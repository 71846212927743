<template>
  <v-dialog persistent v-model="dialog" width="1000">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word"
      >
        Créer une question
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'Question'"
              :linkNode="true"
            />
            <v-col cols="6" fluid>
              <v-select
                label="Type de question *"
                v-model="ofType"
                :rules="[required_rule]"
                :items="questionTypes"
                :item-text="(item) => item[1]"
                :item-value="(item) => item[0]"
                color="primary"
                item-color="primary"
              >
              </v-select>
            </v-col>
            <v-col cols="6" fluid>
              <v-select
                label="Format de réponse *"
                v-model="ofFormat"
                :rules="[required_rule]"
                :items="answerFormat"
                :item-text="(item) => item[1]"
                :item-value="(item) => item[0]"
                color="primary"
                item-color="primary"
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-switch
                v-if="canBeAuto()"
                inset
                v-model="autoAnswer"
                label="Générer des reponses Oui/Non/Ignorance"
                color="primary"
              ></v-switch>
            </v-col>
            <MetaQuestName/>
            <QuestionScoring
              :validForm.sync="validForm"
              :is_scoring.sync="is_scoring"
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-end">
          <v-btn
            style="padding: 0; text-align: center; width: 6em"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
						class="mx-2"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Aller aux réponses
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateModalCore from "@/components/Dialog/CreateModalCore";
import MetaQuestName from "@/components/Utils/MetaQuestName";
import QuestionScoring from "@/components/Utils/QuestionScoring";

export default {
  name: "CreateQuestionModal",
  components: {
    CreateModalCore,
    MetaQuestName,
    QuestionScoring,
  },
  data: () => ({
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    questionData: null,
    meta_questionnaire_name: "",
    required_rule: (v) => !!v || "Ce champ est requis",
    len_rule: (v) => (v && v.length <= 100) || "Max 100 caractères",
    //Question props
    autoAnswer: false,
    is_scoring: false,
    ofType: null,
    ofFormat: null,
    questionTypes: JSON.parse(localStorage.getItem("question_choices_2")),
    answerFormat: JSON.parse(localStorage.getItem("display_answers_2")),
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object,
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.ofType = null;
      this.ofFormat = null;
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.validateLoading = false;
      this.questionData = {};
      this.is_scoring = false;
      this.$store.state.scoring_name = null;
      this.$store.state.scoring_type = null;
    },
    canBeAuto() {
      if (
        this.linkedNodes &&
        this.linkedNodes.length === 1 &&
        this.linkedNodes[0].linkType === "asks_about" &&
        this.linkedNodes[0].tag === "discrimination"
      )
        return true;
      else return false;
    },
    validate() {
      this.$emit("update:dialog", false);
      this.validateLoading = true;
      if (this.is_scoring === null) {
        this.$store.state.scoring_type = null;
        this.$store.state.scoring_name = null;
      }
      this.questionData = {
        name: this.$store.state.newName,
        meta_questionnaire_name: this.$store.state.meta_questionnaire_name
          ? this.$store.state.meta_questionnaire_name
          : "",
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        of_type: this.ofType,
        of_format: this.ofFormat,
        is_scoring: this.is_scoring,
        scoring_type: this.$store.state.scoring_type,
        scoring_name: this.$store.state.scoring_name,
        node_link: this.linkedNodes,
      };
      if (this.canBeAuto() && this.autoAnswer) {
        this.questionData.auto_name = this.questionData.node_link[0].node.name;
        this.questionData.auto_uid = this.questionData.node_link[0].node.uid;
      }
      this.$emit("creating_question", this.questionData);
      this.close();
    },
  },
};
</script>
