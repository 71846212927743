<template>
  <v-dialog persistent v-model="dialog" width="85vw">
    <v-card>
      <v-card-title
        v-if="questionData"
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Réponses de "{{ questionData.name }}"
      </v-card-title>
      <v-card-text v-if="questionData && questionData.scoring_type === 'eva'">
        <v-form ref="form" v-model="validEVA" autocomplete="off">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="eva_first"
                label="Intitulé de la première réponse *"
                :rules="[required_rule]"
                hint="Score : 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="eva_last"
                label="Intitulé de la seconde réponse *"
                :rules="[required_rule]"
                hint="Score : 10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text v-else class="py-5">
        <v-col v-if="adding" cols="12">
          <v-row>
            <v-col cols="12">
              <v-form v-if="adding" ref="form" v-model="validAnswer" autocomplete="off">
                <v-row>
                  <v-col cols="12" fluid>
                    <v-text-field
                      v-model="answerName"
                      label="Nom de la réponse *"
                      :rules="[required_rule]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="questionData.is_scoring" cols="12">
                    <v-text-field
                      v-model="scoringValue"
                      label="Score"
                      type="number"
                      :rules="[required_rule, scoring_rule]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" class="mx-1">
                    <v-row>
                      <v-col cols="4" class="mr-0 pr-0">
                        <v-checkbox
                          v-model="is_neither_of_them"
                          label="Type 'Aucune de ces réponses'"
                          color="secondary"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          v-model="is_free_answer"
                          label="Type 'Entrée libre'"
                          color="secondary"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" class="ma-0 py-1 px-0">
              <v-card outlined class="px-3">
                <SubLink
                  :label="'Chainer une question'"
                  :color="'grey'"
                  :chosenList.sync="tmpQuestion"
                  :current_csq="[]"
                  :nodeTypesDict="nodeTypesDict"
                  :linkLabels="['MasterQuestion', 'Question']"
                />
              </v-card>
            </v-col>
            <v-col cols="12" class="ma-0 py-1 px-0">
              <v-card outlined class="px-3">
                <v-row>
                  <v-col cols="4">
                    <SubLink
                      :label="'Présence'"
                      :color="'primary'"
                      :chosenList.sync="tmpPresence"
                      :current_csq="tmpAbsence.concat(tmpIgnorance)"
                      :nodeTypesDict="nodeTypesDict"
                      :linkLabels="['Symptom', 'RiskFactor']"
                    />
                  </v-col>
                  <v-col cols="4">
                    <SubLink
                      :label="'Absence'"
                      :color="'secondary'"
                      :chosenList.sync="tmpAbsence"
                      :current_csq="tmpPresence.concat(tmpIgnorance)"
                      :nodeTypesDict="nodeTypesDict"
                      :linkLabels="['Symptom', 'RiskFactor']"
                    />
                  </v-col>
                  <v-col cols="4">
                    <SubLink
                      :label="'Ignorance'"
                      :color="'blue-grey'"
                      :chosenList.sync="tmpIgnorance"
                      :current_csq="tmpAbsence.concat(tmpPresence)"
                      :nodeTypesDict="nodeTypesDict"
                      :linkLabels="['Symptom', 'RiskFactor']"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-end">
              <v-btn
                style="padding: 0; text-align: center; width: 7em;"
                outlined
                color="secondary"
                @click="adding = !adding"
              >
                Annuler
              </v-btn>
              <v-btn
								class="ml-2"
                style="padding: 0; text-align: center; width: 7em;"
                :disabled="!validAnswer"
                :loading="validateLoading"
                @click="addAnswer"
                color="secondary"
              >
                Ajouter
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12" class="text-center">
          <v-btn @click="adding = !adding" tile outlined color="secondary">
            <v-icon left>mdi-plus</v-icon> Ajouter une réponse
          </v-btn>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12">
          <v-card outlined v-if="answersList.length === 0">
            <v-card-text>
              Aucune réponse pour cette question pour le moment
            </v-card-text>
          </v-card>
          <v-simple-table v-else>
            <thead>
              <tr>
                <th class="text-center" style="width: 1em;">Ordre</th>
                <th class="text-start">Nom</th>
                <th class="text-center" style="width: 1em;">
                  Aucune de ces réponses
                </th>
                <th class="text-center" style="width: 1em;">Entrée libre</th>
                <th class="text-start">Chainage</th>
                <th class="text-start">Conséquences</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="index" v-for="(answer, index) in answersList">
                <td class="text-center">
                  <v-btn
                    class="px-0"
                    v-if="answersList.length === 1"
                    icon
                    color="secondary"
                    disabled
                    @click="move(answersList, index, index - 1)"
                    ><v-icon>mdi-adjust</v-icon></v-btn
                  >
                  <v-btn
                    class="px-0"
                    v-if="index > 0"
                    icon
                    color="secondary"
                    @click="move(answersList, index, index - 1)"
                    ><v-icon>mdi-chevron-up</v-icon></v-btn
                  >
                  <v-btn
                    class="px-0"
                    v-if="index < answersList.length - 1"
                    icon
                    color="secondary"
                    @click="move(answersList, index, index + 1)"
                    ><v-icon>mdi-chevron-down</v-icon></v-btn
                  >
                </td>
                <td>{{ answer.name }}</td>
                <td class="text-center">
                  <v-checkbox
                    v-model="answer.is_neither_of_them"
                    disabled
                    color="secondary"
                  ></v-checkbox>
                </td>
                <td class="text-center">
                  <v-checkbox
                    v-model="answer.is_free_answer"
                    disabled
                    color="secondary"
                  ></v-checkbox>
                </td>
                <td>
                  <v-chip-group>
                    <v-chip :key="item.uid" v-for="item in answer.chained" label
                      >{{ item.name }}</v-chip
                    >
                  </v-chip-group>
                </td>
                <td>
                  <v-chip-group>
                    <v-chip
											:key="item.uid"
                      v-for="item in answer.presence"
                      class="white--text"
                      color="primary"
                      >P: {{ item.name }}</v-chip
                    >
                    <v-chip
											:key="item.uid"
                      v-for="item in answer.absence"
                      class="white--text"
                      color="secondary"
                      >A: {{ item.name}}</v-chip
                    >
                    <v-chip
											:key="item.uid"
                      v-for="item in answer.ignorance"
                      class="white--text"
                      color="blue-grey"
                      >I: {{ item.name }}</v-chip
                    >
                  </v-chip-group>
                </td>
                <td class="text-center">
                  <v-btn fab x-small color="grey" @click="load(answer, index)"
                    ><v-icon>mdi-pen</v-icon></v-btn
                  >
                  <v-btn fab x-small color="error" @click="remove(index)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-end">
          <v-btn
            style="padding: 0; text-align: center; width: 7em;"
            outlined
            color="primary"
            @click="close()"
          >
            Annuler
          </v-btn>
          <v-btn
						class="ml-2"
            v-if="questionData && questionData.scoring_type === 'eva'"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validEVA"
            :loading="validateLoading"
            @click="validateEVA()"
            color="primary"
          >
            <span>Enregistrer</span>
          </v-btn>
          <v-btn
            v-else
						class="ml-2"
            style="padding: 0; text-align: center; width: 11em;"
            :disabled="answersList.length === 0"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            <span v-if="answersList.length === 0">Aucune réponse</span>
            <span v-else>Enregistrer</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import SubLink from "@/components/Utils/SubLink";

export default {
  name: "CreateAnswerModal",
  components: { SubLink },
  data: () => ({
    answerName: "",
    scoringValue: null,
    tmpPresence: [],
    tmpAbsence: [],
    tmpIgnorance: [],
    tmpQuestion: [],
    eva_first: null,
    eva_last: null,
    validEVA: false,
    adding: false,
    validAnswer: false,
    is_neither_of_them: false,
    is_free_answer: false,
    answersList: [],
    validForm: false,
    validateLoading: false,
    required_rule: v => !!v || "Ce champ est requis",
    scoring_rule: v => v >= 0 || "Le score doit être supérieur à 0"
  }),
  props: {
    questionData: Object,
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  watch: {
    questionData(val) {
      if (val.auto_uid) {
        this.add_auto_answer(val.auto_name, val.auto_uid);
      }
    }
  },
  methods: {
    remove(index) {
      this.answersList.splice(index, 1);
    },
    load(answer, index) {
      this.answerName = answer.name;
      this.is_neither_of_them = answer.is_neither_of_them;
      this.is_free_answer = answer.is_free_answer;
      this.tmpPresence = answer.presence;
      this.tmpAbsence = answer.absence;
      this.tmpIgnorance = answer.ignorance;
      this.tmpQuestion = answer.chained;
      this.remove(index);
      this.adding = true;
    },
    move(array, from, to) {
      array.splice(to, 0, array.splice(from, 1)[0]);
    },
    addAnswer() {
      this.answersList.push({
        name: this.answerName,
        is_neither_of_them: this.is_neither_of_them,
        is_free_answer: this.is_free_answer,
        scoring: this.scoringValue,
        chained: Array.from(this.tmpQuestion),
        presence: Array.from(this.tmpPresence),
        absence: Array.from(this.tmpAbsence),
        ignorance: Array.from(this.tmpIgnorance)
      });
      this.$refs.form.reset();
      (this.is_neither_of_them = false),
        (this.is_free_answer = false),
        (this.adding = false);
      this.tmpPresence = [];
      this.tmpAbsence = [];
      this.tmpIgnorance = [];
      this.tmpQuestion = [];
    },
    close() {
      this.answersList = [];
      this.adding = false;
      this.$emit("update:dialog", false);
    },
    validateEVA() {
      let answers = [];
      let answer_data = {
        scoring_name: this.questionData.scoring_name,
        name_first_answer: this.eva_first,
        name_last_answer: this.eva_last
      };
      answers.push(answer_data);
      this.questionData.answers = answers;
      this.validateLoading = true;
      axios
        .post("/api/questions", this.questionData, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          if (this.questionData.meta_questionnaire_name !== "")
            this.$emit("update_question_list", response.data.meta);
          this.$emit("update:snackText", response.data.detail);
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:dialog", false);
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
          this.close();
        });
    },
    validate() {
      let answers = [];
      for (let i = 0; i < this.answersList.length; i++) {
        let presence = [];
        let absence = [];
        let ignorance = [];
        let chained = [];
        for (let j = 0; j < this.answersList[i].presence.length; j++) {
          presence.push(this.answersList[i].presence[j].uid);
        }
        for (let j = 0; j < this.answersList[i].absence.length; j++) {
          absence.push(this.answersList[i].absence[j].uid);
        }
        for (let j = 0; j < this.answersList[i].ignorance.length; j++) {
          ignorance.push(this.answersList[i].ignorance[j].uid);
        }
        for (let j = 0; j < this.answersList[i].chained.length; j++) {
          chained.push(this.answersList[i].chained[j].uid);
        }
        let answer_data = {
          question_uid: "",
          name: this.answersList[i].name,
          ids_presence: presence,
          ids_absence: absence,
          ids_ignorance: ignorance,
          chained: chained
        };
        if (this.questionData.is_scoring) {
          answer_data.scoring = this.answersList[i].scoring;
          answer_data.scoring_name = this.questionData.scoring_name;
        } else {
          answer_data.is_none_of_the_above_answer = this.answersList[
            i
          ].is_neither_of_them;
          answer_data.is_input = this.answersList[i].is_free_answer;
        }
        answers.push(answer_data);
      }
      this.questionData.answers = answers;
      this.validateLoading = true;
      axios
        .post("/api/questions", this.questionData, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          if (this.questionData.meta_questionnaire_name !== "")
            this.$emit("update_question_list", response.data.meta);
          this.$emit("update:snackText", response.data.detail);
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:dialog", false);
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
          this.close();
        });
    },
    add_auto_answer(name, uid) {
      this.answersList.push({
        name: "Oui",
        is_neither_of_them: false,
        is_free_answer: false,
        scoring: null,
        chained: [],
        presence: [{ name: name, uid: uid }],
        absence: [],
        ignorance: []
      });
      this.answersList.push({
        name: "Non",
        is_neither_of_them: false,
        is_free_answer: false,
        scoring: null,
        chained: [],
        presence: [],
        absence: [{ name: name, uid: uid }],
        ignorance: []
      });
      this.answersList.push({
        name: "Je ne sais pas",
        is_neither_of_them: false,
        is_free_answer: false,
        scoring: null,
        chained: [],
        presence: [],
        absence: [],
        ignorance: [{ name: name, uid: uid }]
      });
    }
  }
};
</script>
