<template>
  <v-container class="ma-0" style="max-width: 100%;">
    <v-snackbar bottom v-model="snackbar" timeout="2000" :color="snackColor">
      {{ message }}
    </v-snackbar>
    <v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="list-item-avatar@10"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card v-if="description" color="#BDD5EA" tile class="pa-5 my-3">
        <b>{{ description }}</b>
      </v-card>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-toolbar-title> {{ titleList }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title> {{ itemList.length }} Noeuds </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="itemList"
            :items-per-page="50"
            :footer-props="{ 'items-per-page-options': [50, 100] }"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <a :href="'/details/' + row.item.uid"">
                    {{ row.item.name }}
                  </a>
                </td>
                <td style="width: 20%;">
                  <v-select
                    hide-details
                    class="my-3"
                    dense
                    outlined
                    v-model="row.item.primary_label"
                    :items="['Symptom', 'RiskFactor']"
                    label="Label principal"
                  ></v-select>
                </td>
                <td class="text-end">
                  <v-btn elevation="0" color="secondary" @click="saveItem(row.item)"
                    >Enregistrer</v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
  name: "RiskFactorLabel",
  data: () => ({
    loading: true,
    message: "",
    snackbar: false,
    snackColor: "white",
    validForm: false,
    titleList: null,
    description: null,
    itemList: [],
  }),
  created: function() {
    axios
      .get("/api/mkg/riskfactor_primary_label", {})
      .then(response => {
        this.titleList = response.data.list_title;
        this.description = response.data.description;
        this.itemList = response.data.item_list;
      })
      .catch(error => {
        this.message = "Erreur lors de l'aquisition des données";
        this.snackColor = "error";
        this.snackbar = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    saveItem(item) {
      if (!item.primary_label) {
        this.message = "Label principal requis";
        this.snackColor = "error";
        this.snackbar = true;
      } else {
        axios
          .patch(
            "/api/mkg/riskfactor_primary_label",
            { uid: item.uid, label: item.primary_label },
            { headers: { "X-CSRFToken": getCookie("csrftoken") } }
          )
          .then(response => {
            this.message = "Mis à jour avec succès";
            this.snackColor = "success";
            this.itemList = response.data.item_list;
          })
          .catch(error => {
            this.message = error.response.data.detail;
            this.snackColor = "error";
          })
          .finally(() => {
            this.snackbar = true;
          });
      }
    }
  }
};
</script>
