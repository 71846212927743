<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_primary">
        <v-card-title class="text-h4 primary--text">
          Traduction
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <div class="mb-15">{{ node_list.length }} nœuds ({{untranslated}} non-traduits)</div>
          <!--            data table listing all the nodes linked to a particular label-->
          <v-data-table
            :headers="headers"
            :items="node_list"
            :loading="loading"
            loading-text="Loading... Please wait"
            @click:row="openNodeNames($event)"
            :footer-props="{
              'items-per-page-options': [15]
            }"
            :items-per-page="300"
            item-key="uid"
          >
            <template v-slot:top>
              <!--            button to translate everything-->
              <v-btn elevation="0" class="ma-4" color="secondary" dark @click="translate_all">
                <v-icon small class="mr-1" dark>mdi-translate</v-icon>
                Traduire
              </v-btn>
              <!--            translation options-->
              <v-btn icon elevation="0" class="ma-4" color="grey" dark @click="translation_options">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-tabs centered v-model="tab" @change="searchByLabel" class="mb-10" show-arrows>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab v-for="(item,i) in labels" :key="i">{{ item }}</v-tab>
              </v-tabs>
            </template>
            <!--            actions header-->
            <template v-slot:item.links="{ item }">
              <!--            open node graph-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click.stop="on" :href="'/graph/' + item.uid">
                    <v-icon>mdi-share-variant-outline</v-icon>
                  </v-btn>
                </template>
                <span>graph</span>
              </v-tooltip>
              <!--            open node details-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click.stop="on" :href="'/details/' + item.uid">
                    <v-icon>mdi-card-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>details</span>
              </v-tooltip>
            </template>
            <!--            name header-->
            <template v-slot:item.names.name_fr="{ item }">
              <div class="black--text text-sm-body-2" >
                {{ truncate(item.names.name_fr, 100) }}
              </div>
            </template>
            <!--            translation status header (fully translated or incomplete)-->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="translation_status[item.status].color" dark>
                {{ translation_status[item.status].text }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <!--            translation options popup-->
    <v-dialog persistent v-model="options" max-width="60%">
      <v-card v-if="options">
        <v-card-title class="primary--text text-h5 font-weight-bold mb-4">
          Translation options
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                class="mt-0 ml-4"
                v-model="only_incomplete"
                :label="`Traduire seulement les champs manquant`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="untranslated > 0">
              <v-slider
                v-model="batch_size"
                class="align-center"
                :max="untranslated"
                min="0"
                hide-details
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="batch_size"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="12">
              <p class="primary--text font-weight-bold ml-4 mb-0">Langues</p>
            </v-col>
            <v-col cols="1">
              <div v-for="lang in all_languages">
                <v-checkbox
                  class="mt-0 ml-4"
                  v-model="selected_languages"
                  :label="lang"
                  :value="lang"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="primary--text font-weight-bold ml-4 mb-0">Moteur de traduction</p>
            </v-col>
            <v-radio-group v-model="translation_engine">
              <v-radio
                class="mt-0 ml-4"
                v-for="engine in choices_translation_engine"
                :key="engine"
                :label="engine"
                :value="engine"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-container>
        <!--            close translation options-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--            single translation popup-->
    <v-dialog persistent v-model="dialog" max-width="60%">
      <v-card v-if="dialog">
        <v-card-title class="primary--text text-h5 font-weight-bold mb-4">
          {{ names.name_fr }}
        </v-card-title>
        <v-card-text>
          <v-form autocomplete="off" v-model="validForm">
            <div :key="key" v-for="(name, key) in names">
              <v-row>
                <v-col cols="11" v-if="key!=='name_fr'">
                  <v-text-field
                    outlined
                    dense
                    clearable
                    :label="key"
                    v-model="names[key]"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" v-if="key!=='name_fr'">
                  <v-btn elevation="0" fab dark small color="secondary" @click="translate(key)">
                    <v-icon>mdi-translate</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--            close single translation popup without saving-->
          <v-btn color="blue darken-1" text @click="close">
            Annuler
          </v-btn>
          <!--            save single translation and close-->
          <v-btn :disabled="!validForm" color="blue darken-1" text @click="save()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_translate" max-width="620px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Êtes-vous sûr de bien vouloir traduire {{ batch_size }}
          noeuds ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="translate_all_confirm" :loading="loading_translation">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      bottom
      v-model="snackData.show"
      :timeout="snackData.timeout"
      :color="snackData.color"
    >
      {{snackData.message}}
      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="snackData.loading"
        :size="30"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
      <v-icon v-else color="white">{{snackData.icon}}</v-icon>
    </v-snackbar>
  </v-row>
</template>

<script>
import moment from "moment";
import axios from "axios";
import {getCookie} from "@/data/utils";

export default {
  name: "Translation",
  components: {},
  data: () => ({
    loading: false,
    dialog: false,
    dialog_translate: false,
    loading_translation: false,
    validForm: false,
    tab: "0",
    batch_size: null,
    node_list: [],
    untranslated: 0,
    options: false,
    only_incomplete: true,
    all_languages: [],
    selected_languages: ['EN'],
    choices_translation_engine: ['DeepL', 'Google Translate'],
    translation_engine: 'DeepL',
    node: null,
    names: null,
    uid: null,
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 4000
    },
    labels: [
        "MasterQuestion",
        "Question",
        "Answer",
        "Symptom",
        "RiskFactor",
        "Group",
        "Disease",
        "ClinicalExam",
        "MedicalExam",
        "MedicalExamResult",
        "Bacteria",
        "Virus",
        "Parasite",
        "Surgery",
        "Vaccine",
    ],
    translation_status: [
      { text: "TRADUIT", value: "0", color: "#789e9e"},
      { text: "INCOMPLET", value: "1", color: "#fe615a"},
    ],
    headers: [
      { text: 'Liens', value: 'links', class: 'primary--text', sortable: false },
      { text: "Nom du nœud", value: "names.name_fr", class: 'primary--text'},
      { text: 'Traduction', value: 'status', class: 'primary--text'},
    ],
    rules: {
      required: v => !!v || 'Champs Obligatoire',
    }
  }),
  methods: {
    truncate(str, n){
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    },
    searchByLabel(tab) {
      this.loading = true;
      axios.get("/api/mkg/node_filtered_by_label", {
        params: {
          label: this.labels[tab]
        }
      })
      .then(response => {
        this.node_list = response.data.node_list;
        this.untranslated = response.data.node_list.filter(function (el) {return el.status === 1}).length;
        this.batch_size = this.untranslated;
        this.all_languages = Object.keys(response.data.node_list[0].names).filter(el => el !=='name_fr');
        this.all_languages = this.all_languages.map(function(el) { return el.replace('name_', '').toUpperCase() });
      })
      .catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      })
      .finally(() => {
        this.loading = false;
      });
    },
    openNodeNames(event) {
      this.dialog = true;
      this.uid = event.uid;
      this.names = event.names;
    },
    translation_options() {
      this.options = true;
    },
    translate(key) {
      axios.get("/api/translate", {
        params: {
          translation_engine: this.translation_engine,
          language: key.split('_')[1].toUpperCase(),
          text: this.names.name_fr
        }
      })
      .then(response => {
        this.names[key] = response.data.name;
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "", false, 4000);
      });
    },
    translate_all() {
      this.dialog_translate = true;
    },
    translate_all_confirm() {
      this.loading_translation = true;
      axios.post(
          "api/translate",
          {
            label: this.labels[this.tab],
            translation_engine: this.translation_engine,
            only_incomplete: this.only_incomplete,
            selected_languages: this.selected_languages,
            batch_size: this.batch_size
          },
          {headers: {"X-CSRFToken": getCookie('csrftoken')}})
      .then(response => {
        this.node_list = response.data.node_list;
        this.untranslated = response.data.node_list.filter(function (el) {return el.status === 1}).length;
        this.batch_size = this.untranslated;
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "", false, 4000);
      })
      .finally(() => {
        this.loading_translation = false;
        this.close();
      });
    },
    save() {
      axios({
        method: "patch",
        url: "/api/manage_translation/" + this.uid,
        data: {
          names: this.names
        },
        headers: { "X-CSRFToken": getCookie("csrftoken") }
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "", false, 4000);
      })
      .finally(() => {
        this.close();
      });
    },
    close() {
      this.options = false;
      this.dialog = false;
      this.dialog_translate = false;
      this.names = null;
      this.uid = null;
    },
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
  }
}
</script>
