<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_primary">
        <v-card-title class="text-h4 primary--text">
          Cas Cliniques
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <div class="mb-15">{{ nb_test_cases }} cas cliniques</div>
          <v-data-table
            :headers="headers"
            :items="test_cases"
            :search="search"
            :expanded.sync="expanded"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
            :items-per-page="25"
            item-key="id"
            show-expand
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            @pagination="updateCaseCount"
          >
            <template v-slot:[`item.case_type`]="{ item }">
              <v-chip :color="caseTypeDict[item.case_type].color" dark>
                {{ caseTypeDict[item.case_type].text }}
              </v-chip>
            </template>
            <template v-slot:[`item.age`]="{ item }">{{ item.age }} {{ item.age === 1 && item.age_unit === 'ans'? item.age_unit.slice(0,-1) : item.age_unit}}</template>
            <template v-slot:top>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    class="ma-5"
                    v-model="search"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="Recherche"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn elevation="0" class="mt-8 mr-8" color="secondary" dark @click="dialog = true; in_creation = true">
                  Nouveau Cas
                </v-btn>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon small @click="editItem(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon small @click="deleteItem(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="mt-5"><span class="text-h5 font-weight-bold primary--text">{{item.case_name}}</span></div>
                <div><span class="font-size--16 primary--text">CIM-10: {{item.expected_disease.icd10}}</span></div>
                <div class="mt-5"><span class="font-size--18 font-weight-bold primary--text">Indications validées</span></div>
                <div><span class="font-size--16 primary--text">
                  <template v-if="item.validated_indications.length > 0">
                    <ul>
                      <li :key="ind.name" v-for='ind in item.validated_indications'>
                        {{ ind.name }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    aucunes
                  </template>
                </span></div>
                <div class="mt-5"><span class="font-size--18 font-weight-bold primary--text">Indications invalidées</span></div>
                <div><span class="font-size--16 primary--text">
                  <template v-if="item.unvalidated_indications.length > 0">
                    <ul>
                      <li :key="ind.name" v-for='ind in item.unvalidated_indications'>
                        {{ ind.name }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    aucunes
                  </template>
                </span></div>
                <div class="mt-5"><span class="font-size--18 font-weight-bold primary--text">Indications inconnues</span></div>
                <div><span class="font-size--16 primary--text">
                  <template v-if="item.unknown_indications.length > 0">
                    <ul>
                      <li :key="ind.name" v-for='ind in item.unknown_indications'>
                        {{ ind.name }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    aucunes
                  </template>
                </span></div>
                 <div class="mt-5"><span class="font-size--18 font-weight-bold primary--text" v-if="item.expected_position_range != null">Classement de la maladie attendue</span></div>
                 <div><span class="font-size--16 primary--text" v-if="item.expected_position_range != null">{{item.expected_disease.name}} dans le top {{item.expected_position_range.split("_")[1]}}</span></div>
                 <div class="mt-5"><span class="font-size--18 font-weight-bold primary--text">Source du cas</span></div>
                 <div><span class="font-size--16 primary--text">{{item.case_source}}</span></div>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog persistent v-model="dialog" max-width="60%">
      <v-card>
        <v-card-title>
          <span class="primary--text text-h5 font-weight-bold mb-4">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form autocomplete="off" v-model="validForm" v-if="dialog">
            <v-row>
              <v-col cols="12" sm="6" md="4" v-if="mountLoading" class="text-center mt-3">
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                </v-col>
              <v-col cols="12" sm="6" md="4" v-else>
                <v-autocomplete
                  name="disease"
                  v-model="editedItem.expected_disease"
                  :items="diseaseList"
                  :item-text="(item) => (item.icd10 + '-' + item.name)"
                  :item-value="(item) => item"
                  label="Choisissez une maladie"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  :rules="[rules.required]"
                  required
                  no-data-text="Pas de correspondance"
                  color="primary"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  hide-details
                  name="expected_position_range"
                  v-model="editedItem.expected_position_range"
                  :items="rankPick"
                  item-text="text"
                  item-value="value"
                  label="Classement"
                  outlined
                  reverse
                  color="primary"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  name="age"
                  v-model="editedItem.age"
                  type="number"
                  label="Age"
                  append-icon="mdi-calendar-account"
                  outlined
                  reverse
                  min="0"
                  max="150"
                  :rules="[rules.required]"
                  required
                  color="primary"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  name="age_unit"
                  v-model="editedItem.age_unit"
                  :items="ageUnit"
                  item-text="text"
                  item-value="value"
                  label="Unité d'age"
                  outlined
                  reverse
                  :rules="[rules.required]"
                  required
                  color="primary"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  name="sexe"
                  v-model="editedItem.sex"
                  :items="genderPick"
                  item-text="text"
                  item-value="value"
                  label="Sexe"
                  append-icon="mdi-gender-male-female"
                  outlined
                  reverse
                  :rules="[rules.required]"
                  required
                  color="primary"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  name="case_name"
                  v-model="editedItem.case_name"
                  label="Nom du cas"
                  outlined
                  :rules="[rules.required, rules.case_name]"
                  required
                  color="primary"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  name="medical_classification"
                  v-model="editedItem.medical_classification"
                  :items="specialityList"
                  :item-text="(item) => item[1]"
                  :item-value="(item) => item[0]"
                  label="Spécialité"
                  color="primary"
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  outlined
                  dense
                  label="Type du cas"
                  v-model="editedItem.case_type"
                  :rules="[rules.required]"
                  :items="caseTypeDict"
                  color="primary"
                  item-text="text"
                  item-value="value"
                  @change="changeType">
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="case_source"
                  v-model="editedItem.case_source"
                  label="Source du cas"
                  outlined
                  :rules="[rules.required, rules.case_source]"
                  required
                  color="primary"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card elevation="0" class="pt-2">
                  <SubLink
                    :label="'Indications validées'"
                    :color="'primary'"
                    :chosenList.sync="editedItem.validated_indications"
                    :current_csq="[]"
                    :nodeTypesDict="nodeTypesDict"
                    :linkLabels="['Symptom', 'RiskFactor']"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card elevation="0" class="pt-2">
                  <SubLink
                    :label="'Indications invalidées'"
                    :color="'primary'"
                    :chosenList.sync="editedItem.unvalidated_indications"
                    :current_csq="[]"
                    :nodeTypesDict="nodeTypesDict"
                    :linkLabels="['Symptom', 'RiskFactor']"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card elevation="0" class="pt-2">
                  <SubLink
                    :label="'Indications inconnues'"
                    :color="'primary'"
                    :chosenList.sync="editedItem.unknown_indications"
                    :current_csq="[]"
                    :nodeTypesDict="nodeTypesDict"
                    :linkLabels="['Symptom', 'RiskFactor']"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Annuler
          </v-btn>
          <v-btn :disabled="!validForm" color="blue darken-1" text @click="save" :loading="loadingSave">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="620px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Êtes-vous sûr de bien vouloir supprimer cet élément?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" :loading="loadingDelete">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SubLink from "@/components/Utils/SubLink";
import {getCookie} from "@/data/utils";
import axios from "axios";

export default {
  name: "TestCases",
  components: {SubLink},
  data: () => ({
    expanded: [],
    validForm: false,
    dialog: false,
    dialogDelete: false,
    mountLoading: true,
    in_creation: false,
    loadingSave: false,
    loadingDelete: false,
    specialityList: JSON.parse(localStorage.getItem("medical_classification")),
    search: '',
    headers: [
      { text: 'Maladie', value: 'expected_disease.name', class: 'primary--text', sortable: false, align: 'start'},
      { text: 'Spécialité', value: 'medical_classification', class: 'primary--text'},
      { text: 'Age', value: 'age', class: 'primary--text'},
      { text: 'Sexe', value: 'sex', class: 'primary--text' },
      { text: 'Type', value: 'case_type', class: 'primary--text'},
      { text: 'Date', value: 'date_added', class: 'primary--text'},
      { text: 'Utilisateur', value: 'created_by', class: 'primary--text'},
      { text: 'Actions', value: 'actions', class: 'primary--text', sortable: false },
    ],
    test_cases: [],
    nb_test_cases: null,
    editedIndex: -1,
    editedItem: {
      id: null,
      expected_disease: null,
      medical_classification: null,
      age: null,
      age_unit: null,
      sex: null,
      case_name: null,
      case_source: null,
      expected_position_range: null,
      validated_indications: [],
      unvalidated_indications: [],
      unknown_indications: [],
      case_type: { text: "MEDICAL", value: "0", color: "#fe615a"},
    },
    diseaseList: [],
    rankPick: [
      { text: "Top 3", value: "top_3" },
      { text: "Top 5", value: "top_5" },
      { text: "Top 10", value: "top_10" },
    ],
    ageUnit: [
      { text: "Ans", value: "ans" },
      { text: "Mois", value: "mois" }
    ],
    genderPick: [
      { text: "Homme", value: "M" },
      { text: "Femme", value: "F" }
    ],
    caseTypeDict: [
      { text: "MEDICAL", value: "0", color: "#fe615a"},
      { text: "ARTIFICIAL", value: "1", color: "#789e9e"},
    ],
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
    rules: {
      required: v => !!v || 'Champs Obligatoire',
      case_name: v => (v && v.length <= 150) || "Max 150 caractères",
      case_source: v => (v && v.length <= 255) || "Max 255 caractères",
    }
  }),

  mounted: function () {
    this.getPossibleDisease();
  },

  created: function() {
    axios.get(
        "/api/mkg/test_cases",
        {}
    ).then(response => {
      this.test_cases = response.data;
    }).catch(error => {
      this.manageError(error);
    });
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nouveau Cas' : 'Modifier Cas';
    },
  },
  methods: {
    editItem (item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.case_type = this.caseTypeDict[item.case_type]
      this.editedIndex = this.test_cases.indexOf(item)
      this.dialog = true;
    },

    deleteItem (item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm () {
      this.loadingDelete = true;

      axios.delete(
         `/api/mkg/test_cases/${this.editedItem.id}`,
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
      ).then(response => {
        let index = this.test_cases.findIndex(x => x.id === this.editedItem.id);
        if (index > -1) {
          this.test_cases.splice(index, 1);
        }
      }).finally(() => {
        this.close();
        this.loadingDelete = false;
      }).catch(error => {
        this.manageError(error);
      });
    },

    close () {
      this.dialog = false;
      this.dialogDelete = false;
      this.in_creation = false;
      this.editedItem = Object.assign({
        id: null,
        expected_disease: null,
        medical_classification: null,
        age: null,
        age_unit: null,
        sex: null,
        case_name: null,
        case_source: null,
        expected_position_range: null,
        validated_indications: [],
        unvalidated_indications: [],
        unknown_indications: [],
        case_type: { text: "MEDICAL", value: "0", color: "#fe615a"},
      });
      this.editedIndex = -1;
    },

    save () {
      this.loadingSave = true;
      let data = {
        case_name: this.editedItem.case_name,
        case_source: this.editedItem.case_source,
        age: this.editedItem.age,
        medical_classification: this.editedItem.medical_classification,
        age_unit: this.editedItem.age_unit,
        sex: this.editedItem.sex,
        expected_disease: this.editedItem.expected_disease,
        expected_position_range: this.editedItem.expected_position_range,
        validated_indications: this.editedItem.validated_indications,
        unvalidated_indications: this.editedItem.unvalidated_indications,
        unknown_indications: this.editedItem.unknown_indications,
        case_type: this.editedItem.case_type.value,
      };
      if (!this.in_creation) {
        axios.put(
          `/api/mkg/test_cases/${this.editedItem.id}`,
          data,
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
        ).then(response => {
          let index = this.test_cases.findIndex(x => x.id === this.editedItem.id);
          if (index > -1) {
            Object.assign(this.test_cases[index], response.data);
          }
        }).finally(() => {
          this.close();
          this.loadingSave = false;
        }).catch(error => {
          this.manageError(error);
        });
      } else {
        axios.post(
          `/api/mkg/test_cases`,
          data,
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
        ).then(response => {
          this.test_cases.push(response.data);
        }).finally(() => {
          this.close();
          this.loadingSave = false;
        }).catch(error => {
          this.manageError(error);
        });
      }
    },

    manageError(error) {
      if (error.response) {
        this.$store.dispatch("ManageError", error.response.status);
      } else {
        this.$store.commit("PostError", "Une erreur est survenue");
      }
    },

    getPossibleDisease() {
      this.$http.get("/api/mkg/disease_active", {}).then((response) => {
        this.diseaseList = response.data.disease;
      }).catch(error => {
        this.manageError(error);
      }).finally(() => {
        this.mountLoading = false;
      });
    },
    changeType(value) {
      this.editedItem.case_type = this.caseTypeDict[value];
    },
    updateCaseCount(pagination) {
      this.nb_test_cases = pagination.itemsLength;
    }
  },
}
</script>