<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <div v-if="loading" style="width: 100%; text-align: center; padding-top: 15%;">
        <v-progress-circular
          :size="250"
          :width="20"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-row v-else>
        <v-col v-if="!active && redirect === null" cols="12">
          <v-alert type="error" text>
            <span class="semibold--text font-size--16">Attention NOEUD INACTIF</span>
          </v-alert>
        </v-col>
        <v-col v-else-if="redirect !== null" cols="12">
          <v-alert type="error" text>
            <span class="semibold--text font-size--16">Attention NOEUD DÉPRÉCIÉ, cliquez <a class="bold--text text-decoration-underline red--text" :href="'/details/' + redirect">ici</a> pour aller vers le nouveau noeud</span>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 ml-4 mt-4 mb-0">
            <v-card-title class="text-h5 primary--text mb-4 pa-2">
              Gestion des labels
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="10" class="text-start">
                  <v-chip v-for="label in nodeLabels"
                          :key="label"
                          :color="nodeTypesDict[label].color"
                          class="text-center font-weight-bold white--text mr-1">
                    {{label}}
                  </v-chip>
                </v-col>
                <v-col cols="2" class="text-end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text color="error" v-bind="attrs" v-on="on">Supprimer</v-btn>
                    </template>
                    <v-list>
                      <v-list-item :key="label" v-for="label in nodeLabels" @click="removeLabel(label)">
                        <v-list-item-title>{{label}}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="AvailableLabels.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text color="primary" v-bind="attrs" v-on="on">Ajouter</v-btn>
                    </template>
                    <v-list>
                      <v-list-item :key="label" v-for="label in AvailableLabels" @click="addLabel(label)">
                        <v-list-item-title>{{label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-tabs v-model="tab" color="primary" background-color="transparent" fixed-tabs>
            <v-tab :key="item" v-for="item in nodeLabels">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="item" v-for="item in nodeLabels">
              <v-card flat class="bg_primary">
                <SymptomDetails
                  v-if="nodeLabels[tab] === 'Symptom'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <RiskFactorDetails
                  v-else-if="nodeLabels[tab] === 'RiskFactor'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <DiseaseDetails
                  v-else-if="nodeLabels[tab] === 'Disease'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <GroupDetails
                  v-else-if="nodeLabels[tab] === 'Group'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <MasterQuestionDetails
                  v-else-if="nodeLabels[tab] === 'MasterQuestion'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <QuestionDetails
                  v-else-if="nodeLabels[tab] === 'Question'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <AnswerDetails
                  v-else-if="nodeLabels[tab] === 'Answer'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <ClinicalExamDetails
                  v-else-if="nodeLabels[tab] === 'ClinicalExam'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <MedExamDetails
                  v-else-if="nodeLabels[tab] === 'MedicalExam'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <MedExamResultDetails
                  v-else-if="nodeLabels[tab] === 'MedicalExamResult'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <SurgeryDetails
                  v-else-if="nodeLabels[tab] === 'Surgery'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <DrugDetails
                  v-else-if="nodeLabels[tab] === 'Drug'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                  label="Drug"
                  route="/api/drug/"
                />
                <DrugDetails
                  v-else-if="nodeLabels[tab] === 'Vaccine'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                  label="Vaccine"
                  route="/api/vaccine/"
                />
                <DciDetails
                  v-else-if="nodeLabels[tab] === 'DCI'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <CompoundDetails
                  v-else-if="nodeLabels[tab] === 'Compound'"
                  :uid="nodeUID"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
                <GenericDetails
                  v-else
                  :uid="nodeUID"
                  :label="nodeLabels[tab]"
                  :node_dict="nodeTypesDict"
                  :speciality_list="specialityList"
                  :snack_data.sync="snackData"
                  :update_field="updateField"
                  :unpack_pr="unpackPR"
                  :save_pr="savePR"
                  :toggle_snack="toggle_snack"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-snackbar class="pa-0"
        bottom
        v-model="snackData.show"
        :timeout="snackData.timeout"
        :color="snackData.color"
      >
        <v-row>
          <v-col cols="10" class="py-0">
            <p class="pt-1 ma-0">{{ snackData.message }}</p>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-progress-circular
              class="text-end"
              v-if="snackData.loading"
              :size="30"
              :width="3"
              color="white"
              indeterminate
            ></v-progress-circular>
            <v-icon v-else color="white">{{ snackData.icon }}</v-icon>
          </v-col>
        </v-row>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import SymptomDetails from "@/components/Details/SymptomDetails";
import SurgeryDetails from "@/components/Details/SurgeryDetails";
import RiskFactorDetails from "@/components/Details/RiskFactorDetails";
import DiseaseDetails from "@/components/Details/DiseaseDetails";
import DrugDetails from "@/components/Details/DrugDetails";
import GroupDetails from "@/components/Details/GroupDetails";
import MasterQuestionDetails from "@/components/Details/MasterQuestionDetails";
import QuestionDetails from "@/components/Details/QuestionDetails";
import AnswerDetails from "@/components/Details/AnswerDetails";
import MedExamDetails from "@/components/Details/MedExamDetails";
import GenericDetails from "@/components/Details/GenericDetails";
import CompoundDetails from "@/components/Details/CompoundDetails";
import DciDetails from "@/components/Details/DciDetails";
import MedExamResultDetails from "@/components/Details/MedExamResultDetails.vue";
import ClinicalExamDetails from "@/components/Details/ClinicalExamDetails.vue";

export default {
  name: "Details",
  components: {
    ClinicalExamDetails,
    MedExamResultDetails,
    DciDetails,
    CompoundDetails,
    SymptomDetails,
    RiskFactorDetails,
    DiseaseDetails,
    GroupDetails,
    MasterQuestionDetails,
    QuestionDetails,
    AnswerDetails,
    MedExamDetails,
    SurgeryDetails,
    DrugDetails,
    GenericDetails
  },
  data: () => ({
    loading: true,
    tab: null,
    nodeUID: "",
    specialityList: JSON.parse(localStorage.getItem("medical_classification")),
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "Pa", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
    active: true,
    redirect: null,
    nodeLabels: [],
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 2000
    }
  }),
  computed: {
    AvailableLabels() {
      let question_constraint = ['MasterQuestion', 'Question', 'Answer'];
      if (this.nodeLabels.some(x => question_constraint.includes(x))) {
        // No point in changing labels for questions and answers
        return [];
      } else {
        // all medical nodes can be together
        return Object.keys(this.nodeTypesDict).filter(x =>
            x!=='Answer' && !question_constraint.includes(x) && !this.nodeLabels.includes(x));
      }
    }
  },
  created: function() {
    axios
      .get("/api/mkg/node/" + this.$route.params.uid, {
        params: {
          details: true
        }
      })
      .then(response => {
        this.nodeUID = this.$route.params.uid;
        this.nodeLabels = response.data.labels;
        this.active = response.data.is_active;
        this.redirect = response.data.redirect;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  watch: {
    $route(to, from) {
      this.$router.go();
    }
  },
  methods: {
    updateField(field, value, route, pretty_name, use_route = false) {
      this.toggle_snack("secondary", "Mis à jour en cours", "", true, 0);
      let updateDataDict = {};
      updateDataDict[field] = value;
      updateDataDict["details"] = true;
      let full_route = use_route ? route : route + this.nodeUID;
      axios
        .patch(full_route, updateDataDict, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Mis à jour avec succès",
            "mdi-check",
            false,
            1000
          );
        })
        .catch(error => {
          let message = error.response.data.detail;
          if (!message)
            message = "Un problème est survenu: erreur à la mise à jour " + pretty_name;
          this.toggle_snack("error", message, "mdi-close", false, 4000);
        })
        .finally(() => {
          setTimeout(() => {
            this.snackData.show = false;
          }, this.snackData.timeout);
          if (field === "label_to_add" || field === "label_to_remove")
            window.location.reload();
        });
    },
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
    savePR(value, route) {
      this.updateField(
        "prerequisites",
        JSON.stringify(value),
        route,
        "des pré-requis"
      );
    },
    unpackPR(raw_prerequisites) {
      let prerequisites = raw_prerequisites;
      let preRequisitesData = JSON.parse(
        localStorage.getItem("dict_prerequisites")
      );
      let _this = this;
      prerequisites.forEach(function(group) {
        group.forEach(function(pre_req) {
          let name = pre_req["name"];
          let prData = preRequisitesData[name];
          pre_req.field_type = "";
          pre_req.conditions = "";
          pre_req.possible_values = [];
          pre_req.limits = {};
          pre_req.conditions = prData.condition;
          pre_req.field_type = prData.field_type[0];
          if (pre_req.field_type === "input") {
            if (prData.value_type[0] === "text") {
              pre_req.field_type = "text";
            } else if (prData.value_type[0] === "number") {
              pre_req.field_type = "number";
              pre_req.limits = {
                max: prData.max[0],
                min: prData.min[0],
                step: prData.step[0]
              };
            }
          } else if (pre_req.field_type === "scoring_select") {
            pre_req.possible_values = _this.$store.getters.getScoringNames;
          } else {
            pre_req.possible_values = prData.value_field;
          }
        });
      });
      if (prerequisites.length === 1 && prerequisites[0].length === 0)
        prerequisites.splice(0, 1);
      return prerequisites;
    },
    addLabel(label) {
      this.updateField("label_to_add", label, "/api/mkg/node/", "des labels");
    },
    removeLabel(label) {
      if (this.nodeLabels.length > 1) {
        this.updateField(
          "label_to_remove",
          label,
          "/api/mkg/node/",
          "des labels"
        );
      } else {
        this.toggle_snack(
          "error",
          "Le dernier label ne peut pas être retiré",
          "mdi-close",
          false,
          3000
        );
      }
    }
  }
};
</script>
