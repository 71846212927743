<template>
	<v-col cols="12">
		<v-checkbox class="ma-auto"
			v-model="inQuest"
			label="Rattaché à un questionnaire"
		></v-checkbox>
		<v-autocomplete v-if="inQuest"
			outlined
			clearable
			hide-details
			class="ma-auto"
			:loading="loadingQuest"
			v-model="$store.state.meta_questionnaire_name"
			:items="questList"
			:item-text="(item) => item.commercial_name"
			:item-value="(item) => item.name"
			label="Chercher un questionnaire"
			prepend-inner-icon="mdi-magnify"
		>
			<template v-slot:no-data>
				<v-list-item>
					Pas de correspondance
				</v-list-item>
			</template>
		</v-autocomplete>
	</v-col>
</template>

<script>
import axios from "axios";

export default {
	name: "MetaQuestName",
  data: () => ({
    inQuest: false,
    from_page: false,
    loadingQuest: false,
    questList: [],
  }),
  mounted: function () {
    this.inQuest = RegExp(".*questionnaires/*.+[a-z]+").test(this.$route.path);
    if (this.inQuest) this.from_page=true;
  },
  watch: {
    inQuest(val) {
      if (val && !this.questList.length) {
        this.loadingQuest = true;
        axios
          .get("/api/mkg/questionnaires", {
            params: {
              list: true,
            },
          })
          .then((response) => {
            this.questList = response.data.questionnaire_list;
            if (this.from_page) this.$store.state.meta_questionnaire_name = this.$route.path.match(/questionnaires\/(.*?)/)[1]
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingQuest = false;
          });
      } else {
        this.$store.state.meta_questionnaire_name = null;
      }
    },
  },
};
</script>
