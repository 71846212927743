<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_primary">
        <v-card-title class="text-h4 primary--text mb-4">
          Maintenance
        </v-card-title>
        <v-card-text>
          <div class="text-h5 primary--text mb-2">Tâches</div>
           <v-col cols="12" sm="4">
             <v-card>
               <v-card-title class="justify-center text-center align-center primary--text text-h6 font-weight-bold">
                 Fusionner des noeuds
               </v-card-title>
               <v-card-text>
                 <SubLink
                     :label="'Source (un ou plusieurs noeuds)'"
                     :color="'primary'"
                     :chosenList.sync="source"
                     :current_csq="[]"
                     :nodeTypesDict="nodeTypesDict"
                     :exceptionList="cible"
                     :linkLabels="[
                         'Symptom',
                         'Disease',
                         'RiskFactor',
                         'Group',
                         'ClinicalExam',
                         'MedicalExam',
                         'MedicalExamResult',
                         'Bacteria',
                         'Virus',
                         'Parasite',
                         'Surgery',
                         'Vaccine',
                         'Drug',
                         'DCI',
                         'Compound',
                     ]"
                />
                 <SubLink
                     :label="'Cible (un seul noeud)'"
                     :color="'primary'"
                     :chosenList.sync="cible"
                     :multiple="false"
                     :current_csq="[]"
                     :exceptionList="source"
                     :nodeTypesDict="nodeTypesDict"
                     :linkLabels="[
                         'Symptom',
                         'Disease',
                         'RiskFactor',
                         'Group',
                         'ClinicalExam',
                         'MedicalExam',
                         'MedicalExamResult',
                         'Bacteria',
                         'Virus',
                         'Parasite',
                         'Surgery',
                         'Vaccine',
                         'Drug',
                         'DCI',
                         'Compound',
                     ]"
                />
                 <v-btn
                     class="pa-0"
                     pa="0"
                     color="secondary"
                     text
                     :loading="loading"
                     :disabled="isEmpty"
                     @click="mergeNodes()"
                 >
                   Enregistrer
                 </v-btn>
               </v-card-text>
             </v-card>
           </v-col>
        </v-card-text>
        <v-card-text>
          <div class="text-h5 primary--text mb-2">Informations générales</div>
          <v-row>
            <v-col cols="12" sm="4" :key="item.title" v-for="item in maintenance['general']">
              <NodeRow
                :key="item.title"
                :title="item.title"
                :field="item.data"
                :loading="item.loading"
              />
            </v-col>
          </v-row>
          <div class="text-h5 primary--text mt-10 mb-2">Problèmes de noeuds</div>
          <v-row>
            <v-col cols="12" sm="4" :key="item.title" v-for="item in maintenance['nodes']">
              <NodeRow
                :key="item.title"
                :title="item.title"
                :field="item.data"
                :loading="item.loading"
                :download="download_disease_statistics"
              />
            </v-col>
          </v-row>
          <div class="text-h5 primary--text mt-10 mb-2">Problèmes de liens</div>
          <v-row>
            <v-col cols="12" sm="4" :key="item.title" v-for="item in maintenance['relationships']">
              <NodeRow
                :key="item.title"
                :title="item.title"
                :field="item.data"
                :loading="item.loading"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar class="pa-0" bottom v-model="snackData.show" :timeout="snackData.timeout" :color="snackData.color">
        <v-row>
          <v-col cols="10" class="py-0">
            <p class="pt-1 ma-0">{{ snackData.message }}</p>
          </v-col>
          <v-col cols="2" class="py-0">
            <v-progress-circular
              class="text-end"
              v-if="snackData.loading"
              :size="30"
              :width="3"
              color="white"
              indeterminate
            ></v-progress-circular>
            <v-icon v-else color="white">{{ snackData.icon }}</v-icon>
          </v-col>
        </v-row>
      </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import NodeRow from "@/components/Utils/NodeRow";
import generateCsv from "anam-library/src/js/export_csv";
import moment from "moment";
import SubLink from "@/components/Utils/SubLink.vue";
import getCookie from "anam-library/src/js/common";

export default {
  name: "Maintenance",
  components: {SubLink, NodeRow },
  data: () => ({
    maintenance: {
      general: {
        comments: {title: "Tags", data: {}, loading: true},
        test_cases: {title: "Cas Cliniques", data: {}, loading: true},
      },
      nodes: {
        anamnese_nodes: {title: "AnamneseNode", data: {}, loading: true},
        questions: {title: "Question", data: {}, loading: true},
        answers: {title: "Answer", data: {}, loading: true},
        symptoms: {title: "Symptom", data: {}, loading: true},
        risk_factors: {title: "RiskFactor", data: {}, loading: true},
        diseases: {title: "Disease", data: {}, loading: true},
        drugs: {title: "Drug", data: {}, loading: true},
      },
      relationships: {
        chained: {title: "chained", data: {}, loading: true},
      }
    },
    source: [],
    cible: [],
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
    loading: false,
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 2000
    }
  }),
  mounted() {
    Object.entries(this.maintenance).map(item => {
      Object.entries(item[1]).map(key => {
        axios.get("/api/mkg/get_maintenance_" + key[0])
        .then(response => {
          let resp = response.data;
          key[1]["data"] = resp.dict;
          key[1]["loading"] = false;
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
        });
      })
    })
  },
  computed: {
    isEmpty: ({ source, cible }) => source.length === 0 || cible.length === 0
  },
  methods: {
    mergeNodes() {
      this.loading = true;
      let source_map = this.source.map(a => a.uid);
      let cible_map = this.cible.map(a => a.uid);
      axios
        .post(
          "/api/mkg/merge_nodes",
          {
            source: source_map,
            cible: cible_map[0],
          },
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
        )
        .then(() => {
          this.toggle_snack(
              "gray",
              "Fusion en cours",
              "",
              true,
              2000
          );
        })
        .catch(error => {
          if(error.response) {
            this.$store.dispatch("ManageError", error.response.status);
          }
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.source = [];
          this.cible = [];
        });
    },
    download_disease_statistics() {
      const filename = moment(new Date())
        .locale("fr")
        .format("[diseaseStats]-DD-MM-YYYY");

      axios.get("/api/mkg/download_disease_stats_csv").then((response) => {
        generateCsv(filename, response);
      });
    },
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
  }
};
</script>
