<template>
  <v-row>
    <v-col cols="12" class="my-0 py-0" v-if="chosenList.length > 0">
      <v-chip-group column>
        <v-chip
					:key="index"
          v-for="(item, index) in chosenList"
          label
          class="white--text"
          style="white-space: normal;"
          close
          :color="color"
          @click:close="removeItem(index)"
        >
          {{ item.name }}
        </v-chip>
      </v-chip-group>
    </v-col>
    <v-col cols="12" class="my-0 py-0">
      <v-menu v-model="showMenu" offset-y max-height="30vh">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            ref="search"
            outlined
            dense
            append-icon="mdi-magnify"
            :label="label"
            v-model="search"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-list v-if="loading">
          <v-list-item style="min-height: 200px; justify-content: center">
            <v-progress-circular :size="100" :width="10" color="soft_blue" indeterminate></v-progress-circular>
          </v-list-item>
        </v-list>
        <v-list v-else-if="!search || search < 3">
          <v-list-item>
            <v-list-item-content>
              Au moins 3 caractères pour lancer la recherche
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else-if="noResult">
          <v-list-item>
            <v-list-item-content>
              Pas de résultat pour cette recherche
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
              :key="item.uid"
              v-for="item in searchResult"
              @click="addItem(item)"
          >
            <v-list-item-avatar
              size="26"
              :color="
                nodeTypesDict[item.class_name]
                  ? nodeTypesDict[item.class_name].color
                  : 'black'
              "
              class="white--text"
            >
              {{ nodeTypesDict[item.class_name] ?
              nodeTypesDict[item.class_name].symbol : item.class_name[0] }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-snackbar
      bottom
      v-model="snackData.show"
      :timeout="snackData.timeout"
      :color="snackData.color"
    >
      {{snackData.message}}
      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="snackData.loading"
        :size="30"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
      <v-icon v-else color="white">{{snackData.icon}}</v-icon>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
  data: () => ({
    timeout: null,
    noResult: false,
    loading: false,
    showMenu: false,
    searchResult: [],
    search: "",
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 2000
    }
  }),
  props: {
    answer: Object,
    color: String,
    label: String,
    chosenList: Array,
    exceptionList: {
      type: Array,
      default() {
        return []
      }
    },
    nodeTypesDict: Object,
    linkLabels: Array,
    current_csq: Array,
    link_type: String,
    link_data: Object,
    multiple: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
    updateLink(item, create, index) {
      this.toggle_snack("secondary", "Mis à jour des liens", "", true, 0);
      let updateDataDict = {};
      if (create) {
        (updateDataDict["action"] = "create");
        (updateDataDict["target"] = item.uid);
        updateDataDict["link_type"] = this.link_type;
        updateDataDict["link_data"] = this.link_data;
      } else {
        updateDataDict["action"] = "delete";
        updateDataDict["target"] = item.uid;
        updateDataDict["link_type"] = this.link_type;
      }
      updateDataDict["manage_link"] = true;
      axios
        .patch("/api/answers/" + this.answer.uid, updateDataDict, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Mis à jour avec succès",
            "mdi-check",
            false,
            1000
          );
          let tmp = JSON.parse(JSON.stringify(this.chosenList));
          if (create) {
            if (
              this.chosenList.findIndex(x => x.uid === item.uid) === -1 &&
              this.current_csq.findIndex(x => x.uid === item.uid) === -1 &&
              this.exceptionList.findIndex(x => x.uid === item.uid) === -1
            ) {
              tmp.push({ uid: item.uid, name: item.name });
              this.$emit("update:chosenList", tmp);
            }
          } else {
            tmp.splice(index, 1);
            this.$emit("update:chosenList", tmp);
          }
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            error.response.data.detail,
            "mdi-close",
            false,
            4000
          );
        })
        .finally(() => {
          setTimeout(() => {
            this.snackData.show = false;
          }, this.snackData.timeout);
        });
    },
    addItem(item) {
      if (this.answer) {
        this.updateLink(item, 1, null);
      } else {
        if (
          this.chosenList.findIndex(x => x.uid === item.uid) === -1 &&
          this.current_csq.findIndex(x => x.uid === item.uid) === -1 &&
          this.exceptionList.findIndex(x => x.uid === item.uid) === -1
        ) {
          let tmp = null;
          if(this.multiple) {
            tmp = JSON.parse(JSON.stringify(this.chosenList));
            tmp.push({ uid: item.uid, name: item.name });
          }
          else tmp = [{ uid: item.uid, name: item.name }];
          this.$emit("update:chosenList", tmp);
        }
      }
      this.search = "";
    },
    removeItem(index) {
      if (this.answer) {
        let item = this.chosenList[index];
        this.updateLink(item, 0, index);
      } else {
        let tmp = JSON.parse(JSON.stringify(this.chosenList));
        tmp.splice(index, 1);
        this.$emit("update:chosenList", tmp);
      }
    }
  },
  watch: {
    search(value) {
      this.searchResult = [];
      this.noResult = false;
      if (value && value.length >= 3) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.showMenu = false;
        }
        this.timeout = setTimeout(() => {
          this.loading = true;
          this.showMenu = true;
          let data = {txt: value, labels: this.linkLabels, medical_classification_filter: null, group_by_label: false}
          axios
            .get("/api/mkg/search_ind", {
              params: {"payload": data},
              headers: {"X-CSRFToken": getCookie("csrftoken")},
            })
            .then(response => {
              // deprecated nodes are hidden from the search
              this.searchResult = response.data.all.filter(node => node.is_deprecated===false);
            })
            .catch(error => {
              this.$store.dispatch("ManageError", error.response.status)
            })
            .finally(() => {
              if (!this.searchResult || this.searchResult.length === 0)
                this.noResult = true;
              this.loading = false;
            });
        }, 500);
      } else {
        this.showMenu = false;
      }
    },
  }
};
</script>
