<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="2" v-if="!launched" class="ml-3 mr-6 mt-4 mb-0">
        <v-skeleton-loader type="article, table-tfoot"></v-skeleton-loader>
      </v-card>
      <v-card elevation="2" class="ml-3 mr-6 mt-4 mb-0" v-else>
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Recommandations</v-card-title>
        <v-card-text>
          <v-btn text @click="triggerSmr = !triggerSmr">
            <v-icon left v-if="triggerSmr">mdi-chevron-down</v-icon>
            <v-icon left v-else>mdi-chevron-up</v-icon>
            <span class="text-h6">Service médical rendu</span>
          </v-btn>
          <v-timeline align-top dense v-if="triggerSmr">
            <v-timeline-item
                :key="i" v-for="(item, i) in smr"
                small
                fill-dot
                color="teal lighten-3"
            >
              <v-row>
                <v-col cols="6">
                  <v-btn
                      small
                      color="blue lighten-1"
                      text
                      @click="show_information(item.information)"
                  >
                    <strong>{{ item.date }}</strong>
                  </v-btn>
                </v-col>
                <v-col class="mt-1" cols="6">
                    <strong>{{ item.decision }}</strong>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <v-btn class="mt-4" text @click="triggerAsmr = !triggerAsmr">
            <v-icon left v-if="triggerAsmr">mdi-chevron-down</v-icon>
            <v-icon left v-else>mdi-chevron-up</v-icon>
            <span class="text-h6">Amélioration du service médical rendu</span>
          </v-btn>
          <v-timeline align-top dense v-if="triggerAsmr">
            <v-timeline-item
                :key="i" v-for="(item, i) in asmr"
                small
                fill-dot
                color="red lighten-2"
            >
              <v-row>
                <v-col cols="6">
                  <v-btn
                      small
                      color="blue lighten-1"
                      text
                      @click="show_information(item.information)"
                  >
                    <strong>{{ item.date }}</strong>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <strong>{{ asmr_definition[item.decision] || item.decision }}</strong>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" width="40%">
      <v-card>
        <v-card-title class="primary--text text-h5 font-weight-bold mb-4">
          Information
        </v-card-title>
        <v-card-text>{{ information }} </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import moment from "moment";

export default {
	name:"Recommendations",
	props: {
    launched: Boolean,
    cis_code: String,
  },
  data: () => ({
    smr: null,
    asmr: null,
    information: null,
    triggerSmr: false,
    triggerAsmr: false,
    dialog: false,
    asmr_definition: {
      I: 'majeure',
      II: 'importante',
      III: 'modérée',
      IV: 'mineure',
      V:  'inexistante',
    }
  }),
  mounted() {
    axios.get(`/api/drugs/smr_recommendation/${this.cis_code}`)
      .then(response => {
        if (response.data) {
          this.smr = response.data.map(function (item) {
            return {
              date: moment(item.date, "YYYYMMDD").locale('fr').format('L'),
              decision: item.decision,
              information: item.information
            };
          });
        };
      });

    axios.get(`/api/drugs/asmr_recommendation/${this.cis_code}`)
      .then(response => {
        if (response.data) {
          this.asmr = response.data.map(function (item) {
            return {
              date: moment(item.date, "YYYYMMDD").locale('fr').format('L'),
              decision: item.decision,
              information: item.information
            };
          });
        };
      });
  },
  methods: {
    show_information(info) {
      this.information = info;
      this.dialog = true;
    },
  }
};
</script>
