<template>
  <v-card elevation="2">
    <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
      Ajouter un ou plusieurs liens
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" class="pt-2">
            <SubLink
              :label="'Indications validées'"
              :color="'primary'"
              :chosenList.sync="nodesToLink"
              :current_csq="[]"
              :nodeTypesDict="nodeTypesDict"
              :linkLabels="[label]"
            />
          </v-card>
        </v-col>
        <v-col cols="4" class="mt-2">
          <p class="primary--text">
            <b>Type</b>
          </p>
        </v-col>
        <v-col cols="8">
          <v-select
            dense
            outlined
            placeholder="Choisir un type"
            v-model="linkType"
            :items="Object.keys(rel_label_data)"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="4" class="mt-2" v-if="linkType && linkDirection">
          <p class="primary--text">
            <b>Direction</b>
          </p>
        </v-col>
        <v-col cols="8" v-if="linkType && linkDirection">
          <v-select
            dense
            outlined
            placeholder="Choisir une direction"
            v-model="linkDirection"
            :items="possibleDirections"
            item-value="value"
            item-text="name"
            hide-details
            return-object
            @change="updateDirection"
          ></v-select>
        </v-col>
<!--        dosage for drugs-->
        <v-col cols="4" class="mt-2" v-if="linkType && rel_label_data[linkType].relation_model.dosage">
          <span class="font-weight-bold primary--text">Dosage</span>
        </v-col>
        <v-col cols="8" class="pb-4" v-if="linkType && rel_label_data[linkType].relation_model.dosage">
          <v-text-field
              dense
              outlined
              v-model="linkDosage"
              hide-details
          ></v-text-field>
        </v-col>
<!--        reference of dosage for drugs-->
         <v-col cols="4" class="mt-2" v-if="linkType && rel_label_data[linkType].relation_model.reference">
          <span class="font-weight-bold primary--text">Référence</span>
        </v-col>
        <v-col cols="8" v-if="linkType && rel_label_data[linkType].relation_model.reference">
          <v-text-field
              dense
              outlined
              v-model="linkReference"
              hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="4"
          class="mt-2"
          v-if="linkType && rel_label_data[linkType].relation_model.tag"
        >
          <p class="primary--text">
            <b>Tag</b>
          </p>
        </v-col>
        <v-col
          cols="8"
          v-if="linkType && rel_label_data[linkType].relation_model.tag"
        >
          <v-select
            dense
            outlined
            placeholder="Choisir un tag"
            v-model="linkTag"
            :items="
              Object.entries(
                rel_label_data[linkType].relation_model.tag.choices
              )
            "
            hide-details
            :item-value="item => item[0]"
            :item-text="item => item[1]"
          ></v-select>
        </v-col>

        <v-col
          cols="4"
          class="mt-2"
          v-if="linkType && linkType === 'is_prerequired'"
        >
          <p class="primary--text">
            <b>Status</b>
          </p>
        </v-col>
        <v-col
          cols="8"
          v-if="linkType && linkType === 'is_prerequired'"
        >
          <v-select
            dense
            outlined
            placeholder="Choisir un status"
            v-model="linkStatus"
            :items="Object.entries(is_pr_tags)"
            hide-details
            :item-value="item => item[0]"
            :item-text="item => item[1]"
          ></v-select>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            color="primary"
            text
            :disabled="checkData()"
            @click="createLinks()"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import SubLink from "@/components/Utils/SubLink.vue";

export default {
  name: "DetailsAddLink",
  components: {SubLink},
  props: {
    uid: String,
    label: String,
    rel_label_data: Object,
    nodeTypesDict: Object,
    refresh_data: Function,
    toggle_snack: Function
  },
  mounted: function() {
    this.linkType = Object.keys(this.rel_label_data)[0];
  },
  data: () => ({
    refreshing: false,
    nodesToLink: [],
    linkType: "",
    linkDirection: "",
    linkDosage: "",
    linkReference: "",
    possibleDirections: [],
    linkStatus: "",
    linkTag: "",
    is_pr_tags: JSON.parse(
      localStorage.getItem("is_prerequired_tag")
    ),
  }),
  watch: {
    linkType(value) {
      if (value) {
        this.possibleDirections = this.getDirections();
        if (this.possibleDirections.length) {
          this.updateDirection(this.possibleDirections[0])
        }
      } else this.possibleDirections = [];
    },
  },
  methods: {
    createLinks() {
      this.refreshing = true;
      this.toggle_snack("primary", "Création du lien", "", true, 0);

      // set relationship properties
      let properties = {};
      if (this.linkTag) { properties.tag = this.linkTag}
      if (this.linkDosage) { properties.dosage = this.linkDosage}
      if (this.linkReference) { properties.reference = this.linkReference}
      if (this.linkStatus) { properties.status = this.linkStatus}

      // set data
      let flattened_nodes = this.nodesToLink.map(a => a.uid);
      let data = {
        source: this.linkDirection.value === "1" ? this.uid : flattened_nodes,
        target: this.linkDirection.value === "1" ? flattened_nodes : this.uid,
        link_type: this.linkType,
        properties: properties,
      };
      axios
        .post("/api/mkg/link_nodes/", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Liens ajoutés avec succès",
            "mdi-check",
            false,
            2000
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            error.response.data.detail,
            "mdi-close",
            false,
            5000
          );
        })
        .finally(() => {
          this.resetData();
          setTimeout(() => {
            this.refresh_data();
            this.refreshing = false;
          }, 2000);
        });
    },
    getDirections() {
      let directions = [];
      let dict = {
        "-1": { value: "-1", name: "Depuis ce noeud" },
        "1": { value: "1", name: "Vers ce noeud" }
      };
      this.rel_label_data[this.linkType].directions.forEach(element => {
        if (element !== 0) directions.push(dict[element]);
      });
      return directions;
    },
    resetData() {
      this.linkType = "";
      this.linkStatus = "";
      this.linkDosage = "";
      this.linkReference = "";
      this.nodesToLink = [];
    },
    checkData() {
      return !this.linkType ||
          (!this.possibleDirections && !this.linkDirection) ||
          (this.rel_label_data[this.linkType].relation_model.tag && !this.linkTag) ||
          (this.linkType === 'is_prerequired' && !this.linkStatus) ||
          !(this.nodesToLink.length > 0) ||
          this.refreshing;
    },
    updateDirection(direction) {
      this.linkDirection = direction;
    },
  }
};
</script>
