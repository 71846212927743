<template>
  <v-row>
    <v-col cols="12">
<!--      Node information-->
      <v-card elevation="2" class="ml-4 mr-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refresh_data()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur le noeud
				</v-card-title>
        <v-card-text elevation="10" v-if="!launched">
          <v-skeleton-loader
            class="mx-10"
            type="article, actions, image, table-tfoot"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="align-self-center">
              <span class="primary--text align-self-center font-weight-bold">UID</span>
            </v-col>
						<v-col cols="10">
              <span class="primary--text">{{ uid }}</span>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Nom</span>
            </v-col>
            <v-col cols="10">
              <v-text-field
                dense
                outlined
                v-model="name"
                label="Nom du noeud"
                hide-details
                @change="update_field('name_fr', name, route, fieldsDict['name_fr'])"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="align-self-center">
              <span class="primary--text align-self-center font-weight-bold">URL RCP</span>
            </v-col>
            <v-col cols="10">
              <v-text-field
                dense
                outlined
                v-model="rcp_url"
                label="URL RCP"
                hide-details
                @change="update_field('rcp_url', rcp_url, route, fieldsDict['rcp_url'])"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
							<v-switch
								v-model="read_only"
								hide-details
                label="Lecture Seule"
								@change="update_field('read_only', read_only, route, fieldsDict['read_only'])"
							></v-switch>
						</v-col>
            <v-col cols="2" offset="3">
							<v-switch
								v-model="commercialised"
								hide-details
                label="Commercialisé"
								@change="update_field('commercialised', commercialised, route, fieldsDict['commercialised'])"
							></v-switch>
						</v-col>
          </v-row>
        </v-card-text>
      </v-card>
<!--      DCI monography-->
      <v-card elevation="2" class="ml-4 mr-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refresh_data()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Monographie de la DCI
        </v-card-title>
        <v-tabs centered v-model="tab" background-color="transparent">
          <v-tab v-for="item in monography_items" :key="item.tab">
             {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in monography_items" :key="item.tab">
<!--            indications-->
            <v-card flat v-if="item.tab == 'indications'">
              <EditableObjectList
                  :data.sync="indications"
                  :update_field="update_field"
                  :api_route="monography_route"
                  :snack_message="fieldsDict['indications']"
                  field="indications"
                  key_name="indication"
                  value_name="details"
              />
            </v-card>
<!--            dosage_regimens-->
            <v-card v-else-if="item.tab == 'dosage_regimens'" class="ma-8" flat>
              <v-row>
                <v-col cols="12" class="pt-0 text-end">
                  <v-btn small color="info" text @click="addDosageRegimen" :loading="loadingAdd">
                    <span>ajouter un schéma posologique</span>
                  </v-btn>
                </v-col>
                <v-expansion-panels multiple>
                  <v-expansion-panel v-for="(regimen, regimen_index) in dosage_regimens" :key="regimen_index">
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col cols="12">
                          <span class="font-size--16 font-weight-bold primary--text"">Schéma Posologique {{ regimen_index+1 }}</span>
                        </v-col>
                        <v-btn x-small text color="error" dark @click="deleteDosageRegimen(regimen_index)">
                          Supprimer
                        </v-btn>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-model="panels" multiple accordion>
<!--                        voie-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Voie</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-text-field
                                class="ml-4 mr-4"
                                dense
                                outlined
                                v-model="dosage_regimens[regimen_index].route"
                                hide-details
                                label="Voie"
                                @change="update_field('route', regimen.route, regimen_route + regimen.id, fieldsDict['route'], true)"
                            ></v-text-field>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        forme-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Forme</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-text-field
                                class="ml-4 mr-4"
                                dense
                                outlined
                                v-model="dosage_regimens[regimen_index].form"
                                hide-details
                                label="Forme"
                                @change="update_field('form', regimen.form, regimen_route + regimen.id, fieldsDict['form'], true)"
                            ></v-text-field>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        indications-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Indications</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-container fluid>
                              <v-combobox
                                v-model="dosage_regimens[regimen_index].indications"
                                :items="dosage_regimens[regimen_index].indications"
                                hide-selected
                                label="Indications"
                                multiple
                                outlined
                                small-chips
                                dense
                                class="ml-1 mr-1"
                                @change="update_field('indications', regimen.indications, regimen_route + regimen.id, fieldsDict['indications'], true)"
                              >
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    label
                                    small
                                  >
                                    <span class="pr-2">{{ item }}</span>
                                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        target_population-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Population(s) concernée(s)</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-container fluid>
                              <v-combobox
                                v-model="dosage_regimens[regimen_index].target_populations"
                                :items="dosage_regimens[regimen_index].target_populations"
                                hide-selected
                                label="Population(s) concernée(s)"
                                multiple
                                outlined
                                small-chips
                                dense
                                class="ml-1 mr-1"
                                @change="update_field('target_populations', regimen.target_populations, regimen_route + regimen.id, fieldsDict['target_populations'], true)"
                              >
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    label
                                    small
                                  >
                                    <span class="pr-2">{{ item }}</span>
                                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        initial_posology-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Posologie initiale</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card>
                              <v-card-text>
                                <v-row>
                                  <v-col><v-btn small color="info" text @click="save('initial', regimen_index)"><v-icon>mdi-content-save</v-icon></v-btn></v-col>
                                  <v-col cols="12">
                                    <v-text-field label="dose" v-model="regimen.initial_posology.dosage" outlined dense></v-text-field>
                                    <v-text-field label="fréquence" v-model="regimen.initial_posology.frequency" outlined dense></v-text-field>
                                    <v-text-field label="durée de traitement" v-model="regimen.initial_posology.duration" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        maintenance_posology-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Posologie d'entretient</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card>
                              <v-card-text>
                                <v-row>
                                  <v-col><v-btn small color="info" text @click="save('maintenance', regimen_index)"><v-icon>mdi-content-save</v-icon></v-btn></v-col>
                                  <v-col cols="12">
                                    <v-text-field label="dose" v-model="regimen.maintenance_posology.dosage" outlined dense></v-text-field>
                                    <v-text-field label="fréquence" v-model="regimen.maintenance_posology.frequency" outlined dense></v-text-field>
                                    <v-text-field label="durée de traitement" v-model="regimen.maintenance_posology.duration" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        maximum_posology-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Posologie maximale</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-card>
                              <v-card-text>
                                <v-row>
                                  <v-col><v-btn small color="info" text @click="save('maximum', regimen_index)"><v-icon>mdi-content-save</v-icon></v-btn></v-col>
                                  <v-col cols="12">
                                    <v-text-field label="dose" v-model="regimen.maximum_posology.dosage" outlined dense></v-text-field>
                                    <v-text-field label="fréquence" v-model="regimen.maximum_posology.frequency" outlined dense></v-text-field>
                                    <v-text-field label="durée de traitement" v-model="regimen.maximum_posology.duration" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        treatment_discontinuation-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Arrêt du traitement</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <EditableObjectList
                                :data.sync="dosage_regimens[regimen_index].treatment_discontinuation"
                                :update_field="update_field"
                                :api_route="regimen_route + regimen.id"
                                :snack_message="fieldsDict['treatment_discontinuation']"
                                :use_route="true"
                                field="treatment_discontinuation"
                                key_name="title"
                                value_name="description"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
<!--                        additional_information-->
                        <v-expansion-panel>
                          <v-expansion-panel-header class="font-weight-bold primary--text">Informations additionelles</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <EditableObjectList
                                :data.sync="dosage_regimens[regimen_index].additional_information"
                                :update_field="update_field"
                                :api_route="regimen_route + regimen.id"
                                :snack_message="fieldsDict['additional_information']"
                                :use_route="true"
                                field="additional_information"
                                key_name="title"
                                value_name="description"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card>
<!--            contraindications-->
            <v-card flat v-else-if="item.tab === 'contraindications'">
              <v-textarea outlined auto-grow rows="1" dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="contraindications"
                          label="Contre-indications"
                          @change="update_field('contraindications', contraindications, monography_route, fieldsDict['contraindications'])">
              </v-textarea>
            </v-card>
<!--            neurobiology-->
            <v-card flat v-else-if="item.tab === 'neurobiology'">
              <v-textarea outlined rows="1" auto-grow dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="neurobiology"
                          label="Neurobiologie"
                          @change="update_field('neurobiology', neurobiology, monography_route, fieldsDict['neurobiology'])">
              </v-textarea>
            </v-card>
<!--            pharmacodynamics-->
            <v-card flat v-else-if="item.tab === 'pharmacodynamics'">
              <v-textarea outlined rows="1" auto-grow dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="pharmacodynamics"
                          label="Pharmacodynamique"
                          @change="update_field('pharmacodynamics', pharmacodynamics, monography_route, fieldsDict['pharmacodynamics'])">
              </v-textarea>
            </v-card>
<!--            pharmacokinetics-->
            <v-card flat v-else-if="item.tab === 'pharmacokinetics'">
              <v-textarea outlined rows="1" auto-grow dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="pharmacokinetics"
                          label="Pharmacocinétique"
                          @change="update_field('pharmacokinetics', pharmacokinetics, monography_route, fieldsDict['pharmacokinetics'])">
              </v-textarea>
            </v-card>
<!--            astuces d'utilisation-->
            <v-card flat v-else-if="item.tab === 'tips_usage'">
              <v-textarea outlined rows="1" auto-grow dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="tips_usage"
                          label="Astuces d'utilisation"
                          @change="update_field('tips_usage', tips_usage, monography_route, fieldsDict['tips_usage'])">
              </v-textarea>
            </v-card>
<!--            astuces de précaution-->
            <v-card flat v-else-if="item.tab === 'tips_precaution'">
              <v-textarea outlined rows="1" auto-grow dense clear-icon="mdi-close" hide-details class="ma-4"
                          v-model="tips_precaution"
                          label="Astuces de précaution"
                          @change="update_field('tips_precaution', tips_precaution, monography_route, fieldsDict['tips_precaution'])">
              </v-textarea>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
<!--    Relationship Actions-->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="label"
        :nodeTypesDict="node_dict"
        :refresh_data="refresh_data"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
    <v-dialog v-model="deleteDialog" max-width="620px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Êtes-vous sûr de bien vouloir supprimer cet élément?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteDosageRegimenConfirm" :loading="loadingDelete">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
  </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import EditableObjectList from "@/components/Utils/EditableObjectList";
import RelationshipActions from "@/components/Details/RelationshipActions";

export default {
  name: "DciDetails",
  components: {
    EditableObjectList,
    RelationshipActions
  },
  props: {
    uid: String,
    label: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/dci/",
    monography_route: "/api/drugs/dci_monography/",
    regimen_route: "/api/drugs/dosage_regimen/",
    launched: false,
    loaded: false,
    tab: null,
    rel_data: null,
    name: "",
    rcp_url: "",
    commercialised: null,
    read_only: null,
    updated_by: "",
    updated_at: "",
    dci_monography_id: null,
    indications: null,
    dosage_regimens: null,
    contraindications: null,
    neurobiology: null,
    pharmacodynamics: null,
    pharmacokinetics: null,
    tips_usage: null,
    tips_precaution: null,
    loadingAdd: false,
    loadingDelete: false,
    deleteDialog: false,
    deleteIndex: null,
    panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    monography_items: [
        {tab: "indications", title: "Indications"},
        {tab: "dosage_regimens", title: "Posologie"},
        {tab: "contraindications", title: "Contre-indications"},
        {tab: "neurobiology", title: "Neurobiologie"},
        {tab: "pharmacodynamics", title: "Pharmacodynamique"},
        {tab: "pharmacokinetics", title: "Pharmacocinétique"},
        {tab: "tips_usage", title: "Astuces d'utilisation"},
        {tab: "tips_precaution", title: "Astuces de précaution"},
    ],
    fieldsDict: {
      name: "du nom du noeud",
    },
    dangerosity_rule: v =>
      /^([0-9]|[1-9][0-9]|100)$/.test(v) || "Dangerosité comprise etre 0 et 100"
  }),
  mounted: function() {
    this.refresh_data();
  },
  methods: {
    get_neo4j_data() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: this.label
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.name = nodeData.name_fr.value;
          this.rcp_url = nodeData.rcp_url.value;
          this.read_only = nodeData.read_only.value;
          this.commercialised = nodeData.commercialised.value;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données de neo4j",
            "mdi-close",
            false,
            10000
          );
        });
    },
    get_dci_monography() {
      axios
          .get(this.monography_route + this.uid, {
            params: {
              load_from_cache: false
            }
          })
          .then(response => {
            this.indications = response.data.indications;
            this.dosage_regimens = response.data.regimens;
            this.contraindications = response.data.contraindications;
            this.neurobiology = response.data.neurobiology;
            this.pharmacodynamics = response.data.pharmacodynamics;
            this.pharmacokinetics = response.data.pharmacokinetics;
            this.tips_usage = response.data.tips_usage;
            this.tips_precaution = response.data.tips_precaution;
            this.dci_monography_id = response.data.id;
          })
          .catch(error => {
            this.toggle_snack(
                "error",
                "Un problème est survenu lors de la récupération des données de monographie",
                "mdi-close",
                false,
                10000
            );
          })
          .finally(() => {
            this.launched = true;
            this.loaded = true;
            this.snack_data.show = false;
            this.$emit("update:snack_data", this.snack_data);
          });
    },
    update_dosage_regimen(index) {
      let new_regimen = this.dosage_regimens[index]
      axios
          .patch("/api/drugs/dosage_regimen/" + new_regimen.id, new_regimen, {
            headers: { "X-CSRFToken": getCookie("csrftoken") }
          });
    },
    refresh_data() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.get_neo4j_data();
      this.get_dci_monography();
    },
    addDosageRegimen() {
      this.loadingAdd = true;
      let newDosageRegimen = {
        indications: [],
        target_populations: [],
        route: null,
        form: null,
        initial_posology: {dosage: null, frequency: null, duration: null},
        maintenance_posology: {dosage: null, frequency: null, duration: null},
        maximum_posology: {dosage: null, frequency: null, duration: null},
        treatment_discontinuation: [{title: null, description: null}],
        additional_information: [{title: null, description: null}],
        dci_monography: this.dci_monography_id,
      }
      axios.post(
          `/api/drugs/dosage_regimen`,
          newDosageRegimen,
          {headers: { "X-CSRFToken": getCookie("csrftoken") }
        }).then(response => {
          this.dosage_regimens.push(response.data);
        }).finally(() => {
          this.close();
          this.loadingAdd = false;
        }).catch(error => {
          this.manageError(error);
        });
    },
    manageError(error) {
      if (error.response) {
        this.$store.dispatch("ManageError", error.response.status);
      } else {
        this.$store.commit("PostError", "Une erreur est survenue");
      }
    },
    close () {
      this.deleteDialog = false;
      this.deleteIndex = null;
    },
    deleteDosageRegimen(index) {
      this.deleteDialog = true;
      this.deleteIndex = index;
    },
    deleteDosageRegimenConfirm() {
      axios.delete(
         `/api/drugs/dosage_regimen/${this.dosage_regimens[this.deleteIndex].id}`,
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
      ).then(response => {
        if (this.deleteIndex > -1) {
          this.dosage_regimens.splice(this.deleteIndex, 1);
        }
      }).finally(() => {
        this.close();
        this.loadingDelete = false;
      }).catch(error => {
        this.manageError(error);
      });
    },
    save(posology_type, regimen_index) {
      if (posology_type === "initial") {
        this.update_field('initial_posology', this.dosage_regimens[regimen_index].initial_posology, this.regimen_route + this.dosage_regimens[regimen_index].id, this.fieldsDict['initial_posology'], true)
      } else if (posology_type === "maintenance") {
        this.update_field('maintenance_posology', this.dosage_regimens[regimen_index].maintenance_posology, this.regimen_route + this.dosage_regimens[regimen_index].id, this.fieldsDict['maintenance_posology'], true)
      } else if (posology_type === "maximum") {
        this.update_field('maximum_posology', this.dosage_regimens[regimen_index].maximum_posology, this.regimen_route + this.dosage_regimens[regimen_index].id, this.fieldsDict['maximum_posology'], true)
      }
    }
  }
};
</script>
