<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" class="pt-0 text-end">
        <v-btn small color="info" text @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn small color="info" text @click="save">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card class="mr-1 ml-1 mb-1" :key="index" v-for="(item, index) in data" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="data[index][key_name]"
                  :label="key_name"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    dense outlined hide-details auto-grow rows="1"
                    v-model="data[index][value_name]"
                    :label="value_name"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="error" x-small dark @click="removeItem(index)">
              Supprimer
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "EditableObjectList",
  props: {
    data: Array,
    key_name: String,
    value_name: String,
    field: String,
    update_field: Function,
    snack_message: String,
    api_route: String,
    use_route: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    fieldsDict: {
      monography: "de la monographie",
    },
  }),
  methods: {
    addItem() {
      let newItem = {
        [this.key_name]: null,
        [this.value_name]: null
      }
      this.data.push(newItem);
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
    save() {
      this.update_field(this.field, this.data, this.api_route, this.snack_message, this.use_route)
    }
  }
};
</script>
