import Vue from 'vue'
import VueRouter from 'vue-router'

import ConnexionView from "../components/Global/ConnexionView";
import DeconnexionView from "@/components/Global/DeconnexionView.vue";
import ResetPasswordView from "../components/Global/ResetPasswordView";
import MkgLayout from "../components/MkgLayout";
import Home from '@/components/Pages/Home'
import Details from "@/components/Pages/Details";
import Graph from "@/components/Pages/Graph";
import Translate from "@/components/Pages/Translate";
import Questionnaire from "@/components/Pages/Questionnaire";
import QuestionnaireList from "@/components/Pages/QuestionnaireList";
import Maintenance from "@/components/Pages/Maintenance";
import DisplayNodes from "@/components/Pages/DisplayNodes";
import RecentChanges from "@/components/Pages/RecentChanges";
import DifferentialDiagnosis from "@/components/Pages/DifferentialDiagnosis";
import DemoMachineLearning from "@/components/Pages/DemoMachineLearning";
import AtcClassification from "@/components/Pages/AtcClassification";
import TestCases from "@/components/Pages/TestCases";
import Translation from "@/components/Pages/Translation";
import MaintenanceList from "@/components/Pages/Template/MaintenanceList";
import TableList from "@/components/Pages/Template/TableList";
import SymptomList from "@/components/Pages/Template/SymptomList";
import SymptomLabel from "@/components/Pages/Template/SymptomLabel";
import RiskFactorLabel from "@/components/Pages/Template/RiskFactorLabel";
import PrimaryMedClass from "@/components/Pages/Template/PrimaryMedClass";
import Duplicates from "@/components/Pages/Template/Duplicates";
import TestCaseList from "@/components/Pages/Template/TestCaseList";
import UnreachableCluster from "@/components/Pages/Template/UnreachableCluster";
import AddQuestionnaireView from "@/components/Pages/AddQuestionnaireView.vue";

import router_error_pages from "anam-library/src/js/router_error_pages";

Vue.use(VueRouter)

const routes = [
    router_error_pages.deconnexion_page,
	router_error_pages.forbidden_page,
	router_error_pages.not_found_page,
    router_error_pages.wildcard_page,
	router_error_pages.nginx_error_page,
	router_error_pages.error_server_page,
    router_error_pages.expiration_token_page,
    {
        path: "/",
        redirect: {name: 'ConnexionLayout'},
    },
    {
        path: "/connexion",
        name: "ConnexionLayout",
        component: ConnexionView,
        meta: {
            title: "Connexion MKG"
        }
    },
    {
        path: '/logout',
        name: 'DeconnexionView',
        component: DeconnexionView
    },
    {
        path: "/reset_password",
        name: "ResetPassword",
        component: ResetPasswordView,
        meta: {
            title: "Mot de passe oublié MKG"
        }
    },
    {
        path: "/mkg",
        name: "MKG",
        redirect: {name: 'Home'},
        component: MkgLayout,
        children: [
            {
                path: "/home",
                name: "Home",
                component: Home,
                meta: {
                    title: "Accueil MKG"
                }
            },
            {
                path: "/details/:uid",
                name: "Details",
                component: Details,
                meta: {
                    title: "Détails"
                }
            },
            {
                path: "/graph/:uid",
                name: "Graph",
                component: Graph,
                meta: {
                    title: "Graphe"
                }
            },
            {
                path: "/list/:type",
                name: "DisplayNodes",
                component: DisplayNodes,
                meta: {
                    title: "Visualiser"
                }
            },
            {
                path: "/translate/:uid",
                name: "Translate",
                component: Translate,
                meta: {
                    title: "Traduction"
                }
            },
            {
                path: "/questionnaires",
                name: "QuestionnaireList",
                component: QuestionnaireList,
                meta: {
                    title: "Liste des questionnaire"
                }
            },
            {
                path: "/questionnaires/add-questionnaire",
                name: "AddQuestionnaire",
                component: AddQuestionnaireView,
                meta: {
                    title: "Ajout d'un questionnaire"
                }
            },
            {
                path: "/questionnaires/:name",
                name: "Questionnaire",
                component: Questionnaire,
                meta: {
                    title: "Questionnaire"
                }
            },
            {
                path: "/maintenance",
                name: "Maintenance",
                component: Maintenance,
                meta: {
                    title: "Maintenance"
                }
            },
            {
                path: "/maintenance/:name",
                name: "MaintenanceList",
                component: MaintenanceList,
                meta: {
                    title: "Maintenance des noeuds"
                }
            },
            {
                path: "/maintenance/table/:name",
                name: "TableList",
                component: TableList,
                meta: {
                    title: "Maintenance des noeuds"
                }
            },
            {
                path: "/maintenance/duplicates/:name",
                name: "Duplicates",
                component: Duplicates,
                meta: {
                    title: "Maintenance des noeuds"
                }
            },
            {
                path: "/maintenance/test_cases/:name",
                name: "TestCaseList",
                component: TestCaseList,
                meta: {
                    title: "Maintenance des cas cliniques"
                }
            },
            {
                path: "/maintenance/symptoms_vibility/:name",
                name: "SymptomList",
                component: SymptomList,
                meta: {
                    title: "Symptom Visible/Non Visible"
                }
            },
            {
                path: "/maintenance/primary_labels/symptom_primary_label",
                name: "SymptomLabel",
                component: SymptomLabel,
                meta: {
                    title: "Label principal des symptomes"
                }
            },
            {
                path: "/maintenance/primary_labels/riskfactor_primary_label",
                name: "RiskFactorLabel",
                component: RiskFactorLabel,
                meta: {
                    title: "Label principal des facteurs de risque"
                }
            },
            {
                path: "/maintenance/disease/primary_med_class",
                name: "PrimaryMedClass",
                component: PrimaryMedClass,
                meta: {
                    title: "Classification principale des maladies"
                }
            },
            {
                path: "/maintenance/disease/clusters_unreachable_by_spe",
                name: "UnreachableCluster",
                component: UnreachableCluster,
                meta: {
                    title: "Maladies inatteignable par spécialité"
                }
            },
            {
                path: "/differential_diagnosis",
                name: "DifferentialDiagnosis",
                component: DifferentialDiagnosis,
                meta: {
                    title: "Diagnostic Différentiel"
                }
            },
            {
                path: "/machine_learning",
                name: "DemoMachineLearning",
                component: DemoMachineLearning,
                meta: {
                    title: "Démo Machine Learning"
                }
            },
            {
                path: "/test_cases",
                name: "TestCases",
                component: TestCases,
                meta: {
                    title: "Test Cases"
                }
            },
            {
                path: "/atc_classification",
                name: "AtcClassification",
                component: AtcClassification,
                meta: {
                    title: "Atc Classification"
                }
            },
            {
                path: "/recent_changes",
                name: "RecentChanges",
                component: RecentChanges,
                meta: {
                    title: "Recent Changes"
                }
            },
            {
                path: "/translation",
                name: "Translation",
                component: Translation,
                meta: {
                    title: "Translation"
                }
            },
        ]
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router
