<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer un groupe
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'Group'"
              :linkNode="true"
            />
            <v-col cols="12">
              <v-autocomplete
                v-model="subGrpOf"
                :search-input.sync="searchGrp"
                :loading="loadingGrp"
                :items="allGroups"
                label="Sous groupe de"
                item-text="name"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title v-if="noResult">
                      Pas de resultat pour "{{searchGrp}}"
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      Rechercher un group dont celui-ci est un sous-groupe
                      (min. 3 lettres)
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item>
                    <v-list-item-avatar
                      size="26"
                      color="#8ee5ee"
                      class="white--text"
                    >
                      G
                    </v-list-item-avatar>
                    <v-list-item-content @click="subGrpOf = item">
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
						class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
	name: "CreateGroupModal",
  components: {
    CreateModalCore
	},
	data: () => ({
    noResult: false,
    searchGrp: "",
    subGrpOf: "",
    loadingGrp: false,
    allGroups: [],
    timeout: null,
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    required_rule: (v) => !!v || "Ce champ est requis",
    len_rule: (v) => (v && v.length <= 100) || "Max 100 caractères",
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object,
  },
  watch: {
    searchGrp(value) {
      this.noResult = false;
      if (value && value.length >= 3) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.loadingGrp = true;
          let data = {txt: value, labels: ["Group"], medical_classification_filter: null, group_by_label: false}
          axios
            .get("/api/mkg/search_ind", {
              params: {"payload": data},
              headers: {"X-CSRFToken": getCookie("csrftoken")},
            })
            .then((response) => {
              this.allGroups = response.data.all;
            })
            .catch((error) => {
              this.$store.dispatch("ManageError", error.response.status)
            })
            .finally(() => {
              if (!this.allGroups || this.allGroups.length === 0)
                this.noResult = true;
              this.loadingGrp = false;
            });
        }, 500);
      } else {
        this.allGroups = [];
        this.noResult = false;
      }
    },
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.noResult = false;
      this.searchGrp = "";
      this.subGrpOf = "";
      this.loadingGrp = false;
      this.allGroups = [];
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let data = {
        parent_node: this.subGrpOf.uid || null,
        name: this.$store.state.newName,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes,
      };
      axios
        .post("/api/groups", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") },
        })
        .then((response) => {
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch((error) => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    },
  },
};
</script>
