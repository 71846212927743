<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur la question
				</v-card-title>
        <v-card-text>
          <v-skeleton-loader v-if="!launched" class="mx-10" type="article, actions, image, table-tfoot"></v-skeleton-loader>
          <v-row v-else class="primary--text">
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end align-self-center"><b>UID</b></v-col>
            <v-col cols="10" class="text-start align-self-center">{{uid}}</v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Nom</b></v-col>
            <v-col cols="7" class="align-self-center">
              <v-text-field dense outlined v-model="nodeName" label="Nom" hide-details
                            @change="update_field('name', nodeName, route, fieldsDict['name'])">
              </v-text-field>
            </v-col>
            <v-col cols="2" class=" mt-1">
							<v-btn color="primary" elevation="0" :href="'/translate/' + uid"> Traduction </v-btn>
						</v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Falcultative</b></v-col>
            <v-col cols="5" class="text-end">
              <v-switch @change="update_field('is_optional', is_optional, route, fieldsDict['is_optional'])"
                        v-model="is_optional" color="primary" inset>
              </v-switch>
            </v-col>
            <v-col cols="4" class="align-self-center text-right">
              <v-btn elevation="0" class="white--text" color="primary" @click="impact()">Modale d'Impact</v-btn>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Type de question</b></v-col>
            <v-col cols="4" class="text-center align-self-center">
              <v-select outlined dense hide-details placeholder="Non défini"
                        v-model="ofType"
                        :items="questionTypes" :item-text="item => item[1]" :item-value="item => item[0]"
                        @change="update_field('of_type', ofType, route, fieldsDict['of_type'])">
              </v-select>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Format de réponse</b></v-col>
            <v-col cols="4" class="text-center align-self-center">
              <v-select outlined dense hide-details placeholder="Non défini"
                        v-model="ofFormat"
                        :items="answerFormat" :item-text="item => item[1]" :item-value="item => item[0]"
                        @change="update_field('of_format', ofFormat, route, fieldsDict['ofFormat'])">
              </v-select>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Spécialité(s)</b></v-col>
            <v-col cols="4">
              <v-autocomplete outlined dense hide-details placeholder="Aucune séléctionné" multiple
                              v-model="nodeSpe"
                              :items="speciality_list" :item-text="item => item[1]" :item-value="item => item[0]"
                              @change="update_field('medical_classification', nodeSpe, route, fieldsDict['medical_classification'])">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>Pas de resultat pour cette recherche</v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Questionnaire</b></v-col>
            <v-col cols="4">
              <v-autocomplete ref="questField" dense outlined clearable hide-details class="ma-auto"
                              :loading="loadingQuest"
                              v-model="meta_questionnaire_name"
                              :items="questList" :item-text="item => item.commercial_name" :item-value="item => item.name"
                              label="Chercher un questionnaire"
                              prepend-inner-icon="mdi-magnify" @change="updateQuest()">
                <template v-slot:no-data>
                  <v-list-item>Pas de correspondance</v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>URL photo/vidéo</b></v-col>
            <v-col cols="10" class="align-self-center">
              <v-text-field dense outlined v-model="media_link" label="URL photo/vidéo" hide-details
                            @change="update_field('media_link', media_link, route, fieldsDict['media_link'])">
              </v-text-field>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Afficher la description</b></v-col>
            <v-col cols="5" class="text-end">
              <v-switch @change="update_field('description_display', show_description, route, fieldsDict['description_display'])"
                        v-model="show_description" color="primary" inset>
              </v-switch>
            </v-col>
            <v-col cols="12" class="text-start">
              <v-textarea outlined auto-grow clearable clear-icon="mdi-close" hide-details
                          v-model="description"
                          label="Description (Optionnel) — Mot clés: TODO, FIXME"
                          @change="update_field('description', description, route, fieldsDict['description'])">
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <ImpactModal ref="impact" @impact_maj="impactUpdated($event)" :uid="impactUid" :dialog.sync="impactDialog"/>

    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0">
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
            <v-skeleton-loader v-if="!launched" type="article, table-tfoot" class="pa-5"></v-skeleton-loader>
            <v-card-text v-else>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn :disabled="!prChanged" text color="blue darken-1" @click="save_pr(prerequisites, route);prChanged = false;">
                    Enregistrer
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mr-4 mt-4 mb-0">
            <v-skeleton-loader v-if="!launched" class="ma-5" type="table-tfoot"></v-skeleton-loader>
            <v-row v-else class="primary--text">
              <v-col cols="3" class="text-center align-self-center"><b>Scoring</b></v-col>
              <v-col cols="2" class="text-center align-self-center">
                <v-switch @change="updateScoring()"
                          v-model="is_scoring"
                          color="primary" inset hide-details class="mt-0">
                </v-switch>
              </v-col>
              <v-col cols="7" class="text-right">
                <v-btn text class="white--text" color="primary" @click="scoringDialog = true">
                  Ajouter un scoring
                </v-btn>
              </v-col>
              <v-col v-if="is_scoring" cols="5" class="align-self-center"><b>Type de scoring</b></v-col>
              <v-col v-if="is_scoring" cols="7">
                <v-select filled dense hide-details
                          v-model="scoring_type"
                          :items="scoringTypes"
                          @change="updateScoring()">
                </v-select>
              </v-col>
              <v-col v-if="is_scoring" cols="5" class="align-self-center"><b>Nom du scoring</b></v-col>
              <v-col v-if="is_scoring" cols="7">
                <v-autocomplete filled dense hide-details
                          v-model="scoring_name"
                          :items="scoringNames"
                          @change="updateScoring()">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title style="font-size: small">Pas de scoring pour le moment</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-dialog v-model="scoringDialog" width="650">
          <v-card>
            <v-card-title class="primary white--text">
              Ajouter un nom de scoring
            </v-card-title>
            <v-card-text class="py-5">
              <v-form ref="form_scoring" v-model="validScoringForm" autocomplete="off">
                <v-row>
                  <v-col cols="12" fluid>
                    <v-text-field
                      v-model="scoringToAdd"
                      label="Nom du scoring *"
                      counter="50"
                      :rules="[required_rule, len50_rule]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="text-end">
                <v-btn outlined color="primary" @click="close_scoring">Fermer</v-btn>
                <v-btn :disabled="!validScoringForm" @click="save_scoring" color="primary">Enregistrer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <!-- Actions -->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'Question'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";
import ImpactModal from "@/components/Dialog/ImpactModal";

export default {
  name: "QuestionDetails",
  components: {
    ImpactModal,
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/questions/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeName: "",
    nodeSpe: [],
    description: "",
    media_link: null,
    prerequisites: [],
    is_optional: false,
    show_description: false,
    ofType: "",
    questionTypes: JSON.parse(localStorage.getItem("question_choices_2")),
    ofFormat: "",
    answerFormat: JSON.parse(localStorage.getItem("display_answers_2")),
    is_scoring: false,
    scoring_type: "",
    scoring_name: "",
    is_deprecated: null,
    validScoringForm: false,
    scoringToAdd: null,
    scoringDialog: false,
    scoringNames: null,
    scoringTypes: JSON.parse(localStorage.getItem("scoring_choices")),
    required_rule: v => !!v || "Ce champ est requis",
    len50_rule: v => (v && v.length <= 50) || "Max 50 caractères",
    prChanged: false,
    impactUid: "",
    impactDialog: false,
    meta_questionnaire_name: null,
    loadingQuest: false,
    questList: [],
    updated_by: "",
    updated_at: "",
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
      description: "de la description",
      questionnaire: "du nom du questionnaire",
      is_optional: "du status facultatif",
      ofType: "du type de question",
      ofFormat: "du format de réponse",
      is_scoring: "du scoring",
      scoring_type: "du type de scoring",
      scoring_name: "du nom du scoring"
    },
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    }
  },
  mounted: function() {
    this.getQuestionnaires();
    this.refreshData();
  },
  methods: {
    updateQuest() {
      if (!this.meta_questionnaire_name) {
        this.update_field(
          "meta_questionnaire_name",
          "",
          this.route,
          "du questionnaire"
        );
      } else {
        this.update_field(
          "meta_questionnaire_name",
          this.meta_questionnaire_name,
          this.route,
          "du questionnaire"
        );
      }
      this.$refs.questField.blur();
    },
    getQuestionnaires() {
      if (!this.questList.length) {
        this.loadingQuest = true;
        axios
          .get("/api/mkg/questionnaires", {
            params: {
              list: true
            }
          })
          .then(response => {
            this.questList = response.data.questionnaire_list;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loadingQuest = false;
          });
      }
    },
    getData() {
      this.getScoringData();
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "Question"
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeName = nodeData.name_fr.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.description = nodeData.description.value;
          this.show_description = nodeData.description_display.value;
          this.is_optional = nodeData.is_optional.value;
          this.ofType = nodeData.of_type.value;
          this.ofFormat = nodeData.format.value;
          this.media_link = nodeData.media_link.value;
          this.is_scoring = nodeData.scoring.value.is_scoring;
          if (this.is_scoring) {
            this.scoring_type = nodeData.scoring.value.scoring_type;
            this.scoring_name = nodeData.scoring.value.scoring_name;
          }
          this.is_deprecated = nodeData.is_deprecated.value;
          this.meta_questionnaire_name = nodeData.meta_questionnaire_name.value;
          if (this.meta_questionnaire_name === "")
            this.meta_questionnaire_name = null;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    },
    getScoringData() {
      this.scoringNames = this.$store.getters.getScoringNames;
    },
    close_scoring() {
      this.$refs.form_scoring.reset();
      this.scoringToAdd = null;
      this.scoringDialog = false;
    },
    save_scoring() {
      axios
        .post(
          "/api/mkg/manage_scoring?scoring_name=" + this.scoringToAdd.replace(/ /g, "_"),
          {}, { headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Nom de scoring ajouté",
            "mdi-check",
            false,
            2000
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            error.response.data.detail,
            "mdi-close",
            false,
            4000
          );
        })
        .finally(() => {
          this.getScoringData();
        });
      this.close_scoring();
    },
    updateScoring() {
      if (!this.scoring_type || !this.scoring_name) {
        this.toggle_snack(
          "warning",
          "Type ET Nom du scoring requis",
          "mdi-alert",
          false,
          3000
        );
        return;
      }
      this.toggle_snack("primary", "Mis à jour du scoring", "", true, 0);
      let updateDataDict = {};
      updateDataDict["is_scoring"] = this.is_scoring;
      if (!this.is_scoring) {
        this.scoring_type = null;
        this.scoring_name = null;
      }
      updateDataDict["scoring_type"] = this.scoring_type;
      updateDataDict["scoring_name"] = this.scoring_name;
      updateDataDict["details"] = true;
      axios
        .patch(this.route + this.uid, updateDataDict, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Mis à jour avec succès",
            "mdi-check",
            false,
            1000
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            error.response.data.detail,
            "mdi-close",
            false,
            4000
          );
        })
        .finally(() => {
          setTimeout(() => {
            this.snack_data.show = false;
            this.$emit("update:snack_data", this.snack_data);
          }, 4000);
        });
    },
    impact() {
      this.impactUid = this.uid;
      this.$refs.impact.getImpactData(this.uid);
      this.impactDialog = true;
    },
    impactUpdated(event) {
      let success = event.success ? "success" : "error"
      this.toggle_snack(
        success,
        event.message,
        "mdi-check",
        false,
        2000
      );
      setTimeout(() => {
        this.refreshData();
      }, 2000);
    }
  }
};
</script>

<style>
  .v-btn__content {
    text-transform: initial;
  }
</style>