<template>
  <v-container style="max-width: 100%;">
    <v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="paragraph@30"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card v-if="description" color="#BDD5EA" tile class="pa-5">
        <b>{{ description }}</b>
      </v-card>
      <InfoCard v-if="infoList" :title="'Consignes'" :items="infoList" />
      <div style="display: flex; align-items: center; justify-content: center;">
        <v-btn
          x-large
          class="ma-3"
          color="error"
          @click="axios.get('/api/mkg/clusters_unreachable_by_spe')"
          >RELOAD</v-btn
        >
      </div>
      <v-card :key="key" v-for="(value, key) in itemList" class="mt-5">
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title> {{ key }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>
              {{ Object.keys(value["cl_unreachable"]).length }} /
              {{ value.nb_total }}
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="mt-2">
          <div
            style="text-align: center"
            v-if="Object.keys(value['cl_unreachable']).length == 0"
          >
            <b>Cette liste est vide</b>
          </div>
          <ul id="info_list">
            <li :key="uid" v-for="(name, uid) in value['cl_unreachable']">
              <a
                style="text-decoration: none; color:black"
                @click="$router.push('/details/' + uid)"
              >
                {{ name }}
              </a>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import InfoCard from "@/components/Utils/InfoCard";

export default {
  name: "QuestionDetails",
  components: { InfoCard },
  data: () => ({
    loading: true,
    currLen: 0,
    description: "",
    itemList: [],
    infoList: null
  }),
  created: function() {
    axios
      .get("/api/mkg/clusters_unreachable_by_spe", {})
      .then(response => {
        this.description = response.data.description;
        this.itemList = response.data.item_list;
        this.infoList = response.data.info_list;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
