<template>
  <v-dialog v-model="localDialog" width="75%">
    <v-card>
      <v-card-title>
        <span class="primary--text font-weight-bold text-h5">Impact des réponses</span>
      </v-card-title>
      <v-card-text class="pt-5 pb-2">
        <v-card elevation="2">
          <v-card-title class="primary--text mb-4 pa-2 text-h6 soft_grey">
            Paramètres de la Question
          </v-card-title>
          <v-card-text class="py-5">
            <v-form ref="q_form" v-model="validForm" autocomplete="off">
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newName"
                    label="Nom du noeud *"
                    outlined
                    color="primary"
                    dense
                    :rules="[required_rule]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-autocomplete
                    v-model="specialities"
                    :rules="[spe_rule]"
                    :items="specialityList"
                    :item-text="item => item[1]"
                    :item-value="item => item[0]"
                    small-chips
                    label="Spécialité(s) *"
                    outlined
                    color="primary"
                    multiple
                    dense
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Pas de resultat pour cette recherche
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-select
                    label="Type de question *"
                    v-model="ofType"
                    :rules="[required_rule]"
                    :items="questionTypes"
                    :item-text="item => item[1]"
                    :item-value="item => item[0]"
                    outlined
                    color="primary"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-select
                    label="Format de réponse *"
                    v-model="ofFormat"
                    :rules="[required_rule]"
                    :items="answerFormat"
                    :item-text="item => item[1]"
                    :item-value="item => item[0]"
                    outlined
                    color="primary"
                    dense
                  >
                  </v-select>
                </v-col>
                <Prerequisites
                  :validForm.sync="validForm"
                  :prerequisites.sync="prerequisites"
                />
                <QuestionScoring
                  class="ml-3"
                  :validForm.sync="validForm"
                  :is_scoring.sync="is_scoring"
                />
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mt-3">
          <v-card-title class="primary--text mb-4 pa-2 text-h6 soft_grey">
            Paramètres des réponses
            <v-row>
              <v-col class="text-end py-0">
                <v-btn
                  class="mt-2 mb-2"
                  :disabled="adding"
                  @click="adding = true"
                  text
                  color="primary"
                >
                  Ajouter une réponse
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="is_scoring && $store.state.scoring_type === 'eva'">
            <v-form ref="form" v-model="validEVA" autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="eva_first"
                    label="Intitulé de la première réponse *"
                    :rules="[required_rule]"
                    hint="Score : 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="eva_last"
                    label="Intitulé de la seconde réponse *"
                    :rules="[required_rule]"
                    hint="Score : 10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-else class="pa-0">
            <v-card class="ma-2">
              <v-col v-if="adding" cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-form v-if="adding" ref="form" v-model="validAnswer" autocomplete="off">
                      <v-row class="px-3">
                        <v-col cols="7">
                          <v-text-field
                            outlined
                            dense
                            v-model="answerName"
                            label="Nom de la réponse *"
                            :rules="[required_rule]"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="is_scoring" cols="5">
                          <v-text-field
                            outlined
                            dense
                            v-model="scoringValue"
                            label="Score"
                            type="number"
                            :rules="[required_rule, scoring_rule]"
                          ></v-text-field>
                        </v-col>
                        <v-col v-else cols="5" class="py-0">
                          <v-row class="pl-12">
                            <v-col cols="6" class="py-0">
                              <v-checkbox
                                v-model="is_neither_of_them"
                                label="Aucune de ces réponses"
                                color="primary"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="6" class="py-0">
                              <v-checkbox
                                v-model="is_free_answer"
                                label="Entrée libre"
                                color="primary"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col>
                    <v-card flat>
                      <SubLink
                        :answer="tmpAnswer"
                        :label="'Chainer une question'"
                        :color="'grey'"
                        :current_csq="[]"
                        :chosenList.sync="tmpQuestion"
                        :nodeTypesDict="nodeTypesDict"
                        :linkLabels="['MasterQuestion', 'Question']"
                        :link_type="'chained'"
                        :link_data="{ chained: [] }"
                      />
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card flat>
                      <v-row>
                        <v-col>
                          <SubLink
                            :answer="tmpAnswer"
                            :label="'Présence'"
                            :color="'primary'"
                            :chosenList.sync="tmpPresence"
                            :current_csq="tmpAbsence.concat(tmpIgnorance)"
                            :nodeTypesDict="nodeTypesDict"
                            :linkLabels="['Symptom', 'RiskFactor']"
                            :link_type="'consequence'"
                            :link_data="{ status: '1' }"
                          />
                        </v-col>
                        <v-col>
                          <SubLink
                            :answer="tmpAnswer"
                            :label="'Absence'"
                            :color="'primary'"
                            :chosenList.sync="tmpAbsence"
                            :current_csq="tmpPresence.concat(tmpIgnorance)"
                            :nodeTypesDict="nodeTypesDict"
                            :linkLabels="['Symptom', 'RiskFactor']"
                            :link_type="'consequence'"
                            :link_data="{ status: '0' }"
                          />
                        </v-col>
                        <v-col>
                          <SubLink
                            :answer="tmpAnswer"
                            :label="'Ignorance'"
                            :color="'primary'"
                            :chosenList.sync="tmpIgnorance"
                            :current_csq="tmpAbsence.concat(tmpPresence)"
                            :nodeTypesDict="nodeTypesDict"
                            :linkLabels="['Symptom', 'RiskFactor']"
                            :link_type="'consequence'"
                            :link_data="{ status: '2' }"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="text-end">
                    <v-btn
                      text
                      color="blue darken-1"
                      @click="restore()"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      text
                      :disabled="!validAnswer"
                      :loading="validateLoading"
                      @click="addAnswer"
                      color="blue darken-1"
                    >
                      {{ tmpAnswer ? "Sauvegarder" : "Ajouter" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
            <v-col cols="12">
              <v-card outlined v-if="answersList.length === 0">
                <v-card-text>
                  Aucune réponse pour cette question pour le moment
                </v-card-text>
              </v-card>
              <v-simple-table v-else>
                <thead>
                  <tr>
                    <th class="text-center" style="width: 1em;">Ordre</th>
                    <th class="text-start">Nom</th>
                    <th class="text-start" style="width: 1em;">
                      Aucune de ces réponses
                    </th>
                    <th class="text-start" style="width: 1em;">Entrée libre</th>
                    <th class="text-start">Chainage</th>
                    <th class="text-start">Conséquences</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(answer, index) in answersList">
                    <td class="text-center">
                      <v-btn
                        class="px-0"
                        v-if="answersList.length === 1"
                        icon
                        x-small
                        color="primary"
                        disabled
                        @click="move(answersList, index, index - 1)"
                        ><v-icon>mdi-adjust</v-icon></v-btn
                      >
                      <v-btn
                        class="px-0"
                        v-if="index > 0"
                        icon
                        x-small
                        color="primary"
                        @click="move(answersList, index, index - 1)"
                        ><v-icon>mdi-chevron-up</v-icon></v-btn
                      >
                      <v-btn
                        class="px-0"
                        v-if="index < answersList.length - 1"
                        icon
                        x-small
                        color="primary"
                        @click="move(answersList, index, index + 1)"
                        ><v-icon>mdi-chevron-down</v-icon></v-btn
                      >
                    </td>
                    <td>{{ answer.name }}</td>
                    <td class="text-center">
                      <v-checkbox
                        v-model="answer.is_neither_of_them"
                        disabled
                        color="primary"
                      ></v-checkbox>
                    </td>
                    <td class="text-center">
                      <v-checkbox
                        v-model="answer.is_free_answer"
                        disabled
                        color="primary"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-chip-group>
                        <v-chip
                          @click="$router.push('/details/' + item.uid)"
                          :key="item.uid"
                          v-for="item in answer.chained"
                          label
                          >{{ item.name }}</v-chip
                        >
                      </v-chip-group>
                    </td>
                    <td>
                      <v-chip-group>
                        <v-chip
                          @click="$router.push('/details/' + item.uid)"
                          :key="item.uid"
                          v-for="item in answer.presence"
                          class="white--text"
                          color="primary"
                          >P: {{ item.name }}</v-chip
                        >
                        <v-chip
                          @click="$router.push('/details/' + item.uid)"
                          :key="item.uid"
                          v-for="item in answer.absence"
                          class="white--text"
                          color="#ef8365"
                          >A: {{ item.name }}</v-chip
                        >
                        <v-chip
                          @click="$router.push('/details/' + item.uid)"
                          :key="item.uid"
                          v-for="item in answer.ignorance"
                          class="white--text"
                          color="#a3b1cd"
                          >I: {{ item.name }}</v-chip
                        >
                      </v-chip-group>
                    </td>
                    <td class="text-center">
                      <v-btn
                        :disabled="adding"
                        text
                        icon
                        color="grey"
                        @click="load(answer, index)"
                        ><v-icon>mdi-pen</v-icon></v-btn
                      >
                      <v-btn
                        :disabled="adding"
                        text
                        icon
                        color="error"
                        @click="try_remove(index)"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-dialog v-model="confirmDialog" width="20%">
              <v-card class="primary--text text-center">
                <h3>Supprimer la réponse ?</h3>
                <v-card-actions>
                  <v-col class="text-center">
                    <v-btn
                      @click="confirmDialog = false"
                      text
                      color="blue darken-1"
                    >
                      Non
                    </v-btn>
                    <v-btn
                      text
                      @click="remove()"
                      :loading="removeLoading"
                      color="error"
                    >
                      Oui
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="blue darken-1" @click="localDialog = false">
          Fermer
        </v-btn>
        <v-btn
          v-if="is_scoring && $store.state.scoring_type === 'eva'"
          text
          :disabled="!validEVA"
          :loading="validateLoading"
          @click="validateEVA()"
          color="blue darken-1"
        >
          Enregistrer
        </v-btn>
        <v-btn
          v-else
          text
          :disabled="!validForm"
          :loading="validateLoading"
          @click="validate()"
          color="blue darken-1"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import SubLink from "@/components/Utils/SubLink";
import Prerequisites from "@/components/Utils/Prerequisites";
import QuestionScoring from "@/components/Utils/QuestionScoring";

export default {
  name: "ImpactModal",
  components: { SubLink, Prerequisites, QuestionScoring },
  data: () => ({
    //Base props
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
    validForm: true,
    validateLoading: false,
    required_rule: v => !!v || "Ce champ est requis",
    scoring_rule: v => v >= 0 || "Le score doit être supérieur à 0",
    spe_rule: v =>
      (v && v.length > 0) || "Sélectionnez au moins une spécialité",
    //question
    newName: "",
    specialities: [],
    prerequisites: [],
    is_scoring: false,
    scoring_name: null,
    scoring_type: null,
    ofType: null,
    ofFormat: null,
    specialityList: JSON.parse(localStorage.getItem("medical_classification")),
    questionTypes: JSON.parse(localStorage.getItem("question_choices_2")),
    answerFormat: JSON.parse(localStorage.getItem("display_answers_2")),
    //Answers
    confirmDialog: false,
    to_remove: null,
    removeLoading: false,
    answerName: "",
    scoringValue: null,
    tmpAnswer: null,
    tmpIndex: null,
    tmpUid: null,
    tmpPresence: [],
    tmpAbsence: [],
    tmpIgnorance: [],
    tmpQuestion: [],
    eva_first: null,
    eva_last: null,
    validEVA: false,
    adding: false,
    validAnswer: false,
    is_neither_of_them: false,
    is_free_answer: false,
    answersList: [],
    message: "",
    success: false,
    localDialog: false
  }),
  props: {
    uid: String,
    dialog: Boolean
  },
  watch: {
    dialog(val){
      this.localDialog = val;
    },
    localDialog(val) {
      this.$emit("update:dialog", val);
      if (!val) this.close();
    },
    uid(val) {
      if (val !== "") {
        this.getImpactData(val);
      }
    }
  },
  methods: {
    getImpactData(uid) {
      axios
        .get("/api/questions/" + uid)
        .then(response => {
          let resp = response.data;
          this.newName = resp.name;
          this.specialities = resp.spe;
          this.prerequisites = this.unpackPR(JSON.parse(resp.prerequisites));
          this.is_scoring = resp.is_scoring;
          this.$store.state.scoring_name = resp.scoring_name;
          this.$store.state.scoring_type = resp.scoring_type;
          this.ofType = resp.type;
          this.ofFormat = resp.format;
          if (resp.scoring_type === "eva") {
            this.eva_first = resp.eva_first.name;
            this.eva_last = resp.eva_last.name;
          } else {
            this.answersList = resp.answers;
          }
        })
        .catch(error => {
          this.message = error.response.data.detail;
          if (!this.message)
            this.message = "Une erreur est survenue lors de la recupération des données";
          this.$emit("impact_maj", this.message);
        });
    },
    unpackPR(raw_prerequisites) {
      let prerequisites = raw_prerequisites;
      let preRequisitesData = JSON.parse(
        localStorage.getItem("dict_prerequisites")
      );
      prerequisites.forEach(function(group) {
        group.forEach(function(pre_req) {
          let name = pre_req["name"];
          let prData = preRequisitesData[name];
          pre_req.field_type = "";
          pre_req.conditions = "";
          pre_req.possible_values = [];
          pre_req.limits = {};
          pre_req.conditions = prData.condition;
          pre_req.field_type = prData.field_type[0];
          if (pre_req.field_type === "input") {
            if (prData.value_type[0] === "text") {
              pre_req.field_type = "text";
            } else if (prData.value_type[0] === "number") {
              pre_req.field_type = "number";
              pre_req.limits = {
                max: prData.max[0],
                min: prData.min[0],
                step: prData.step[0]
              };
            }
          } else if (pre_req.field_type === "scoring_select") {
            pre_req.possible_values = this.$store.getters.getScoringNames;
          } else {
            pre_req.possible_values = prData.value_field;
          }
        });
      });
      if (prerequisites.length === 1 && prerequisites[0].length === 0)
        prerequisites.splice(0, 1);
      return prerequisites;
    },
    try_remove(index) {
      this.to_remove = index;
      this.confirmDialog = true;
    },
    remove() {
      this.removeLoading = true;
      let uid = this.answersList[this.to_remove].uid;
      axios
        .delete("/api/answers/" + uid, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.answersList.splice(this.to_remove, 1);
          this.confirmDialog = false;
          this.success = true;
          this.message = "Réponse supprimé avec succès";
        })
        .catch(error => {
          this.success = false;
          this.message = error.response.data.detail;
        })
        .finally(() => {
          this.$emit("impact_maj", {message: this.message, success: this.success});
          this.to_remove = null;
          this.removeLoading = false;
        });
    },
    resetAnswer() {
      this.answerName = "";
      this.is_neither_of_them = false;
      this.is_free_answer = false;
      this.tmpPresence = [];
      this.tmpAbsence = [];
      this.tmpIgnorance = [];
      this.tmpQuestion = [];
    },
    restore() {
      if (this.tmpAnswer) {
        this.answersList.splice(this.tmpIndex, 0, this.tmpAnswer);
        this.tmpAnswer = null;
        this.tmpIndex = null;
      }
      this.resetAnswer();
      this.adding = false;
    },
    load(answer, index) {
      if (answer.scoring) this.scoringValue = answer.scoring;
      this.tmpUid = answer.uid;
      this.answerName = answer.name;
      this.is_neither_of_them = answer.is_neither_of_them;
      this.is_free_answer = answer.is_free_answer;
      this.tmpPresence = answer.presence;
      this.tmpAbsence = answer.absence;
      this.tmpIgnorance = answer.ignorance;
      this.tmpQuestion = answer.chained;
      this.tmpAnswer = this.answersList[index];
      this.tmpIndex = index;
      this.answersList.splice(index, 1);
      this.adding = true;
    },
    move(array, from, to) {
      array.splice(to, 0, array.splice(from, 1)[0]);
    },
    addAnswer() {
      this.tmpAnswer = null;
      if (this.tmpIndex !== null) {
        this.answersList.splice(this.tmpIndex, 0, {
          name: this.answerName,
          uid: this.tmpUid,
          is_neither_of_them: this.is_neither_of_them,
          is_free_answer: this.is_free_answer,
          scoring: this.scoringValue,
          chained: Array.from(this.tmpQuestion),
          presence: Array.from(this.tmpPresence),
          absence: Array.from(this.tmpAbsence),
          ignorance: Array.from(this.tmpIgnorance)
        });
      } else {
        this.answersList.push({
          name: this.answerName,
          uid: this.tmpUid,
          is_neither_of_them: this.is_neither_of_them,
          is_free_answer: this.is_free_answer,
          scoring: this.scoringValue,
          chained: Array.from(this.tmpQuestion),
          presence: Array.from(this.tmpPresence),
          absence: Array.from(this.tmpAbsence),
          ignorance: Array.from(this.tmpIgnorance)
        });
      }
      this.tmpIndex = null;
      this.$refs.form.reset();
      this.adding = false;
      this.tmpUid = null;
      this.tmpPresence = [];
      this.tmpAbsence = [];
      this.tmpIgnorance = [];
      this.tmpQuestion = [];
    },
    close() {
      if (this.tmpAnswer) this.restore();
      else this.resetAnswer();
      this.adding = false;
    },
    validateEVA() {
      let questionData = {
        name: this.newName,
        medical_classification: this.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        of_type: this.ofType,
        of_format: this.ofFormat,
        is_scoring: this.is_scoring,
        scoring_type: this.$store.state.scoring_type,
        scoring_name: this.$store.state.scoring_name
      };
      let answers = [];
      let answer_data = {
        scoring_name: questionData.scoring_name,
        name_first_answer: this.eva_first,
        name_last_answer: this.eva_last
      };
      answers.push(answer_data);
      questionData.answers = answers;
      this.validateLoading = true;
      axios
        .patch("/api/questions/" + this.uid, questionData, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.message = "Mis à jour avec succès";
          this.success = true;
        })
        .catch(error => {
          this.success = false;
          this.message = error.response.data.detail;
          if (!this.message) {
            this.message = "Une erreur est survenue lors de la modification des données";
          }
        })
        .finally(() => {
          this.validateLoading = false;
          this.close();
          this.$emit("update:dialog", false);
          this.$emit("impact_maj", {message: this.message, success: this.success});
        });
    },
    validate() {
      this.validateLoading = true;
      if (this.is_scoring === null) {
        this.$store.state.scoring_type = null;
        this.$store.state.scoring_name = null;
      }
      let questionData = {
        name: this.newName,
        medical_classification: this.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        of_type: this.ofType,
        of_format: this.ofFormat,
        is_scoring: this.is_scoring,
        scoring_type: this.$store.state.scoring_type,
        scoring_name: this.$store.state.scoring_name
      };
      let answers = [];
      for (let i = 0; i < this.answersList.length; i++) {
        let presence = [];
        let absence = [];
        let ignorance = [];
        let chained = [];
        for (let j = 0; j < this.answersList[i].presence.length; j++) {
          presence.push(this.answersList[i].presence[j].uid);
        }
        for (let j = 0; j < this.answersList[i].absence.length; j++) {
          absence.push(this.answersList[i].absence[j].uid);
        }
        for (let j = 0; j < this.answersList[i].ignorance.length; j++) {
          ignorance.push(this.answersList[i].ignorance[j].uid);
        }
        for (let j = 0; j < this.answersList[i].chained.length; j++) {
          chained.push(this.answersList[i].chained[j].uid);
        }
        let answer_data = {
          question_uid: "",
          name: this.answersList[i].name,
          uid: this.answersList[i].uid,
          ids_presence: presence,
          ids_absence: absence,
          ids_ignorance: ignorance,
          chained: chained
        };
        if (questionData.is_scoring) {
          answer_data.scoring = this.answersList[i].scoring;
          answer_data.scoring_name = questionData.scoring_name;
        } else {
          answer_data.is_none_of_the_above_answer = this.answersList[
            i
          ].is_neither_of_them;
          answer_data.is_input = this.answersList[i].is_free_answer;
        }
        answers.push(answer_data);
      }
      questionData.answers = answers;
      axios
        .patch("/api/questions/" + this.uid, questionData, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.success = true;
          this.message = "Mis à jour avec succès";
        })
        .catch(error => {
          this.success = false;
          this.message = error.response.data.detail;
          if (!this.message) {
            this.message = "Une erreur est survenue lors de la modification des données";
          }

        })
        .finally(() => {
          this.validateLoading = false;
          this.close();
          this.$emit("update:dialog", false);
          this.$emit("impact_maj", {message: this.message, success: this.success});
        });
    }
  }
};
</script>
