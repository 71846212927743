<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="6">
        <v-btn elevation="0" color="primary" @click="triggerPreReq = !triggerPreReq">
          <v-icon left v-if="triggerPreReq">mdi-chevron-down</v-icon>
          <v-icon left v-else>mdi-chevron-up</v-icon>
          Prérequis
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-end pr-0">
         <v-btn v-if="triggerPreReq" text color="primary" @click="addPreReqGrp">
          Ajouter
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="triggerPreReq" class="pt-0">
        <v-card :key="index_gr" v-for="(group, index_gr) in prerequisites" outlined>
          <v-card-text class="pb-0">
            <v-row :key="index_pr" v-for="(pre_req, index_pr) in group">
              <v-col cols="4" class="py-1">
                <v-select outlined dense v-model="pre_req.name"
                          label="Type"
                          :items="Object.entries(preRequisitesData)"
                          :item-text="item => item[1]['display_name']"
                          :item-value="item => item[0]"
                          :rules="[required_rule]"
                          @change="updatePRArrays(pre_req)">
                </v-select>
              </v-col>
              <v-col cols="3" class="py-0" v-if="pre_req.conditions.length > 0">
                <v-select outlined dense
                          v-model="pre_req.type"
                          :items="pre_req.conditions"
                          :rules="[required_rule]"
                          label="Conditions">
                </v-select>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-select v-if="pre_req.field_type === 'select'" outlined dense v-model="pre_req.value" label="Sexe"
                          :items="pre_req.possible_values"
                          :rules="[required_rule]">
                </v-select>
                <v-autocomplete v-if="pre_req.field_type === 'scoring_select'" outlined dense v-model="pre_req.value" label="Nom du Scoring"
                          :items="pre_req.possible_values"
                          :rules="[required_rule]">
                </v-autocomplete>
                <v-text-field v-else-if="pre_req.field_type === 'text'" outlined dense label="Code"
                              v-model="pre_req.value"
                              :rules="[required_rule]">
                </v-text-field>
                <v-text-field v-else-if="pre_req.field_type === 'number'" outlined dense type="number" label="Valeur"
                              :min="pre_req.limits.min" :max="pre_req.limits.max" :step="pre_req.limits.step"
                              v-model="pre_req.value"
                              :rules="[required_rule, number_rule(pre_req, pre_req.value)]">
                </v-text-field>
              </v-col>
              <v-col cols="2" class="text-end py-0">
                <v-btn text color="primary" fab x-small dark @click="addPreReq(group)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn text color="error" fab x-small dark @click="removePreReq(index_pr, index_gr, group)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="error" x-small dark @click="removePreReqGrp(index_gr)">
              Supprimer
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Prerequisites",
  props: {
    validForm: Boolean,
    prerequisites: Array
  },
  data: () => ({
    triggerPreReq: true,
    preRequisitesData: JSON.parse(localStorage.getItem("dict_prerequisites")),
    required_rule: v => !!v || "Ce champ est requis"
  }),
  methods: {
    number_rule(pre_req, value) {
      if (parseInt(value) > parseInt(pre_req.limits.max)) {
        this.$emit("update:validForm", false);
        return "La valeur doit être inférieure à " + pre_req.limits.max;
      } else if (parseInt(value) < parseInt(pre_req.limits.min)) {
        this.$emit("update:validForm", false);
        return "La valeur doit être supérieure à " + pre_req.limits.min;
      } else {
        return true;
      }
    },
    updatePRArrays(pre_req) {
      let name = pre_req["name"];
      pre_req.type = "";
      pre_req.value = "";
      let prData = this.preRequisitesData[name];
      pre_req.conditions = prData.condition;
      pre_req.field_type = prData.field_type[0];
      if (pre_req.field_type === "input") {
        if (prData.value_type[0] === "text") {
          pre_req.field_type = "text";
        } else if (prData.value_type[0] === "number") {
          pre_req.field_type = "number";
          pre_req.limits = {
            max: prData.max[0],
            min: prData.min[0],
            step: prData.step[0]
          };
        }
      } else if (pre_req.field_type === "scoring_select") {
        pre_req.possible_values = this.$store.getters.getScoringNames;
      } else {
        pre_req.possible_values = prData.value_field;
      }
    },
    addPreReq(group) {
      group.push({
        name: "",
        type: "",
        value: "",
        field_type: "",
        conditions: "",
        possible_values: [],
        limits: {}
      });
      this.$emit("update:prerequisites", this.prerequisites);
    },
    removePreReq(index_pr, index_gr, group) {
      group.splice(index_pr, 1);
      if (group.length === 0) {
        this.removePreReqGrp(index_gr);
      }
      this.$emit("update:prerequisites", this.prerequisites);
    },
    addPreReqGrp() {
      this.prerequisites.push([]);
      const tmp = this.prerequisites.pop();
      this.addPreReq(tmp);
      this.prerequisites.push(tmp);
      this.$emit("update:prerequisites", this.prerequisites);
    },
    removePreReqGrp(index) {
      this.prerequisites.splice(index, 1);
      this.$emit("update:prerequisites", this.prerequisites);
    }
  }
};
</script>
