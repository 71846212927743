<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer un symptome
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm" autocomplete="off">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'Symptom'"
              :linkNode="true"
              @newName="getName"
            />
            <v-col cols="12" fluid>
              <v-text-field
                v-model="patientName"
                label="Nom patient du symptôme"
                hint="Nom visible par le patient (ne pas remplir si c'est le meme que le nom du noeud)"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="6" fluid>
              <v-select
                label="Tag du symptôme *"
                v-model="symptomTag"
                :items="possibleTags"
                :item-text="item => Object.values(item)[0]"
                :item-value="item => Object.keys(item)[0]"
                color="primary"
                item-color="primary"
                :rules="[required_rule]"
              ></v-select>
            </v-col>
            <v-col cols="6" fluid>
              <v-text-field
                v-model="dangerosity"
                label="Dangerosité *"
                type="number"
                min="0"
                max="100"
                :rules="[dangerosity_rule]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="is_visible"
                label="Visible par le patient"
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="is_absence_of"
                label="Absence de ..."
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="8" fluid>
                  <v-text-field
                    :counter="100"
                    :rules="[synRules]"
                    label="Synonyme"
                    v-model="newSynonym"
                    hint="Ajouter un synonyme au symptôme"
                    persistent-hint
                    @keydown.enter="addSynonym"
                  >
                  </v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" style="align-self: center">
                  <v-btn
                    :disabled="
                      !newSynonym ||
                        synonyms.includes(newSynonym) ||
                        newSynonym.length > 100
                    "
                    color="primary"
                    @click="addSynonym"
                  >
                    Ajouter
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span><b>Liste actuelle des synonymes : </b></span>
                  <p v-if="synonyms.length == 0" label class="my-2">
                    Pas de synonymes pour l'instant
                  </p>
                  <v-chip-group v-else column active-class="primary--text">
                    <v-chip
                      :key="item"
                      v-for="item in synonyms"
                      close
                      label
                      @click:close="removeSynonym(item)"
                    >
                      {{ item }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
						class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
  name: "CreateSymptomModal",
  components: {
    CreateModalCore
  },
  data: () => ({
    newSynonym: "",
    synonyms: [],
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    name: null,
    required_rule: v => !!v || "Ce champ est requis",
    //Symptom
    patientName: null,
    symptomTag: null,
    possibleTags: JSON.parse(localStorage.getItem("symptom_tags")),
    dangerosity: 0,
    is_visible: true,
    is_absence_of: false,
    dangerosity_rule: v =>
      /^([0-9]|[1-9][0-9]|100)$/.test(v) || "Dangerosité comprise etre 0 et 100"
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  methods: {
    getName (value) {
      this.name = value;
    },
    addSynonym() {
      if (
        this.newSynonym === "" ||
        this.synonyms.includes(this.newSynonym) ||
        this.newSynonym.length > 100
      ) {
        return;
      }
      this.synonyms.push(this.newSynonym);
      this.newSynonym = "";
    },
    removeSynonym(item) {
      const index = this.synonyms.indexOf(item);
      if (index > -1) {
        this.synonyms.splice(index, 1);
      }
    },
    synRules(value) {
      if (value && this.newSynonym.length > 100) {
        return "Max 60 caractères";
      } else if (value && this.synonyms.includes(this.newSynonym)) {
        return this.newSynonym + " est deja dans la liste";
      } else return true;
    },
    close() {
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.patientName = null;
      this.name = null;
      this.symptomTag = null;
      this.dangerosity = null;
      this.is_visible = true;
      this.is_absence_of = false;
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let data = {
        name: this.name,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes,
        tags_symptom: this.symptomTag,
        patient_name_fr: this.patientName,
        visible_by_patient: this.is_visible,
        is_absence: this.is_absence_of,
        dangerosity: this.dangerosity,
        synonyme: this.synonyms
      };
      axios
        .post("/api/symptoms", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    }
  }
};
</script>
