<template>
  <v-app class="primary">
    <v-main>
      <v-container class="text-center">
        <img width="5%" src="@/assets/logo-anamnese-blanc.png"/>
      </v-container>
      <AnResetPassword redirection="/"/>
    </v-main>
  </v-app>
</template>

<script>
import AnResetPassword from "anam-library/src/components/an-auth/AnResetPassword";

export default {
  name: "ResetPasswordView",
  components: {
    AnResetPassword
  }
}
</script>

<style scoped>

</style>