import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import auth from "anam-library/src/store/modules/auth";
import error from "anam-library/src/store/modules/snackbar_and_errors"

import router from "@/router";

Vue.use(Vuex)

const paths = [
    'auth',
    'keycloak',
    'scoring_names',
];

export default new Vuex.Store({
    plugins: [createPersistedState({paths})], 
    state: {
        // CreateModalCore
        newName: "",
        specialities: [],
        description: "",
        meta_questionnaire_name: "",
        //Scoring
        scoring_names: [],
        scoring_type: null,
        scoring_name: null,
        // Questionnaire
        loading_graph: true,
        question_list: [],
        entryPointList: [],
        impactDialog: false,
        impactUid: null,
        // Graph
        nodeDialog: false,
        nodeLabels: [],
        nodeType: "",
        nodeName: "",
        nodeUID: "",
        nodeCIM: "",
        edgeUID: "",
        sourceUID: "",
        targetUID: "",
        edgeDialog: false,
        edgeType: "",
        edgeTag: "",
        edgeDosage: "",
        edgeReference: "",
        edgeLoading: true,
        sourceData: {},
        targetData: {},
        message: "",
        snackbar: false,
        // Keycloak
        keycloak: {
            url: "https://auth.anamnese.care/",
            redirect_logout: "https://mkg.anamnese.care/connexion",
            realm: "Anamnese",
            clientId: "mkg"
        }
    },
    getters: {
        getEnvKeycloak: (state) => (element) => {
            return state.keycloak[element];
        },
        getScoringNames: function(state) {
            return state.scoring_names;
        }
    },
    mutations: {
        resetState() {},
        setEnvKeycloak: function(state) {
            if (["localhost"].some(substring => window.location.href.includes(substring))){
                state.keycloak.url = "https://auth.preprod.anamnese.care";
                state.keycloak.redirect_logout = "http://mkg.localhost:81";
                state.keycloak.realm = "Anamnese";
                state.keycloak.clientId = "mkg";
            }
            else if (["preprod"].some(substring => window.location.href.includes(substring))) {
                state.keycloak.url = "https://auth.preprod.anamnese.care";
                state.keycloak.redirect_logout = "https://mkg.preprod.anamnese.care";
                state.keycloak.realm = "Anamnese";
                state.keycloak.clientId = "mkg";
            }
            else {
                state.keycloak.url = "https://auth.anamnese.care";
                state.keycloak.redirect_logout = "https://mkg.anamnese.care";
                state.keycloak.realm = "Anamnese";
                state.keycloak.clientId = "mkg";
            }
        },
        setScoringNames: function(state, value) {
            state.scoring_names = value;
        },
    },
    actions: {
        connexion: function(context, {data}) {
          context.commit('ResetSnackBar');
          if (data.role === "USER_IS_MKG_USER") {
              router.push({name: "MKG"})
              context.commit('setUserPermission', 1)
          } else {
              router.push("/403");
          }
        },
    },
    modules: {
        auth: auth,
        errors: error
    }
})
