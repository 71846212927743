<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_light">
        <v-card-title class="text-h4 primary--text">
          Liste de {{ nodeType }}
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <div class="mb-15">{{ nodeList.length }} nœuds</div>
          <v-data-table
            :headers="_headers"
            :items="nodeList"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-options': [300]
            }"
            :items-per-page="300"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="4">
                  <v-text-field class="ma-5" v-model="search" outlined append-icon="mdi-magnify" label="Recherche"></v-text-field>
                </v-col>
              </v-row>
              <v-tabs centered v-model="tab" @change="searchByLetter" class="mb-10" show-arrows>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab v-for="(item,i) in alphabet" :key="i">{{ item }}</v-tab>
              </v-tabs>
            </template>
<!--            actions header-->
            <template v-slot:item.links="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :href="'/graph/' + item.uid">
                    <v-icon>mdi-share-variant-outline</v-icon>
                  </v-btn>
                </template>
                <span>graph</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :href="'/details/' + item.uid">
                    <v-icon>mdi-card-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>details</span>
              </v-tooltip>
            </template>
<!--            name header-->
            <template v-slot:item.name="{ item }">
              <div class="black--text text-sm-body-2" >
                <v-chip :key="label" v-for="label in item.labels.filter(label => nodeTypesDict[label])"
                        small
                        :color="nodeTypesDict[label].color"
                        class="white--text mx-0 pa-1"
                >
                  {{ nodeTypesDict[label].symbol }}
                </v-chip>
                {{ truncate(item.name, 100) }}
              </div>
            </template>
<!--            speciality header-->
            <template v-slot:item.medical_classification="{ item }">
              <v-chip-group column active-class="secondary--text">
                <v-chip
                  :key="spe"
                  v-for="spe in item.medical_classification"
                  small
                >
                  {{ spe }}
                </v-chip>
              </v-chip-group>
            </template>
<!--            uid header-->
            <template v-slot:item.uid="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.uid }}</div>
            </template>
<!--            icd10 header-->
            <template v-slot:item.icd10_id="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.icd10_id? item.icd10_id : '-' }}</div>
            </template>
<!--            drug atc header-->
            <template v-slot:item.atc_code="{ item }" v-if="nodeType == 'Drug'">
              <div class="black--text text-sm-body-2" >{{ item.atc_code }}</div>
            </template>
<!--            drug commercialised header-->
            <template v-slot:item.commercialised="{ item }" v-if="nodeType == 'Drug'">
              <div class="black--text text-sm-body-2" >{{ item.commercialised ? 'Oui' : 'Non' }}</div>
            </template>
<!--            relations header-->
            <template v-slot:item.relations="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.relations }}</div>
            </template>
<!--            updated at-->
            <template v-slot:item.updated_at="{ item }">
              <div class="black--text text-sm-body-2" >{{ date_format(item.updated_at)}}</div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "DisplayNodes",

  data: () => ({
    nodeType: "",
    tab: "0",
    search: "",
    loading: false,
    nodeList: [],
    alphabet: [
      "All",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    ],
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
    headers: [
      { text: 'Raccourcis', value: 'links', class: 'primary--text', sortable: false},
      { text: "Nom du nœud", value: "name", class: 'primary--text', align: 'start'},
      { text: "Spécialité", value: "medical_classification", class: 'primary--text'},
      { text: "Uid", value: "uid", class: 'primary--text'},
      { text: "CIM-10", value: "icd10_id", class: 'primary--text'},
      { text: "Code ATC", value: "atc_code", class: 'primary--text'},
      { text: "Commerialisé", value: "commercialised", class: 'primary--text'},
      { text: "Liens", value: "relations", class: 'primary--text'},
      { text: "MAJ", value: "updated_at", class: 'primary--text'},
    ]
  }),
  computed : {
    _headers () {
      // filter out the CIM-10 from the Drug list
      return this.headers.filter(x =>
          (this.nodeType == 'Drug') ? x.value != 'icd10_id': x &&
          (this.nodeType != 'Drug') ? (!['atc_code', 'commercialised'].includes(x.value)) : x
      )
    }
  },
  methods: {
    truncate(str, n){
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    },
    date_format(date) {
      return moment(date, "YYYYMMDDHHmm").format("YYYY-MM-DD h:mma");
    },
    searchByLetter(tab) {
      this.loading = true;
      this.nodeType = this.$route.params.type;
      axios
        .get("/api/mkg/node_filtered_with_letter", {
          params: {
            letter: this.alphabet[tab].toLowerCase(),
            label: this.nodeType
          }
        })
        .then(response => {
          this.nodeList = response.data.node_list;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
