<template>
  <v-card>
    <v-card-title
      class="justify-center text-center align-center primary--text text-h6 font-weight-bold"
    >
      {{ title }}
    </v-card-title>
    <v-card-actions>
      <v-btn
        color="secondary"
        text
        @click="show = !show"
      >
        Élargir
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
      <v-btn
        v-if="title == 'Disease'"
        color="blue lighten-1"
        text
        @click="download"
      >
        Statistiques
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-list v-if="loading">
            <v-list-item style="justify-content: center">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-list-item>
          </v-list>
          <v-list dense v-else>
            <v-list-item-group>
              <v-list-item
                v-for="item in field"
                :key="item.sentence"
                :href="item.redirect"
                :disabled="!item.redirect || item.redirect === 'maintenance' || item.redirect === '/knowledge_database/maintenance'"
              >
                <v-list-item-icon>
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title v-text="item.sentence"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.sentence }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "NodeRow",
  props: {
    title: String,
    field: Object,
    loading: Boolean,
    download: Function,
  },
  data: () => ({
    show: false,
  }),

};
</script>
