<template>
	<v-container style="max-width: 100%;">
		<v-card v-if="description" elevation="0" color="#BDD5EA" tile class="pa-5">
			<b>{{ description }}</b>
		</v-card>
		<v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="paragraph@30"></v-skeleton-loader>
      </v-card-text>
    </v-card>
		<v-row v-else fill-height fluid>
			<v-col cols="12">
				<v-card elevation="0">
					<v-card-title class="pa-0">
						<v-toolbar dark color="primary">
							<v-toolbar-title style="white-space: normal;"> {{ title }}
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<span> {{ itemList.data.length }} noeuds </span>
						</v-toolbar>
					</v-card-title>
					<v-card-text class="mt-3">
						<div v-if="itemList.data.length === 0" style="text-align: center;">
							<b>Cette list est vide </b>
						</div>
						<template v-else>
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left"> Noeud parent</th>
											<th class="text-left"> Noeud Enfant</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(item,index) in itemList.data">
											<td>
												<a @click="$router.push('/details/' + item['source'].uid)">
													{{ item.source.name }}
												</a>
											</td>
											<td>
												<a @click="$router.push('/details/' + item['source'].uid)">
													{{ item.target.name}}
												</a>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</template>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";

export default {
	name: "TableList",
  data: () => ({
    loading: true,
		title: "",
		description: "",
		itemList: [],
		infoList: [],
  }),
  created: function() {
    axios
      .get("/api/mkg/" + this.$route.params.name, {})
      .then(response => {
        this.title = response.data.list_title;
        this.type = response.data.type;
        this.description = response.data.description;
        this.itemList = response.data.item_list;
        this.infoList = response.data.info_list;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>
