<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
				<v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
            <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
            Informations sur la réponse
        </v-card-title>
        <v-card-text >
          <v-skeleton-loader v-if="!launched" class="mx-10" type="article, actions, image, table-tfoot"></v-skeleton-loader>
          <v-row v-else class="primary--text">
            <v-col cols="12"><span class="primary--text">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end align-self-center"><b>UID</b></v-col>
            <v-col cols="10" class="text-start align-self-center">{{uid}}</v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Nom</b></v-col>
            <v-col cols="7">
              <v-text-field dense outlined hide-details
                            v-model="nodeName"
                            @change="update_field('name', nodeName, route, fieldsDict['name'])">
              </v-text-field>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-btn color="primary" @click="$router.push('/translate/'+uid)">Traduction</v-btn>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Spécialité(s)</b></v-col>
            <v-col cols="10">
              <v-autocomplete outlined dense hide-details placeholder="Aucune séléctionné" multiple
                              v-model="nodeSpe"
                              :items="speciality_list" :item-text="item => item[1]" :item-value="item => item[0]"
                              @change="update_field('medical_classification', nodeSpe, route, fieldsDict['medical_classification'])">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>Pas de resultat pour cette recherche</v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>URL photo/vidéo</b></v-col>
            <v-col cols="10" class="align-self-center">
              <v-text-field dense outlined v-model="media_link" label="URL photo/vidéo" hide-details
                            @change="update_field('media_link', media_link, route, fieldsDict['media_link'])">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0">
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
            <v-skeleton-loader v-if="!launched" class="pa-5" type="article, table-tfoot"></v-skeleton-loader>
            <v-card-text v-else>
              <v-row no-gutters>
                <Prerequisites :prerequisites.sync="prerequisites" />
                <v-col cols="12" class="text-center mt-4">
                  <v-btn :outlined="!prChanged" class="py-3" color="primary" @click="save_pr(prerequisites, route); prChanged = false;">
                    Enregistrer
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- Actions -->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'Answer'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";

export default {
  name: "AnswerDetails",
  components: {
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/answers/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeName: "",
    nodeSpe: [],
    prerequisites: [],
    prChanged: false,
    updated_by: "",
    updated_at: "",
    media_link: null,
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités"
    }
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    }
  },
  mounted: function() {
    this.refreshData();
  },
  methods: {
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "Answer"
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeName = nodeData.name_fr.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.media_link = nodeData.media_link.value;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    }
  }
};
</script>