<template>
  <v-card elevation="2" class="ml-4 mr-4 mt-4 mb-0">
    <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
      <v-btn icon @click="refreshDexp()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
        Disease Expression
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="dexp_Tab" color="primary" white fixed-tabs>
        <v-tab :key="index" v-for="(level, index) in dexp_data">
          {{ level.score }} - {{ dexp[level.score - 1].text }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="dexp_Tab">
        <v-tab-item class="pa-5" :key="index" v-for="(level,index) in dexp_data">
          <v-card flat>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-select
                    dense
                    outlined
                    hide-details
                    v-model="filter"
                    :items="dexpFilter"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-list-item>
                <v-list-item
                  v-if="!Object.keys(filteredList(level.group)).length"
                  class="text-center"
                >
                  Aucun noeud dans ce groupe avec ce filtre
                </v-list-item>
                <v-list-item
                  :key="uid"
                  v-for="(node, uid) in filteredList(level.group)"
                >
                  <v-list-item-avatar
                    :key="label"
                    v-for="label in node.labels.filter(
                      label => nodeTypesDict[label]
                    )"
                    size="26"
                    :color="nodeTypesDict[label].color"
                    class="white--text mx-0"
                  >
                    {{ nodeTypesDict[label].symbol }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="node.name"
                      class="ml-2"
                      style="white-space: normal; word-break: break-word;"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <span class="font-weight-bold black--text mr-1">Origine:</span>
                  <span v-if="node.origin.name" class="black--text mr-8">{{ node.origin.name }}</span>
                  <span v-else class="black--text mr-8">indéfinie</span>
                  <v-list-item-action>
                    <v-btn
                      link
                      text
                      color="primary"
                      :href="'/details/' + uid"
                    >
                      Détails
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Déplacer
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          :key="index"
                          v-for="(level, index) in dexp_data"
                          @click="move(uid, node, dexp_Tab, index)"
                        >
                          <v-list-item-title>
                            {{ level.score }} - {{ dexp[level.score - 1].text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
  name: "DiseaseDetails",
  props: {
    uid: String,
    route: String,
    dexp_data: Array,
    nodeTypesDict: Object,
    update_field: Function,
    toggle_snack: Function,
    refresh_data: Function
  },
  data: () => ({
    dexp_Tab: 0,
    dexp: [
      {text: "Peu discriminant", value: 1},
      {text: "Assez discriminant", value: 2},
      {text: "Très discriminant", value: 3}
    ],
    filter: 1,
    dexpFilter: [
      { text: "Tous", value: 1 },
      { text: "Hérité(s)", value: 2 },
      { text: "Propre(s)", value: 3 }
    ]
  }),
  mounted: function () {
    this.refreshDexp();
  },
  methods: {
    refreshDexp() {
      this.toggle_snack(
        "primary",
        "Mis à jour de la Disease Expression",
        "",
        true,
        0
      );
      let updateDataDict = {};
      updateDataDict["disease_expression"] = "refresh";
      axios
        .patch(this.route + this.uid, updateDataDict, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.toggle_snack(
            "success",
            "Disease Expression mis à jour",
            "mdi-check",
            false,
            1000
          );
        })
        .catch(error => {
          let message = error.response.data.detail;
          if (!message) message = "Erreur lors la mise à jour";
          this.toggle_snack("error", message, "mdi-close", false, 3000);
        })
        .finally(() => {
          setTimeout(() => {
            this.refresh_data();
          }, 2000);
        });
    },
    saveDiseaseExp() {
      this.toggle_snack(
        "primary",
        "Mise à jour de la Disease Expression",
        "",
        true,
        0
      );
      let updateDataDict = {};
      updateDataDict["disease_expression"] = this.dexp_data;
      axios
        .patch(this.route + this.uid, updateDataDict, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .catch(error => {
          let message = error.response.data.detail;
          if (!message) message = "Erreur lors la mise à jour";
          this.toggle_snack("error", message, "mdi-close", false, 3000);
        })
        .finally(() => {
          setTimeout(() => {
            this.refresh_data();
          }, 4000);
        });
    },
    filteredList(data) {
      let filtered = {};
      Object.entries(data).forEach(element => {
        if (
          (this.filter === 2 && element[1].origin.uid !== this.uid) ||
          (this.filter === 3 && element[1].origin.uid === this.uid) ||
          this.filter === 1
        )
          filtered[element[0]] = element[1];
      });
      return filtered;
    },
    move(uid, data, _from, _to) {
      if (this.dexp_Tab != _to) {
        this.dexp_data[_to].group[uid] = data;
        delete this.dexp_data[_from].group[uid];
        this.saveDiseaseExp();
      }
    },
  }
};
</script>
