<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer un examen complémentaire
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm" autocomplete="off">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'MedicalExam'"
              :linkNode="true"
            />
            <v-col cols="12">
              <v-select
                v-model="examType"
                label="Type d'examen *"
                :rules="[required_rule]"
                :items="tagChoices"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
              ></v-select>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-textarea
                outlined
                auto-grow
                clearable
                clear-icon="mdi-close"
                v-model="contraindication"
                label="Contre-Indication (Optionnel)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
            class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
  name: "CreateMedExamModal",
  components: {
    CreateModalCore
  },
  data: () => ({
    examType: "",
    contraindication: "",
    tagChoices: JSON.parse(localStorage.getItem("medical_exam_tag")),
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    required_rule: v => !!v || "Ce champ est requis",
    len_rule: v => (v && v.length <= 100) || "Max 100 caractères"
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.examType = "";
      this.contraindication = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let data = {
        type_of_exam: this.examType,
        contraindication: this.contraindication,
        name: this.$store.state.newName,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes
      };
      axios
        .post("/api/medical-exams", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    }
  }
};
</script>
