<template>
  <v-card elevation="2" class="ma-4">
    <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
      <v-btn icon @click="refresh_data()">
        <v-icon color="primary">mdi-refresh</v-icon>
      </v-btn>
      Relations du noeud
    </v-card-title>
    <v-card-text v-if="!launched">
      <v-row class="pa-5">
        <v-col cols="8">
          <v-card elevation="10" class="pa-5">
            <v-skeleton-loader
                type="list-item-avatar, article, image"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card elevation="10" class="pa-5">
            <v-skeleton-loader
                type="list-item-avatar, divider, article, table-tfoot"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else-if="_rel_data">
      <v-tabs v-model="actionTab" color="primary" white fixed-tabs>
        <v-tab :key="label" v-for="label in Object.keys(_rel_data)">
          {{ label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="actionTab">
        <v-tab-item :key="label" v-for="label in Object.keys(_rel_data)">
          <v-row>
            <v-col :cols="label === 'Answer' ? 12 : 8" class="pa-5">
              <v-card
                  :key="rel"
                  v-for="rel in Object.keys(_rel_data[label])"
                  elevation="2"
                  class="mb-5"
              >
                <v-card-text>
                  <v-list>
                    <p class="text-center primary--text pt-3">
                      <span style="font-size: medium;"
                      >Lien(s) du type <b>{{ rel }}</b></span
                      >
                    </p>
                    <v-divider class="mx-12 my-0"></v-divider>
                    <p
                        v-if="_rel_data[label][rel].nodes_related.length === 0"
                        class="text-center primary--text pt-5"
                    >
                      <span style="font-size: medium;"
                      ><i>Aucun noeud lié</i></span
                      >
                    </p>
                    <v-list-item
                        v-else
                        :key="index"
                        v-for="(node, index) in _rel_data[label][rel]
                        .nodes_related"
                    >
                      <v-list-item-avatar
                          size="26"
                          :color="
                          nodeTypesDict[node.class_name]
                            ? nodeTypesDict[node.class_name].color
                            : 'black'
                        "
                          class="white--text"
                      >
                        {{
                          nodeTypesDict[node.class_name]
                              ? nodeTypesDict[node.class_name].symbol
                              : node.class_name[0]
                        }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                            v-text="node.name"
                            style="white-space: normal; word-break: break-word;"
                        ></v-list-item-title>
                      <v-list-item-subtitle v-if="node.extra.dosage">
                        <v-btn
                            x-small
                            text
                            class="pa-0 ma-0"
                            color="grey darken-1"
                            @click="editDosage(node.extra)"
                        >
                          {{ get_full_dosage(node.extra.dosage, node.extra.reference) }}
                        </v-btn>
                      </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action
                          v-if="
                          node_label === 'MasterQuestion' &&
                            label === 'Question' &&
                            index > 0
                        "
                      >
                        <v-btn
                            icon
                            x-small
                            color="primary"
                            @click="
                            change_order(
                              rel,
                              index,
                              -1,
                              _rel_data[label][rel].nodes_related
                            )
                          "
                        >
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action
                          v-if="
                          node_label === 'MasterQuestion' &&
                            label === 'Question' &&
                            index <
                              _rel_data[label][rel].nodes_related.length - 1
                        "
                      >
                        <v-btn
                            icon
                            x-small
                            color="primary"
                            @click="
                            change_order(
                              rel,
                              index,
                              1,
                              _rel_data[label][rel].nodes_related
                            )
                          "
                        >
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action v-if="node.extra.tag">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    text
                                    color="blue-grey"
                                    v-bind="attrs"
                                    v-on="{ ...tooltip, ...menu }"
                                >Tag
                                </v-btn
                                >
                              </template>
                              <span>{{
                                  _rel_data[label][rel].relation_model.tag.choices[node.extra.tag]
                                }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                                :key="tag[1]"
                                v-for="tag in get_entries(label, rel)"
                                @click="changeTag(node.extra.uid, tag)"
                            >
                              <v-list-item-title>{{
                                  tag[1]
                                }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                            text
                            color="error"
                            @click="deleteEdge(node.link_uid)"
                            :disabled="refreshing"
                        >
                          Délier
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                            link
                            text
                            color="primary"
                            :href="'/details/' + node.uid"
                        >
                          Détails
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="label !== 'Answer'" cols="4" class="pa-5">
              <DetailsAddLink
                  :uid="uid"
                  :label="label"
                  :rel_label_data="_rel_data[label]"
                  :nodeTypesDict="nodeTypesDict"
                  :refresh_data="refresh_data"
                  :toggle_snack="toggle_snack"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-dialog v-model="dialog_dosage" max-width="60%">
      <v-card>
        <v-card-title>
          <span class="primary--text text-h5 font-weight-bold mb-4">Modifier Dosage</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4" class="align-self-center">
              <span class="font-weight-bold primary--text">Dosage</span>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                v-model="rel_dosage"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="align-self-center">
              <span class="font-weight-bold primary--text">Référence</span>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                v-model="rel_reference"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDosage">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveDosage">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import {getCookie} from "@/data/utils.js";
import DetailsAddLink from "@/components/Details/DetailsAddLink";

export default {
  name: "RelationshipActions",
  components: {DetailsAddLink},
  props: {
    uid: String,
    launched: Boolean,
    node_label: String,
    rel_data: null,
    nodeTypesDict: null,
    refresh_data: Function,
    toggle_snack: Function
  },

  computed: {
    _rel_data() {
      if (this.rel_data) {
        let rel_data = this.rel_data;
        delete rel_data['Indication']
        return rel_data;
      }
      return {};
    }
  },
  data: () => ({
    actionTab: null,
    refreshing: false,
    rel_uid: null,
    rel_dosage: null,
    rel_reference: null,
    dialog_dosage: false,
  }),
  methods: {
    get_full_dosage(dosage, reference) {
      return reference ? `${dosage} par ${reference}`: dosage;
    },
    get_entries(label, rel) {
      let choices = this._rel_data[label][rel].relation_model.tag.choices;
      if (choices) return Object.entries(choices);
      return [];
    },
    editDosage(data) {
      this.dialog_dosage = true;
      this.rel_dosage = data.dosage;
      this.rel_reference = data.reference;
      this.rel_uid = data.uid;
    },
    changeTag(link_id, tag) {
      this.toggle_snack("primary", "Mis à jour du tag", "", true, 0);
      let updateDataDict = {};
      updateDataDict["tag"] = tag[0];
      axios
          .patch("/api/mkg/edge/" + link_id, updateDataDict, {
            headers: {"X-CSRFToken": getCookie("csrftoken")}
          })
          .then(() => {
            this.toggle_snack(
                "success",
                "Tag mis à jour avec succès",
                "mdi-check",
                false,
                2000
            );
          })
          .catch(error => {
            this.toggle_snack(
                "error",
                error.response.data.detail,
                "mdi-close",
                false,
                5000
            );
          })
          .finally(() => {
            setTimeout(() => {
              this.refresh_data();
            }, 2000);
          });
    },
    change_order(type, changed_index, direction, curr_order) {
      this.toggle_snack("primary", "MàJ de l'ordre", "", true, 0);
      curr_order.splice(
          changed_index + direction,
          0,
          curr_order.splice(changed_index, 1)[0]
      );
      let dict_order = {};
      curr_order.forEach((element, index) => {
        dict_order[element.link_uid] = index;
      });
      let updateDataDict = {};
      updateDataDict["ranking"] = type;
      updateDataDict["dict_order"] = dict_order;
      axios
          .patch("/api/master-questions/" + this.uid, updateDataDict, {
            headers: {"X-CSRFToken": getCookie("csrftoken")}
          })
          .then(() => {
            this.toggle_snack(
                "success",
                "Mis à jour avec succès",
                "mdi-check",
                false,
                1000
            );
          })
          .catch(error => {
            this.toggle_snack(
                "error",
                "Erreur lors la mise à jour de l'ordre",
                "mdi-close",
                false,
                3000
            );
          })
          .finally(() => {
            setTimeout(() => {
              this.refresh_data();
            }, 2000);
          });
    },
    closeDosage() {
      this.dialog_dosage = false;
      this.rel_dosage = null;
      this.rel_reference = null;
      this.rel_uid = null;
    },
    saveDosage() {
      let data = {
        dosage: this.rel_dosage,
        reference: this.rel_reference,
      };
      axios
        .patch("/api/mkg/edge/" + this.rel_uid, data, {
          headers: {"X-CSRFToken": getCookie("csrftoken")}
        })
        .then(() => {
          this.toggle_snack(
              "success",
              "Dosage modifié avec succès",
              "mdi-check",
              false,
              2000
          );
        })
        .catch(error => {
          this.toggle_snack(
              "error",
              error.response.data.detail,
              "mdi-close",
              false,
              5000
          );
        })
        .finally(() => {
          this.closeDosage();
          setTimeout(() => {
            this.refresh_data();
          }, 2000);
        });
    },
    deleteEdge(uid) {
      this.refreshing = true;
      this.toggle_snack("primary", "Supression du lien", "", true, 0);
      axios
          .delete("/api/mkg/edge/" + uid, {
            headers: {"X-CSRFToken": getCookie("csrftoken")}
          })
          .then(() => {
            this.toggle_snack(
                "success",
                "Lien supprimé avec succès",
                "mdi-check",
                false,
                2000
            );
          })
          .catch(error => {
            this.toggle_snack(
                "error",
                "Un problème est survenu lors de la suppression du lien",
                "mdi-close",
                false,
                4000
            );
          })
          .finally(() => {
            setTimeout(() => {
              this.refresh_data();
              this.refreshing = false;
            }, 2000);
          });
    }
  }
};
</script>
