<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="6" offset-xl="0">
      <v-card elevation="3" class="mr-4 ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          Prediction de la durée de séjour en Urgences
        </v-card-title>
        <v-card-title class="secondary--text">Paramètres du modèle</v-card-title>
        <v-card-text>
          <v-form v-model="isFormValid">
            <v-row>
              <v-col cols="6">
                <v-select
                    v-model="day"
                    :items="days"
                    label="Jour"
                    outlined dense
                    :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="transportation"
                    :items="transportation_means"
                    label="Transport"
                    outlined dense
                    :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="stay_reason"
                    :items="icd10_chapters"
                    label="Motif de recours"
                    :item-text="(item) => (item.code + ' : ' + item.title)"
                    item-value="code"
                    outlined dense clearable
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="age"
                    type="number"
                    label="Age"
                    outlined dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="entry_time"
                    type="number"
                    label="Horaire d'entrée"
                    outlined dense
                    :rules="[rules.required, rules.time_min, rules.time_max]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="number_of_acts"
                    type="number"
                    label="Nombre d'actes"
                    outlined dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="ccmu"
                    :items="ccmu_choices"
                    label="CCMU"
                    outlined dense
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-switch v-model="is_hospitalized" label="Hospitalisé"></v-switch>
              </v-col>
              <v-col cols="1" offset="5">
                <v-switch v-model="radiology" label="Radiographie"></v-switch>
              </v-col>
              <v-col cols="1" offset="6">
                <v-switch v-model="echography" label="Echographie"></v-switch>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                    color="primary"
                    @click="predict_emergency_los"
                    :loading="loading"
                    :disabled="!isFormValid"
                >
                  Lancer
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title class="secondary--text">Résultat</v-card-title>
        <v-card-text>
          <span class="text-h6 primary--text" v-if="result !== null">{{ result }}</span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import getCookie from "anam-library/src/js/common";

export default {
  name: "DemoMachineLearning",
  data: () => ({
    isFormValid: false,
    loading: false,
    rules: {
      required: v => !!v || "This field is required",
      time_min: v => v >= 0 || "This field cannot be lower than 0",
      time_max: v => v<= 24 || "This field cannot be higher than 23",
    },
    days: ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    transportation_means: ["AMBU", "PERSO", "FO", "VSAB", "SMUR"],
    icd10_chapters: [
      {code: "A00-B99", title: "Certaines maladies infectieuses et parasitaires"},
      // {code: "C00-D48", title: "Tumeurs"},
      {code: "D50-D89", title: "Maladies du sang et des organes hématopoïétiques et certains troubles du système immunitaire"},
      {code: "E00-E90", title: "Maladies endocriniennes, nutritionnelles et métaboliques"},
      {code: "F00-F99", title: "Troubles mentaux et du comportement"},
      {code: "G00-G99", title: "Maladies du système nerveux"},
      {code: "H00-H59", title: "Maladies de l'œil et de ses annexes"},
      {code: "H60-H95", title: "Maladies de l'oreille et de l'apophyse mastoïde"},
      {code: "I00-I99", title: "Maladies de l'appareil circulatoire"},
      {code: "J00-J99", title: "Maladies de l'appareil respiratoire"},
      {code: "K00-K93", title: "Maladies de l'appareil digestif"},
      {code: "L00-L99", title: "Maladies de la peau et du tissu cellulaire sous-cutané"},
      {code: "M00-M99", title: "Maladies du système ostéo-articulaire, des muscles et du tissu conjonctif"},
      {code: "N00-N99", title: "Maladies de l'appareil génito-urinaire"},
      {code: "O00-O99", title: "Grossesse, accouchement et puerpéralité"},
      {code: "P00-P96", title: "Certaines affections dont l'origine se situe dans la période périnatale"},
      // {code: "Q00-Q99", title: "Malformations congénitales et anomalies chromosomiques"},
      {code: "R00-R99", title: "Symptômes, signes et résultats anormaux d'examens cliniques et de laboratoire, non classés ailleurs"},
      {code: "S00-T98", title: "Lésions traumatiques, empoisonnements et certaines autres conséquences de causes externes"},
      {code: "V01-Y98", title: "Causes externes de morbidité et de mortalité"},
      {code: "Z00-Z99", title: "Facteurs influant sur l'état de santé et motifs de recours aux services de santé"},
      {code: "U00-U99", title: "Codes d'utilisation particulière"},
    ],
    ccmu_choices: ["1", "2", "3", "4", "5", "P", "D"],
    day: null,
    transportation: null,
    stay_reason: null,
    age: null,
    entry_time: null,
    ccmu: null,
    number_of_acts: null,
    is_hospitalized: 0,
    radiology: 0,
    echography: 0,
    result_map: {
      0: "moins de 4h",
      1: "plus de 4h"
    },
    result: null,
  }),
  methods: {
    predict_emergency_los() {
      this.loading = true;
      axios.get("/api/emergency/predict_length_of_stay", {
        params: {
          "day": this.day,
          "transportation": this.transportation,
          "stay_reason": this.stay_reason,
          "age": this.age,
          "entry_time": this.entry_time,
          "ccmu": this.ccmu,
          "number_of_acts": this.number_of_acts,
          "is_hospitalized": this.is_hospitalized ? 1 : 0,
          "radiology": this.radiology ? 1 : 0,
          "echography": this.echography? 1 : 0,
        },
        headers: {"X-CSRFToken": getCookie("csrftoken")},
      }).then(response => {
        this.result = this.result_map[response.data.toString()];
      }).catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
