<template>
  <div>
    <v-app-bar elevation="0" color="bg_primary" dense class="ma-2" permanent style="align-items: center">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="hidden-sm-and-down mr-8">
        <a href="/home">
          <v-img width="140px" :src="logo_anamnese" alt="Logo Anamnese"></v-img>
        </a>
      </v-toolbar-title>
      <v-spacer />
      <v-menu v-model="showMenu" offset-y max-height="75vh" max-width="45vw" min-width="45vw">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            solo
            rounded
            v-bind="attrs"
            v-on="on"
            append-icon="mdi-magnify"
            color="white"
            label="Recherche"
            v-model="search"
            class="pt-7"
          >
          </v-text-field>
        </template>
        <v-list v-if="loading">
          <v-list-item
            style="min-height: 200px; justify-content: center"
          >
            <v-progress-circular
              :size="100"
              :width="10"
              color="soft_blue"
              indeterminate
            >
            </v-progress-circular>
          </v-list-item>
        </v-list>
        <v-list v-else-if="!search || search < 3">
          <v-list-item
            style="
              justify-content: center;
              font-size: small;
            "
          >
            <v-list-item-content>
              Au moins 3 caractères pour lancer la recherche
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else-if="noResult">
          <v-list-item
            style="
              justify-content: center;
              font-size: small;
            "
          >
            <v-list-item-content>
              Pas de résultat pour cette recherche
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            class="py-0"
            :key="item.uid"
            v-for="item in searchResult"
            @click="redirectToNode(item.uid)"
          >
            <div :key="label" v-for="label in item.labels">
              <v-list-item-avatar
                v-if="Object.keys(nodeTypesDict).includes(label)"
                size="26"
                :color="
                  nodeTypesDict[label]
                    ? nodeTypesDict[label].color
                    : 'black'
                "
                class="white--text justify-center  ma-auto"
              >
                {{
                  nodeTypesDict[label]
                    ? nodeTypesDict[label].symbol
                    : label[0]
                }}
              </v-list-item-avatar>
            </div>
            <v-list-item-content class="ml-2">
              <v-list-item-title v-if="item.icd10 && !item.is_deprecated">{{item.icd10}} - {{item.name}}</v-list-item-title>
              <v-list-item-title class="grey--text" v-else-if="item.icd10 && item.is_deprecated">{{item.icd10}} - {{item.name}} (déprécié)</v-list-item-title>
              <v-list-item-title v-else-if="!item.icd10 && !item.is_deprecated">{{item.name}}</v-list-item-title>
              <v-list-item-title class="grey--text" v-else-if="!item.icd10 && item.is_deprecated">{{item.name}} (déprécié)</v-list-item-title>
              <v-list-item-title v-else>{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        :close-on-content-click="false"
        min-width="300"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="px-5">
          <v-checkbox
            :key="key"
            hide-details
            class="pa-0 ma-0"
            v-for="(value, key) in nodeTypesDict"
            v-model="selected"
            :label="value.name"
            :value="key"
          >
          </v-checkbox>
          <v-row class="px-0 mx-0">
            <v-col cols="6" class="text-center">
              <v-btn
                small
                solo
                color="soft_blue"
                @click="selected = search_filters"
              >
                Tout
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                small
                solo
                color="soft_blue"
                @click="selected = []"
              >
                Rien
              </v-btn>
            </v-col>
          </v-row>
        </v-list>
      </v-menu>
      <v-btn
        v-if="$route.path.includes('details')"
        text
        color="primary"
        class="text-button font-weight-medium"
        @click="$router.push('/graph/' + $route.params.uid)"
      >
        Graph
      </v-btn>
      <v-btn
        v-if="$route.path.includes('graph')"
        text
        color="primary"
        class="text-button font-weight-medium"
        @click="$router.push('/details/' + $route.params.uid)"
      >
        Details
      </v-btn>
      <v-spacer />
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
            class="ml-4"
          >
            <v-avatar
              color="primary"
              size="48"
            >
              <span class="white--text text-h5">{{ user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="primary"
                class="mb-3"
              >
                <span class="white--text text-h5">{{ user.initials }}</span>
              </v-avatar>
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                 @click="redirectToAdmin()"
              >
                Admin
              </v-btn>
              <v-btn
                depressed
                rounded
                text
                @click="deconnexion()"
              >
                Déconnexion
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list>
        <v-list-group prepend-icon="mdi-plus" v-model="add_show">
          <template v-slot:activator>
            <v-list-item-title>Ajouter</v-list-item-title>
          </template>
          <v-list-item
            :key="key"
            v-for="(value, key) in dialogDict"
            style="padding-left: 4em"
            @click="dialogDict[key] = true"
          >
            {{ nodeTypesDict[key].name }}
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-eye" v-model="visu_show">
          <template v-slot:activator>
            <v-list-item-title>Visualiser</v-list-item-title>
          </template>
          <v-list-item
            :key="key"
            v-for="(value, key) in nodeTypesDict"
            style="padding-left: 4em"
            :href="'/list/' + key"
          >
            {{ value.name }}
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group>
          <v-list-item :key="item.text" v-for="item in pages" :href="item.redirect">
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                {{ item.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <CreateQuestionModal
        @creating_question="launchAnswer($event)"
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['Question']"
      />
      <CreateAnswerModal
        @update-question-list="$emit('update-question-list', $event)"
        :questionData="questionData"
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogAnswer"
      />
      <CreateSymptomModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['Symptom']"
      />
      <CreateDiseaseModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['Disease']"
      />
      <CreateRiskFactorModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['RiskFactor']"
      />
      <CreateMasterQuestionModal
        @update-question-list="$emit('update-question-list', $event)"
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['MasterQuestion']"
      />
      <CreateGroupModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['Group']"
      />
       <CreateClinicalExamModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['ClinicalExam']"
      />
      <CreateMedExamModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['MedicalExam']"
      />
       <CreateMedExamResultModal
        :nodeTypesDict="nodeTypesDict"
        :snackbar.sync="snackbar"
        :snackText.sync="snackText"
        :dialog.sync="dialogDict['MedicalExamResult']"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import {getCookie} from "@/data/utils";
import CreateQuestionModal from "@/components/Dialog/CreateQuestionModal";
import CreateAnswerModal from "@/components/Dialog/CreateAnswerModal";
import CreateSymptomModal from "@/components/Dialog/CreateSymptomModal";
import CreateDiseaseModal from "@/components/Dialog/CreateDiseaseModal";
import CreateRiskFactorModal from "@/components/Dialog/CreateRiskFactorModal";
import CreateMasterQuestionModal from "@/components/Dialog/CreateMasterQuestionModal";
import CreateGroupModal from "@/components/Dialog/CreateGroupModal";
import CreateMedExamModal from "@/components/Dialog/CreateMedExamModal";
import CreateMedExamResultModal from "@/components/Dialog/CreateMedExamResultModal";
import CreateClinicalExamModal from "@/components/Dialog/CreateClinicalExamModal";

export default {
  name: "HeaderDrawer",
  components: {
    CreateQuestionModal,
    CreateAnswerModal,
    CreateSymptomModal,
    CreateDiseaseModal,
    CreateRiskFactorModal,
    CreateMasterQuestionModal,
    CreateGroupModal,
    CreateClinicalExamModal,
    CreateMedExamModal,
    CreateMedExamResultModal
  },
  data: () => ({
    logo_anamnese: "https://www.anamnese.care/hubfs/Visuels/Logos%20anamnese/Logo-2022-Anamnese.png",
    dialogDict: {
      Question: false,
      Symptom: false,
      Disease: false,
      RiskFactor: false,
      MasterQuestion: false,
      Group: false,
      ClinicalExam: false,
      MedicalExam: false,
      MedicalExamResult: false,
    },
    questionData: null,
    dialogAnswer: false,
    noResult: false,
    snackText: null,
    snackbar: false,
    timeout: null,
    search: null,
    searchResult: [],
    drawer: false,
    add_show: false,
    visu_show: false,
    showMenu: false,
    loading: false,
    user: {
      initials: null,
      fullName: null,
      email: null,
    },
    search_filters: [
      "MasterQuestion",
      "Question",
      "Answer",
      "Symptom",
      "RiskFactor",
      "Group",
      "Disease",
      "ClinicalExam",
      "MedicalExam",
      "MedicalExamResult",
      "Bacteria",
      "Virus",
      "Parasite",
      "Surgery",
      "Vaccine",
      "Drug",
      "Compound",
      "DCI"
    ],
    selected: [
      "MasterQuestion",
      "Question",
      "Symptom",
      "RiskFactor",
      "Group",
      "Disease",
      "DCI",
      "ClinicalExam",
      "MedicalExam",
      "MedicalExamResult",
    ],
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
    },
    pages: [
      {
        text: "Accueil",
        redirect: "/home",
        icon: "mdi-home"
      },
      {
        text: "Cas Cliniques",
        redirect: "/test_cases",
        icon: "mdi-hospital-box"
      },
      {
        text: "Questionnaires",
        redirect: "/questionnaires",
        icon: "mdi-comment-question-outline"
      },
        {
        text: "Classification ATC",
        redirect: "/atc_classification",
        icon: "mdi-file-tree"
      },
      {
        text: "Diagnostic Différentiel",
        redirect: "/differential_diagnosis",
        icon: "mdi-google-analytics"
      },
      {
        text: "Démo Machine Learning",
        redirect: "/machine_learning",
        icon: "mdi-vector-polyline"
      },
      {
        text: "Maintenance",
        redirect: "/maintenance",
        icon: "mdi-wrench"
      },
      {
        text: "MAJ Récentes",
        redirect: "/recent_changes",
        icon: "mdi-history"
      },
      {
        text: "Traduction",
        redirect: "/translation",
        icon: "mdi-translate"
      },
    ]
  }),
  mounted: function() {
    axios
      .get("/api/mkg/get_user_name", {})
      .then(response => {
        this.user["fullName"] = response.data.full_name;
        this.user["email"] = response.data.email;
        this.user["initials"] = this.getInitials(response.data.full_name);
      }).catch(() => {
        this.user["fullName"] = null;
        this.user["email"] = null;
        this.user["initials"] = null;
      });
  },
  watch: {
    selected() {
      this.startSearch(this.search);
    },
    search(value) {
      this.startSearch(value);
    }
  },
  methods: {
    deconnexion: function() {
      this.$store.dispatch('axiosLogout');
      this.$keycloak.logout({redirectUri: this.$store.state.keycloak.redirect_logout});
    },
    getInitials(name) {
      return name.match(/(\b\S)?/g).join("");
    },
    startSearch(value) {
      this.noResult = false;
      if (value && value.length >= 3) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.showMenu = false;
        }
        this.timeout = setTimeout(() => {
          this.searchResult = [];
          this.loading = true;
          this.showMenu = true;
          let data = {
            txt: value,
            labels: this.selected,
            medical_classification_filter: null,
            group_by_label: false,
            hide_deprecated: false,
          }
          axios
            .get("/api/mkg/search_ind", {
              params: {"payload": data},
              headers: {"X-CSRFToken": getCookie("csrftoken")},
            })
            .then(response => {
              this.searchResult = response.data.all;
            })
            .catch(error => {
              this.$store.dispatch("ManageError", error.response.status)
            })
            .finally(() => {
              if (!this.searchResult || this.searchResult.length === 0)
                this.noResult = true;
              this.loading = false;
            });
        }, 500);
      } else {
        this.showMenu = false;
      }
    },
    launchAnswer(data) {
      this.questionData = data;
      this.dialogAnswer = true;
    },
    redirect() {
      this.loading = true;
      this.search = null;
    },
    redirectToNode(uid) {
      this.$router.push("/details/" + uid);
    },
    redirectToAdmin() {
      window.location.href = "/admin/"
    },
  }
};
</script>
