<template>
  <v-app>
    <v-main class="light_blue_inzee">
      <v-container fluid class="text-center">
        <p class="mt-12 font-size--34 semibold--text primary--text">Déconnexion en cours</p>
        <v-progress-circular indeterminate size="100" class="mt-10" color="secondary"></v-progress-circular>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "RedirectionView",
  mounted() {
    this.$store.dispatch('axiosLogout');
    if(this.$keycloak) this.$keycloak.logout({redirectUri: this.$store.getters.getEnvKeycloakAnamnese('redirect_logout')});
  },
}
</script>

<style>
.br-15 {
  border-radius: 15px !important;
}
</style>
