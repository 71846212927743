<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer un examen clinique
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm" autocomplete="off">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'ClinicalExam'"
              :linkNode="true"
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
            class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
  name: "CreateClinicalExamModal",
  components: {
    CreateModalCore
  },
  data: () => ({
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    required_rule: v => !!v || "Ce champ est requis",
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let data = {
        name: this.$store.state.newName,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes
      };
      axios
        .post("/api/clinical-exams", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    }
  }
};
</script>
