<template>
  <v-container class="ma-0" style="max-width: 100%;">
    <v-snackbar bottom v-model="snackbar" timeout="2000" :color="snackColor">
      {{ message }}
    </v-snackbar>
    <v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="secondary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="date-picker-days@10"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card v-if="description" color="#BDD5EA" tile class="pa-5 my-3">
        <b>{{ description }}</b>
      </v-card>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title> {{ titleList }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title> {{ itemList.length }} Noeuds </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="itemList"
            :items-per-page="50"
            :footer-props="{ 'items-per-page-options': [50, 100] }"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <a :href="'/details/' + row.item.uid"
                    >{{row.item.name}}</a
                  >
                </td>
                <td style="width: 20%;">
                  <v-select
                    hide-details
                    class="my-3"
                    outlined
                    dense
                    v-model="row.item.primary_label"
                    :items="row.item.med_class"
                    label="Classification principale"
                  ></v-select>
                </td>
                <td class="text-end">
                  <v-btn outlined color="secondary" @click="saveItem(row.item)"
                    >Enregistrer</v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
	name: "PrimaryMedClass",
	data: () => ({
    loading: true,
		message: '',
		snackbar: false,
		snackColor: 'white',
		validForm: false,
		titleList: null,
		description: null,
		itemList: [],
	}),
	created(){
		axios
			.get("/api/mkg/disease_primary_med_class", {})
			.then((response) => {
				this.titleList = response.data.list_title,
				this.description = response.data.description,
				this.itemList = response.data.item_list
			})
			.catch((error) => {
				console.log(error);
				this.message = 'Erreur lors de l\'aquisition des données';
				this.snackColor = 'error';
				this.snackbar = true;
			})
			.finally(() => {
				this.loading = false;
			});

	},
	methods: {
		saveItem(item){
			if (!item.primary_label){
				this.message = 'Classification principale requise';
				this.snackColor = 'error';
				this.snackbar = true;
			} else {
				axios
					.patch("/api/mkg/disease_primary_med_class",
						{uid: item.uid, med_class: item.primary_label},
						{ headers: { "X-CSRFToken": getCookie("csrftoken") },
					})
					.then((response) => {
						this.message = 'Mis à jour avec succès';
						this.snackColor = 'success';
						const uid = response.data.uid;
						this.itemList.splice(this.itemList.map(function(e) { return e.uid; }).indexOf(uid), 1)
					})
					.catch((error) => {
						console.log(error);
						this.message = error.response.data.detail;
						this.snackColor = 'error';
					})
					.finally(() => {
						this.snackbar = true;
					});
			}
		}
	}
};
</script>
