<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur l'examen médical
				</v-card-title>
        <v-card-text elevation="10" v-if="!launched">
          <v-skeleton-loader
            class="mx-10"
            type="article, actions, image, table-tfoot"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>UID</b>
              </p>
            </v-col>
            <v-col cols="5" class="text-start">
              <p class="primary--text" >
                {{uid}}
              </p>
            </v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>CIM-10</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="nodeCIM"
								hide-details
								@change="update_field('icd10_id', nodeCIM, route, fieldsDict['icd10_id'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>Nom</b>
              </p>
            </v-col>
            <v-col cols="7">
              <v-text-field
                dense
                outlined
                v-model="nodeName"
                label="Nom du noeud"
                hide-details
                @change="
                  update_field('name', nodeName, route, fieldsDict['name'])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" class=" mt-1">
							<v-btn
								color="primary"
                elevation="0"
								:href="'/translate/' + uid"
							> Traduction </v-btn>
						</v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>Spécialité(s)</b>
              </p>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                outlined
                dense
                hide-details
                placeholder="Aucune séléctionné"
                v-model="nodeSpe"
                :items="speciality_list"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                multiple
                @change="
                  update_field(
                    'medical_classification',
                    nodeSpe,
                    route,
                    fieldsDict['medical_classification']
                  )
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Pas de resultat pour cette recherche
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>ID Moebius</b></p>
						</v-col>
            <v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="id_moebius"
								hide-details
								@change="update_field('id_moebius', id_moebius, route, fieldsDict['id_moebius'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Code Chirurgie</b></p>
						</v-col>
           <v-col cols="7" class="text-start">
             <v-chip-group column active-class="primary--text">
              <v-chip
                :key="item"
                v-for="item in surgery_code"
                close
                label
                @click:close="removeSurgeryCode(item)"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
						</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-text-field
                outlined
                dense
                label="Code chirurgie"
                v-model="newCode"
                @keydown.enter="addSurgeryCode"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                fab
                small
                elevation="0"
                :disabled="!newCode || surgery_code.includes(newCode)"
                color="primary"
                @click="addSurgeryCode"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Importance</b></p>
						</v-col>
            <v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="surgery_importance"
								hide-details
								@change="update_field('surgery_importance', surgery_importance, route, fieldsDict['surgery_importance'])"
							></v-text-field>
						</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0" v-if="!launched">
						<v-skeleton-loader
							type="article, table-tfoot"
						></v-skeleton-loader>
					</v-card>
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-else>
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
						<v-card-text>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    :disabled="!prChanged"
                    text
                    color="blue darken-1"
                    @click="save_pr(prerequisites, route); prChanged=false"
                  > Enregistrer </v-btn>
                </v-col>
              </v-row>
						</v-card-text>
					</v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- Actions -->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'MedicalExam'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";

export default {
  name: "SurgeryDetails",
  components: {
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/surgery/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeCIM: "",
    nodeName: "",
    id_moebius: "",
    surgery_code: [],
    newCode: "",
    surgery_importance: "",
    nodeSpe: [],
    prerequisites: [],
    prChanged: false,
    updated_by: "",
    updated_at: "",
    is_deprecated: null,
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
    }
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    },
    surgery_code(value) {
      if (this.loaded) {
        this.update_field(
          "surgery_code",
          value,
          this.route,
          this.fieldsDict["surgery_code"]
        );
      }
    }
  },
  mounted: function() {
    this.refreshData();
  },
  methods: {
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "Surgery"
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeCIM = nodeData.icd10_id.value;
          this.id_moebius = (nodeData.id_moebius.value != null ? nodeData.id_moebius.value : "");
          this.surgery_code = (nodeData.surgery_code.value != null ? nodeData.surgery_code.value : []);
          this.surgery_importance = nodeData.surgery_importance.value;
          this.nodeName = nodeData.name_fr.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.is_deprecated = nodeData.is_deprecated.value;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    },
    addSurgeryCode() {
      if (this.newCode === "" || this.surgery_code.includes(this.newCode)) {
        return;
      }
      this.surgery_code.push(this.newCode);
      this.newCode = "";
    },
    removeSurgeryCode(item) {
      const index = this.surgery_code.indexOf(item);
      if (index > -1) {
        this.surgery_code.splice(index, 1);
      }
    },
  }
};
</script>
