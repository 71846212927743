import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
		  primary: '#337477', // green
			primary_2: '#D1E6D8', // light green
			primary_3: '#54847E', // pastel/dark green
			secondary: '#E94E1B', // orange
			secondary_2: '#EE9C7B', // light orange
			secondary_3: '#B1512E', // dark orange

			pastel_green: '#81C784',
			pastel_red: '#E57373',
			bg: '#ECECEC',
			bg_light: "#F7F7F7",
			bg_primary: "#E2EBEC",
			bg_secondary: "#FDEFEA",
			bg_errors_layout: "#337477",

			error: '#F44336',
			success: '#4CAF50',
			info: '#2196F3',
			warning: '#FFC107',

			basic : '#1B2A49',

			score_green: '#7FC881',
			score_yellow: '#C7B400',
			score_orange: '#F9A825',
			score_red: '#E77271',
      },
    },
  },
})
