<template>
  <v-container style="max-width: 100%">
    <div
      v-if="loading"
      style="width: 100%; text-align: center; padding-top: 15%;"
    >
      <v-progress-circular
        :size="250"
        :width="20"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12">
        <v-toolbar color="soft_blue" class="white--text">
          <v-icon color="white" class="mr-2">mdi-compass</v-icon>
          <h4>{{ commercial_questionnaire_name }}</h4>
          <v-spacer></v-spacer>
          <v-select
            v-model="entry_point"
            prepend-icon="mdi-map-marker-question"
            color="white"
            :items="$store.state.entryPointList"
            item-value="uid"
            item-text="name"
            label="Point d'entrée"
            @change="$router.go()"
            dense
            outlined
            hide-details
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Pas de resultat point d'entrée disponible
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-spacer></v-spacer>
          <v-icon color="soft_blue" class="mr-2">mdi-apps</v-icon>
          <h4 style="margin: auto">{{ app }}</h4>
        </v-toolbar>
      </v-col>
      <v-col cols="4">
        <v-card style="width: 100%; height: 100%">
          <v-card-title class="soft_blue white--text">
            Questions disponibles
          </v-card-title>
          <v-card-text class="py-3">
            <div style="height: 72vh">
              <v-list>
                <v-list-item
                  :key="index"
                  v-for="(item, index) in $store.state.question_list"
                  class="px-0"
                >
                  <v-row>
                    <v-col cols="9" class="my-0 py-0">
                      <v-row>
                        <v-col cols="1" class="text-center">
                          <v-avatar
                            v-if="item.type === 'Question'"
                            size="30"
                            color="grey"
                            class="white--text"
                            >Q</v-avatar
                          >
                          <v-avatar
                            v-else
                            size="30"
                            color="brown"
                            class="white--text"
                            >MQ</v-avatar
                          >
                        </v-col>
                        <v-col cols="11" class="text-start">
                          {{ item.name }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3" class="text-end my-0 py-0">
                      <v-btn
                        color="success"
                        fab
                        x-small
                        dark
                        @click="addNodeToGraph(item.uid, index)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        color="blue-grey"
                        fab
                        x-small
                        dark
                        @click="$router.push('/details/' + item.uid)"
                      >
                        <v-icon>mdi-pen</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        fab
                        x-small
                        dark
                        @click="try_remove(item.uid, index)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </div>
            <v-dialog v-model="confirmDialog" width="20%">
              <v-card class="error--text text-center pt-3">
                <h3>Supprimer le noeud ?</h3>
                <v-card-actions>
                  <v-col class="text-center">
                    <v-btn
                      @click="confirmDialog = false"
                      outlined
                      color="error"
                    >
                      Non
                    </v-btn>
                    <v-btn
                      @click="remove()"
                      :loading="removeLoading"
                      color="error"
                      class="white--text ml-2"
                    >
                      Oui
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <ImpactModal
              @impact_maj="toggle_snack($event.message)"
              :uid="$store.state.impactUid"
              :dialog.sync="$store.state.impactDialog"
            />
          </v-card-text>
        </v-card>
        <v-snackbar bottom v-model="snackbar" timeout="2000">
          {{ message }}
        </v-snackbar>
      </v-col>
      <v-col cols="8">
        <v-card style="width: 100%; height: 100%">
          <v-card-title class="soft_blue white--text">
            Visualisation du questionnaire
          </v-card-title>
          <v-card-text class="py-5">
            <div>
              <div v-if="$store.state.loading_graph">
                <v-progress-linear
                  id="questionnaire_loading"
                  indeterminate
                  rounded
                  color="secondary"
                  height="5"
                ></v-progress-linear>
              </div>
              <div
                id="questionnaire_graph"
                style="width:100%; height:70vh;"
              ></div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import ImpactModal from "@/components/Dialog/ImpactModal";

import { initiate_graph } from "@/data/questionnaire.js";
import { add_node } from "@/data/questionnaire.js";

export default {
  name: "QuestionDetails",
  components: {
    ImpactModal
  },
  data: () => ({
    loading: true,
    message: "",
    snackbar: false,
    choose_meta_questionnaire: "",
    meta_questionnaire_name: "",
    commercial_questionnaire_name: "",
    entry_point: "",
    app: "",
    to_remove: null,
    confirmDialog: false,
    removeLoading: false
  }),
  created: function() {
    axios
      .get("/api/mkg/questionnaires/" + this.$route.params.name, {})
      .then(response => {
        this.choose_meta_questionnaire =
          response.data.choose_meta_questionnaire;
        this.$store.state.question_list = response.data.question_list;
        this.meta_questionnaire_name = response.data.meta_questionnaire_name;
        this.commercial_questionnaire_name =
          response.data.commercial_questionnaire_name;
        this.entry_point = response.data.entry_point;
        this.app = response.data.app;
      })
      .catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      })
      .finally(() => {
        this.loading = false;
        //Rework
        setTimeout(() => {
          this.$store.state.loading_graph = false;
          initiate_graph(this.entry_point);
        }, 1000);
      });
  },
  watch: {
    entry_point(val) {
      axios.get("/api/update_entry_point", {
        params: {
          entry_point: val,
          meta_questionnaire: this.meta_questionnaire_name
        }
      })
      .catch(error => {
          this.message = "Une erreur est survenue lors du change de point d'entrée";
          this.snackbar = true;
      });
    }
  },
  methods: {
    toggle_snack(event) {
      this.message = event;
      this.snackbar = true;
    },
    updateQuestionList(meta) {
      this.$store.state.question_list.push(meta);
    },
    addNodeToGraph(uid, index) {
      add_node(uid, this.meta_questionnaire_name);
      this.$store.state.question_list.splice(index, 1);
    },
    try_remove(uid, index) {
      this.toRemove = uid;
      this.toRemoveIndex = index;
      this.confirmDialog = true;
    },
    remove() {
      this.removeLoading = true;
      axios
        .delete("/api/questions/" + this.toRemove, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.$store.state.question_list.splice(this.toRemoveIndex, 1);
          this.confirmDialog = false;
          this.message = "Noeud supprimé avec succès";
        })
        .catch(error => {
          this.message = error.response.data.detail;
          if (!this.message)
            this.message = "Une erreur est survenue lors de la supression";
        })
        .finally(() => {
          this.snackbar = true;
          this.toRemove = null;
          this.removeLoading = false;
        });
    }
  }
};
</script>
