<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur l'examen médical
				</v-card-title>
        <v-card-text elevation="10" v-if="!launched">
          <v-skeleton-loader
            class="mx-10"
            type="article, actions, image, table-tfoot"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>UID</b>
              </p>
            </v-col>
            <v-col cols="5" class="text-start">
              <p class="primary--text" >
                {{uid}}
              </p>
            </v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>CIM-10</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="nodeCIM"
								hide-details
								@change="update_field('icd10_id', nodeCIM, route, fieldsDict['icd10_id'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>Nom</b>
              </p>
            </v-col>
            <v-col cols="7">
              <v-text-field
                dense
                outlined
                v-model="nodeName"
                label="Nom du noeud"
                hide-details
                @change="
                  update_field('name', nodeName, route, fieldsDict['name'])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" class=" mt-1">
							<v-btn
								color="primary"
                elevation="0"
								:href="'/translate/' + uid"
							> Traduction </v-btn>
						</v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>Spécialité(s)</b>
              </p>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                outlined
                dense
                hide-details
                placeholder="Aucune séléctionné"
                v-model="nodeSpe"
                :items="speciality_list"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                multiple
                @change="
                  update_field(
                    'medical_classification',
                    nodeSpe,
                    route,
                    fieldsDict['medical_classification']
                  )
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Pas de resultat pour cette recherche
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text" >
                <b>Type d'examen</b>
              </p>
            </v-col>
            <v-col cols="3">
              <v-select
                outlined
                dense
                hide-details
                v-model="exam_type"
                :items="examTypeChoices"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                @change="
                  update_field(
                    'type_of_exam',
                    exam_type,
                    route,
                    fieldsDict['exam_type']
                  )
                "
              ></v-select>
            </v-col>
            <v-col cols="6" class="text-start" style="margin: auto;">
              <v-textarea
                outlined
                auto-grow
                clearable
                clear-icon="mdi-close"
                v-model="description"
                label="Description (Optionnel) — Mot clés: TODO, FIXME"
                hide-details
                @change="
                  update_field(
                    'description',
                    description,
                    route,
                    fieldsDict['description']
                  )
                "
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-start" style="margin: auto;">
              <v-textarea
                outlined
                auto-grow
                clearable
                clear-icon="mdi-close"
                v-model="contre_indications"
                label="Contre-indications (Optionnel)"
                hide-details
                @change="
                  update_field(
                    'contraindication',
                    contre_indications,
                    route,
                    fieldsDict['contre_indications']
                  )
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0" v-if="!launched">
						<v-skeleton-loader
							type="article, table-tfoot"
						></v-skeleton-loader>
					</v-card>
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-else>
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
						<v-card-text>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    :disabled="!prChanged"
                    text
                    color="blue darken-1"
                    @click="save_pr(prerequisites, route); prChanged=false"
                  > Enregistrer </v-btn>
                </v-col>
              </v-row>
						</v-card-text>
					</v-card>
        </v-col>
        <Synonyms
          :synonyms.sync="synonyms"
          :loaded="loaded"
          :launched="launched"
          :update_field="update_field"
        />
      </v-row>
    </v-col>
    <!-- Actions -->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'MedicalExam'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";
import Synonyms from "@/components/Utils/Synonyms.vue";

export default {
  name: "MedExamDetails",
  components: {
    Synonyms,
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/medical-exams/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeCIM: "",
    nodeName: "",
    nodeSpe: [],
    synonyms: [],
    description: "",
    exam_type: "",
    examTypeChoices: JSON.parse(localStorage.getItem("medical_exam_tag")),
    contre_indications: "",
    prerequisites: [],
    prChanged: false,
    is_deprecated: null,
    updated_by: "",
    updated_at: "",
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
      description: "de la description",
      exam_type: "du type d'examen",
      contre_indications: "des contre-indications"
    }
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    }
  },
  mounted: function() {
    this.refreshData();
  },
  methods: {
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "MedicalExam"
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeCIM = nodeData.icd10_id.value;
          this.nodeName = nodeData.name_fr.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.description = nodeData.description.value;
          this.synonyms = nodeData.synonyme.value;
          this.exam_type = nodeData.type_of_exam.value;
          this.contre_indications = nodeData.contraindication.value;
          this.is_deprecated = nodeData.is_deprecated.value;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    }
  }
};
</script>
