<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer une Master Question
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'MasterQuestion'"
              :linkNode="false"
            />
            <MetaQuestName
              :questionnaire.sync="meta_questionnaire_name"
            />
            <v-col cols="12">
              <SubLink
                :label="'Ajouter une sous-question'"
                :color="'grey'"
                :chosenList.sync="tmpQuestion"
                :current_csq="[]"
                :nodeTypesDict="nodeTypesDict"
                :linkLabels="['Question']"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="questName"
                label="Nom du questionnaire *"
                :rules="[required_rule]"
                :items="possibleQuest"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
            class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import SubLink from "@/components/Utils/SubLink";
import MetaQuestName from "@/components/Utils/MetaQuestName";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
  name: "CreateMasterQuestionModal",
  components: {
		SubLink,
		MetaQuestName,
    CreateModalCore
  },
  data: () => ({
    meta_questionnaire_name: "",
    questName: null,
    possibleQuest: JSON.parse(
      localStorage.getItem("msq_questionnaire_name_choices")
    ),
    tmpQuestion: [],
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    required_rule: v => !!v || "Ce champ est requis",
    len_rule: v => (v && v.length <= 100) || "Max 100 caractères"
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  mounted: function() {
    if (this.possibleQuest) this.questName = this.possibleQuest[0];
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let questions = [];
      for (let i = 0; i < this.tmpQuestion.length; i++) {
        questions.push(this.tmpQuestion[i].uid);
      }
      let data = {
        questionnaire_name: this.questName,
        meta_questionnaire_name: this.meta_questionnaire_name
          ? this.meta_questionnaire_name
          : "",
        name: this.$store.state.newName,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes,
        sub_questions: questions
      };
      axios
        .post("/api/master-questions", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          if (this.meta_questionnaire_name !== "")
            this.$emit("update_question_list", response.data.meta);
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    }
  }
};
</script>
