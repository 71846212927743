<template>
  <v-app class="bg_primary">
    <HeaderDrawer v-if="not_error()"/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import HeaderDrawer from '@/components/Global/HeaderDrawer'

export default {
  name: "MkgLayout",
  components: { HeaderDrawer },
   methods: {
    not_error () {
			return this.$route.path !== '/404' && this.$route.path !== '/403' && this.$route.path !== '/401';
		},
  }
}
</script>

<style scoped>

</style>