<template>
	<v-col cols="12" class="py-0 my-0">
		<v-row>
			<v-col cols="2">
				<v-checkbox
					:value="is_scoring"
					label="Scoring"
					color="primary"
					@change="$emit('update:is_scoring', !is_scoring);"
				></v-checkbox>
			</v-col>
			<v-col cols="4" v-if="is_scoring">
				<v-select
          outlined
          dense
					v-model="$store.state.scoring_type"
					label="Type de scoring"
					:items="scoringTypes"
					color="primary"
					item-color="primary"
					:rules="[required_if_scoring]"
				></v-select>
			</v-col>
			<v-col cols="4" v-if="is_scoring">
				<v-autocomplete
          outlined
          dense
					v-model="$store.state.scoring_name"
					label="Nom du scoring"
					:items="scoringNames"
					:rules="[required_if_scoring]"
					color="primary"
					item-color="primary"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="2" style="text-align: start;" v-if="is_scoring">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon text color="primary" v-bind="attrs" v-on="on" @click="scoringDialog = !scoringDialog">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<span>Ajouter un scoring</span>
				</v-tooltip>
			</v-col>
		</v-row>
		<v-dialog persistent v-model="scoringDialog" width="650">
			<v-card>
				<v-card-title class="px-8 primary white--text" style="white-space: normal; word-break: break-word;">
					Ajouter un nom de scoring
				</v-card-title>
				<v-card-text class="py-5">
					<v-form ref="form_scoring" v-model="validScoringForm" autocomplete="off">
						<v-row>
							<v-col cols="12" fluid>
								<v-text-field
									v-model="scoringToAdd"
									label="Nom du scoring *"
									counter="50"
									:rules="[required_rule, len50_rule]"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-col offset="6">
							<v-btn
							style="padding: 0; text-align: center; width: 6em;"
							outlined
							color="primary"
							@click="close_scoring">
								Fermer
							</v-btn>
							<v-btn
							class="mx-2"
							style="padding: 0; text-align: center; width: 10em;"
							:disabled="!validScoringForm"
							@click="save_scoring"
							color="primary">
								Enregistrer
							</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar
			bottom
			class="white--text mb-12"
			v-model="snackbar"
			:timeout="timeout"
			:color="snackColor"
		>
			{{ scoringAddText }}
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
	name: "QuestionScoring",
	props: {
    validForm: Boolean,
    is_scoring: Boolean,
  },
  data: () => ({
    snackbar: false,
    timeout: 5000,
    snackColor: "primary",
    scoringAddText: null,
    scoringNames: [],
    scoringTypes: JSON.parse(localStorage.getItem("scoring_choices")),
    validScoringForm: false,
    scoringDialog: false,
    scoringToAdd: null,
    required_rule: (v) => !!v || "Ce champ est requis",
    len50_rule: (v) => (v && v.length <= 50) || "Max 50 caractères",
  }),
  mounted: function () {
    this.getScoringData();
  },
  methods: {
    required_if_scoring(value) {
      if (this.is_scoring && !value) {
        this.$emit("update:validForm", false);
        return "Ce champ est requis";
      } else {
        return true;
      }
    },
    getScoringData() {
      this.scoringNames = this.$store.getters.getScoringNames;
    },
    close_scoring() {
      this.$refs.form_scoring.reset();
      this.scoringToAdd = null;
      this.scoringDialog = false;
    },
    save_scoring() {
      axios
        .post(
          "/api/mkg/manage_scoring?scoring_name=" + this.scoringToAdd,
          {}, { headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(() => {
          this.scoringAddText = "Nom de scoring ajouté";
          this.snackColor = "blue-grey";
        })
        .catch((error) => {
          this.scoringAddText = error.response.data.detail;
          this.snackColor = "error";
        })
        .finally(() => {
          this.snackbar = true;
          this.getScoringData();
        });
      this.close_scoring();
    },
  },
}
</script>
