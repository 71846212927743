<template>
  <v-col cols="12" class="py-0 my-0">
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          ref="node_search"
          :loading="isLoading"
          :items="searchNodeList"
          :search-input.sync="searchNode"
          chips
          clearable
          item-text="name"
          :item-value="(item) => item"
          label="Lier à un noeud (Optionnel)"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title v-if="noResult">
                Pas de resultat pour "{{ searchNode }}"
              </v-list-item-title>
              <v-list-item-title v-else-if="subDialog">
                Definissez le lien
              </v-list-item-title>
              <v-list-item-title v-else>
                Rechercher un noeud (min. 3 lettres)
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{}"> </template>
          <template v-slot:item="{ item }">
            <v-list-item
              @click="
                $refs.node_search.blur();
                triggerLinkToNode(item);
              "
            >
              <v-list-item-avatar
                size="26"
                :color="
                  nodeTypesDict[item.class_name]
                    ? nodeTypesDict[item.class_name].color
                    : 'blue-grey'
                "
                class="white--text"
              >
                {{
                  nodeTypesDict[item.class_name]
                    ? nodeTypesDict[item.class_name].symbol
                    : item.class_name[0]
                }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-dialog persistent v-model="subDialog" width="650">
          <v-card ref="link-card">
            <v-card-title
              class="px-8 secondary white--text"
              style="white-space: normal; word-break: break-word"
            >
              Lier un noeud
            </v-card-title>
            <v-card-text class="py-5">
              <v-form ref="form_link" v-model="validSubForm">
                <v-row v-if="!noLinkData">
                  <v-col cols="12">
                    <v-select
                      label="Type de lien"
                      v-model="tmpLinkData"
                      :items="possibleLink"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item"
                      color="secondary"
                      item-color="secondary"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="6">
                    <v-select
                      label="Type de lien"
                      v-model="linkType"
                      :items="allowedTypes"
                      :item-text="(item) => item[1]"
                      :item-value="(item) => item[0]"
                      color="secondary"
                      item-color="secondary"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      label="Type de lien"
                      v-model="linkDirection"
                      :items="allowedDirections"
                      :item-text="(item) => item[1]"
                      :item-value="(item) => item[0]"
                      color="secondary"
                      item-color="secondary"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="tagList">
                  <v-col cols="12">
                    <v-select
                      label="Choix du tag"
                      v-model="tag"
                      :items="tagList"
                      :item-text="(item) => item[1]"
                      :item-value="(item) => item[0]"
                      color="secondary"
                      :rules="[required_rule]"
                      item-color="secondary"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-col offset="6">
                <v-btn outlined color="secondary" @click="closeLinkNode">
                  Fermer
                </v-btn>
                <v-btn
                  class="ml-2"
                  style="padding: 0; text-align: center; width: 10em"
                  :disabled="!validSubForm"
                  @click="addLinkToNode"
                  color="secondary"
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-simple-table v-if="linkedNodes.length > 0">
          <thead>
            <tr>
              <th class="text-center">Nom</th>
              <th class="text-center">Direction</th>
              <th class="text-center">Lien</th>
              <th class="text-center">Noeud</th>
              <th class="text-center">Noeud lié</th>
              <th class="text-center">Supprimer</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(item, index) in linkedNodes">
              <td v-if="item.node.class_name === 'Indication'">
                {{ newName }} (tag : {{ item.tag }})
              </td>
              <td v-else>{{ newName }}</td>
              <td class="text-center">
                <v-btn
                  v-if="item.linkDirection === '-1'"
                  fab
                  x-small
                  color="secondary"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
                <v-btn v-else fab x-small color="secondary"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </td>
              <td class="text-center">
                <v-chip label color="tertiary">{{ item.linkType }}</v-chip>
              </td>
              <td class="text-center">
                <v-avatar
                  size="36"
                  class="white--text"
                  :color="
                    nodeTypesDict[item.node.class_name]
                      ? nodeTypesDict[item.node.class_name].color
                      : 'black'
                  "
                  >{{
                    nodeTypesDict[item.node.class_name]
                      ? nodeTypesDict[item.node.class_name].symbol
                      : item.node.class_name[0]
                  }}</v-avatar
                >
              </td>
              <td>{{ item.node.name }}</td>
              <td class="text-center">
                <v-btn
                  fab
                  x-small
                  color="tertiary"
                  @click="removeLinkToNode(event, index)"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import {getCookie} from "@/data/utils";

export default {
  name: "NodeLink",
  props: {
    newName: String,
    linkedNodes: Array,
    nodeClass: String,
    nodeTypesDict: Object,
  },
  data: () => ({
    timeout: null,
    linkLabels: [],
    subDialog: false,
    isLoading: false,
    validSubForm: false,
    tmpLinkNode: null,
    possibleLinkData: {},
    possibleLink: [],
    allowedTypes: [],
    allowedDirections: [],
    tmpLinkData: {},
    noLinkData: false,
    linkType: null,
    linkDirection: null,
    tag: null,
    tagList: null,
    searchNode: "",
    noResult: false,
    searchNodeList: [],
    required_rule: (v) => !!v || "Ce champ est requis",
    linkStrData: {
      "Answer_is_answer_-1": {
        name: "Cette reponse est une reponse de ma question",
        direction: -1,
        type: "is_answer",
      },
      "Answer_chained_-1": {
        name: "Poser ma question après cette reponse",
        direction: -1,
        type: "chained",
      },
      "Indication_asks_about_-1": {
        name: "Ma question fournit cette indication",
        direction: -1,
        type: "asks_about",
      },
      "Question_chained_-1": {
        name: "Poser ma question après cette question",
        direction: -1,
        type: "chained",
      },
      Question_chained_1: {
        name: "Poser cette question après ma question",
        direction: 1,
        type: "chained",
      },
      "MasterQuestion_is_sub_question_-1": {
        name: "Ma question est une sous-question de cette MasterQuestion",
        direction: -1,
        type: "is_sub_question",
      },
    },
  }),
  mounted: function () {
    if (this.nodeClass) {
      this.getLinkData();
    }
    return;
  },
  methods: {
    getLinkData() {
      axios
        .get("/api/mkg/get_authorized_links", {
          params: {
            node_class: this.nodeClass,
          },
        })
        .then((response) => {
          this.possibleLinkData = response.data;
          this.linkLabels = Object.keys(this.possibleLinkData);
          if (this.nodeClass === "Question") {
            this.linkLabels.shift();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeLinkNode() {
      this.subDialog = false;
      this.linkType = null;
      this.linkDirection = null;
      this.tag = null;
      this.tagList = null;
    },
    triggerLinkToNode(item) {
      this.searchNode = "";
      this.searchNodeList = [];
      this.tmpLinkNode = item;
      this.subDialog = true;
    },
    addLinkToNode() {
      this.linkedNodes.push({
        node: this.tmpLinkNode,
        linkType: this.linkType,
        linkDirection: this.linkDirection,
        tag: this.tag,
      });
      this.$emit("update:linkedNodes", this.linkedNodes);
      this.closeLinkNode();
    },
    removeLinkToNode(index) {
      this.linkedNodes.splice(index, 1);
      this.$emit("update:linkedNodes", this.linkedNodes);
    },
    combineLinkData(key, array1, array2) {
      var tmp = [];
      var res = [];
      array1.forEach(function (a1) {
        array2.forEach(function (a2) {
          tmp.push([key + "_" + a1 + "_" + a2]);
        });
      });
      tmp.forEach((el) =>
        this.linkStrData[el] ? res.push(this.linkStrData[el]) : (res = null)
      );
      return res;
    },
  },
  watch: {
    tmpLinkData() {
      this.linkType = this.tmpLinkData.type;
      this.linkDirection = this.tmpLinkData.direction;
    },
    tmpLinkNode() {
      if (this.tmpLinkNode) {
        this.allowedTypes = Object.keys(
          this.possibleLinkData[this.tmpLinkNode.class_name]
        );
        this.linkType = this.allowedTypes[0];
      } else this.allowedTypes = [];
    },
    linkType() {
      this.noLinkData = false;
      if (this.linkType) {
        var tmpNodeCls = this.tmpLinkNode.class_name;
        if (tmpNodeCls === "Indication") {
          this.tagList = this.possibleLinkData[tmpNodeCls][
            this.linkType
          ].relation_model.tag.choices;
          var tmp = [];
          for (let [key, value] of Object.entries(this.tagList)) {
            tmp.push([key, value]);
          }
          this.tagList = tmp;
        }
        var directions = this.possibleLinkData[tmpNodeCls][this.linkType][
          "directions"
        ];
        directions.forEach((element) =>
          this.allowedDirections.push([
            element,
            element === -1
              ? "Ce noeud vers mon noeud"
              : "Mon noeud vers ce noeud",
          ])
        );
        this.possibleLink = this.combineLinkData(
          tmpNodeCls,
          this.allowedTypes,
          directions
        );
        if (this.possibleLink === null) {
          this.noLinkData = true;
          this.linkDirection = this.allowedDirections[0][0];
        } else {
          this.tmpLinkData = this.possibleLink[0];
        }
      } else this.allowedDirections = [];
    },
    searchNode(value) {
      this.searchNodeList = [];
      if (value && value.length >= 3) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.isLoading = true;
          this.noResult = false;
          let data = {txt: value, labels: this.linkLabels, medical_classification_filter: null, group_by_label: true}
          axios
            .get("/api/mkg/search_ind", {
              params: {"payload": data},
              headers: {"X-CSRFToken": getCookie("csrftoken")},
            })
            .then((response) => {
              for (let i = this.linkLabels.length - 1; i >= 0; i--) {
                const key = this.linkLabels[i];
                this.searchNodeList = this.searchNodeList.concat(
                  response.data[key]
                );
              }
              if (this.searchNodeList.length === 0) this.noResult = true;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 500);
      } else {
        this.searchNodeList = [];
        this.noResult = false;
      }
    },
  },
};
</script>
