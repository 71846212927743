<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="12" class="py-0 my-0">
				<v-row>
					<v-col cols="12" fluid>
						<v-text-field
							v-model="$store.state.newName"
							label="Nom du noeud *"
							counter
							:rules="[required_rule]"
              v-on:input="$emit('newName', $store.state.newName)">
            </v-text-field>
					</v-col>
					<v-col cols="12">
						<v-autocomplete
							v-model="$store.state.specialities"
							:rules="[spe_rule]"
							:items="specialityList"
							:item-text="(item) => item[1]"
							:item-value="(item) => item[0]"
							chips
							label="Spécialité(s) *"
							multiple
						>
							<template v-slot:no-data>
								<v-list-item>
									<v-list-item-title>
										Pas de resultat pour cette recherche
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12">
						<v-textarea
							outlined
							auto-grow
							clearable
							clear-icon="mdi-close"
							v-model="$store.state.description"
							label="Description (Optionnel) — Mot clés: 'OLD' = noeuds à supprimer, 'TODO' = à faire"
						></v-textarea>
					</v-col>
				</v-row>
			</v-col>
			<NodeLink
				v-if="linkNode && !RegExp('.*questionnaires/*.+[a-z]+').test(this.$route.path)"
				:newName="$store.state.newName"
				:nodeClass="nodeClass"
				:linkedNodes.sync="linkedNodes"
				:nodeTypesDict="nodeTypesDict"
			/>
			<Prerequisites
				:validForm.sync="validForm"
				:prerequisites.sync="prerequisites"
			/>
		</v-row>
	</v-col>
</template>

<script>
import NodeLink from "@/components/Utils/NodeLink";
import Prerequisites from "@/components/Utils/Prerequisites";

export default {
	name: "CreateModalCore",
	components: {
		NodeLink,
    Prerequisites,
  },
	props: {
    prerequisites: Array,
    linkedNodes: Array,
    linkNode: Boolean,
    validForm: Boolean,
		nodeClass: String,
    nodeTypesDict: Object,
  },
  data: () => ({
    specialityList: JSON.parse(localStorage.getItem("medical_classification")),
  }),
  methods: {
    required_rule(value) {
      if (!value) {
        this.$emit("update:validForm", false);
        return "Ce champ est requis";
      } else {
        return true;
      }
    },
    spe_rule(value) {
      if (value && value.length === 0) {
        this.$emit("update:validForm", false);
        return "Sélectionnez au moins une spécialité";
      } else {
        return true;
      }
    },
  },
};
</script>
