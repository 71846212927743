<template>
  <v-container style="max-width: 100%">
    <v-card v-if="loading" flat height="100%">
      <v-card-title class="pa-0">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="paragraph@20"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <v-row fill-height fluid v-else>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h4 secondary--text">
            <v-row>
              <v-col cols="12">
                {{ names.name_fr }}
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-radio-group v-model="translation_engine" row>
                  <v-radio
                    v-for="engine in choices_translation_engine"
                    :key="engine"
                    :label="engine"
                    :value="engine"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="mx-5 mt-5">
            <v-form ref="form" v-model="validForm" autocomplete="off">
              <div :key="key" v-for="(name, key) in names">
                <v-row>
                  <v-col cols="11" v-if="key!=='name_fr'">
                    <v-text-field
                      outlined
                      dense
                      clearable
                      :label="key"
                      v-model="names[key]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" v-if="key!=='name_fr'">
                    <v-btn elevation="0" fab dark small color="secondary" @click="translate(key)">
                      <v-icon>mdi-translate</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col style="text-align: center">
              <v-btn
                text
                color="blue darken-1"
                @click="$router.push('/details/' + uid)"
              >
                Retour
              </v-btn>
              <v-btn :disabled="!validForm" text @click="validate" color="blue darken-1">
                Enregistrer
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar class="pa-0"
      bottom
      v-model="snackData.show"
      :timeout="snackData.timeout"
      :color="snackData.color"
    >
      <v-row>
        <v-col cols="10" class="py-0">
          <p class="pt-1 ma-0">{{ snackData.message }}</p>
        </v-col>
        <v-col cols="2" class="py-0">
          <v-progress-circular
            class="text-end"
            v-if="snackData.loading"
            :size="30"
            :width="3"
            color="white"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else color="white">{{ snackData.icon }}</v-icon>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";

export default {
  name: "Translate",
  data: () => ({
    loading: true,
    uid: "",
    names: [],
    name_fr: "",
    validForm: true,
    choices_translation_engine: ['DeepL', 'Google Translate'],
    translation_engine: 'DeepL',
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 2000
    }
  }),
  created: function() {
    axios
      .get("/api/manage_translation/" + this.$route.params.uid, {})
      .then(response => {
        this.names = response.data.names;
        this.name_fr = response.data.names.name_fr;
        this.uid = this.$route.params.uid
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "mdi-close", false, 4000);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    translate(key) {
      axios.get("/api/translate", {
        params: {
          translation_engine: this.translation_engine,
          language: key.split('_')[1].toUpperCase(),
          text: this.names.name_fr
        }
      })
      .then(response => {
        this.names[key] = response.data.name;
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "", false, 4000);
      });
    },
    validate() {
      this.$refs.form.validate();
      axios({
        method: "patch",
        url: "/api/manage_translation/" + this.uid,
        data: {
          names: this.names
        },
        headers: { "X-CSRFToken": getCookie("csrftoken") }
      })
        .then(() => {
          this.$router.push('/details/' + this.uid);
        })
        .catch(error => {
          let message = error.response.data.detail;
          this.toggle_snack("error", message, "mdi-close", false, 4000);
        });
    },
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
  }
};
</script>
