<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur le médicament
				</v-card-title>
        <v-card-text elevation="10" v-if="!launched">
          <v-skeleton-loader
            class="mx-10"
            type="article, actions, image, table-tfoot"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">UID</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <span class="primary--text">{{ uid }}</span>
            </v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Nom</span>
            </v-col>
            <v-col cols="10">
              <v-text-field
                dense
                outlined
                v-model="name"
                label="Nom du noeud"
                hide-details
                @change="
                  update_field('name_fr', name, route, fieldsDict['name_fr'])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Gamme</span>
            </v-col>
            <v-col cols="10">
              <v-text-field
                dense
                outlined
                v-model="brand"
                label="Nom de la gamme"
                hide-details
                @change="
                  update_field('brand', brand, route, fieldsDict['brand'])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Code ATC</span>
            </v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
                label="code ATC"
								v-model="atc_code"
								hide-details
								@change="update_field('atc_code', atc_code, route, fieldsDict['atc_code'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Code CIP13</span>
            </v-col>
						<v-col cols="5" class="text-start">
							<v-text-field
								dense
								outlined
                label="code CIP13"
								v-model="cip13_code"
								hide-details
								@change="update_field('cip13_code', cip13_code, route, fieldsDict['cip13_code'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Code CIS</span>
            </v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
                label="code CIS"
								v-model="cis_code"
								hide-details
								@change="update_field('CIS_code', cis_code, route, fieldsDict['CIS_code'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Forme</span>
            </v-col>
						<v-col cols="5" class="text-start">
							<v-text-field
								dense
								outlined
                label="Forme"
								v-model="pharmaceutical_form"
								hide-details
								@change="update_field('pharmaceutical_form', pharmaceutical_form, route, fieldsDict['pharmaceutical_form'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Type</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="type"
								hide-details
                label="Type (G/R/S)"
								@change="update_field('type', type, route, fieldsDict['type'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Date statut commercial</b></p>
						</v-col>
						<v-col cols="5" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="commercialisation_date"
								hide-details
                label="Date"
								@change="update_field('commercialisation_date', commercialisation_date, route, fieldsDict['commercialisation_date'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Prix HT (€)</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="price_ht"
								hide-details
                label="Prix"
                type="number"
								@change="update_field('price_ht', price_ht, route, fieldsDict['price_ht'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Taux de remboursement (%)</b></p>
						</v-col>
						<v-col cols="5" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="refund"
								hide-details
                label="Remb."
                type="number"
                min="0"
                max="100"
								@change="update_field('refund', pharmaceutical_form, route, fieldsDict['refund'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Présentation</span>
            </v-col>
						<v-col cols="10" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="presentation"
								hide-details
                label="Presentation"
								@change="update_field('presentation', presentation, route, fieldsDict['presentation'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Conditions de prescription</span>
            </v-col>
						<v-col cols="10" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="prescription_conditions"
								hide-details
                label="Conditions de prescrition"
								@change="update_field('prescription_conditions', prescription_conditions, route, fieldsDict['prescription_conditions'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Laboratoires</b></p>
						</v-col>
            <v-col cols="6" class="text-start">
             <v-chip-group column active-class="primary--text">
              <v-chip
                :key="item"
                v-for="item in laboratory"
                close
                label
                @click:close="removeLab(item)"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
						</v-col>
            <v-col cols="3">
              <v-text-field
                outlined
                dense
                label="Laboratoire"
                v-model="newLab"
                @keydown.enter="addLab"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                fab
                small
                elevation="0"
                :disabled="!newLab || laboratory.includes(newLab)"
                color="primary"
                @click="addLab"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Routes d'administration</b></p>
						</v-col>
            <v-col cols="6" class="text-start">
             <v-chip-group column active-class="primary--text">
              <v-chip
                :key="item"
                v-for="item in administration_route"
                close
                label
                @click:close="removeAdminRoute(item)"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
						</v-col>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                outlined
                dense
                label="Route d'administration"
                v-model="newAdminRoute"
                @keydown.enter="addAdminRoute"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                fab
                small
                elevation="0"
                :disabled="!newAdminRoute || administration_route.includes(newAdminRoute)"
                color="primary"
                @click="addAdminRoute"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-end align-self-center">
              <span class="primary--text align-self-center font-weight-bold">Sources</span>
            </v-col>
            <v-col cols="10">
              <v-chip-group column>
                <v-chip
                  :key="item"
                  v-for="item in sources"
                  :color="sources_details.find(x => x.name == item).color"
                  :href="sources_details.find(x => x.name == item).url"
                  link
                >
                  {{ item }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="2" class="ml-4">
							<v-switch
								v-model="commercialised"
								hide-details
                label="Commercialisé"
								@change="update_field('commercialised', commercialised, route, fieldsDict['commercialised'])"
							></v-switch>
						</v-col>
            <v-col cols="2" offset="3">
							<v-switch
								v-model="agrement_collectivites"
								hide-details
                label="Agrément collectivités"
								@change="update_field('agrement_collectivites', agrement_collectivites, route, fieldsDict['agrement_collectivites'])"
							></v-switch>
						</v-col>
            <v-spacer></v-spacer>
            <v-col v-if="label === 'Vaccine'" cols="2" class="mr-4">
							<v-switch
								v-model="compulsory"
								hide-details
                label="Obligatoire"
								@change="update_field('compulsory', compulsory, route, fieldsDict['compulsory'])"
							></v-switch>
						</v-col>
            <v-col cols="12"><span class="primary--text font-size--13">Données mises à jour le {{ last_updated }}</span></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0" v-if="!launched">
						<v-skeleton-loader
							type="article, table-tfoot"
						></v-skeleton-loader>
					</v-card>
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-else>
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
						<v-card-text>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    :disabled="!prChanged"
                    text
                    color="blue darken-1"
                    @click="save_pr(prerequisites, route); prChanged=false"
                  > Enregistrer </v-btn>
                </v-col>
              </v-row>
						</v-card-text>
					</v-card>
        </v-col>
        <Recommendations
            v-if="loaded"
            :launched.sync="launched"
            :cis_code.sync="cis_code"
        />
      </v-row>
    </v-col>
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'Drug'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";
import Recommendations from "@/components/Utils/Recommendations";

export default {
  name: "DrugDetails",
  components: {
    Recommendations,
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function,
    route: String,
    label: String
  },
  data: () => ({
    launched: false,
    loaded: false,
    rel_data: null,
    name: null,
    cip13_code: null,
    cis_code: null,
    atc_code: null,
    price_ht: null,
    refund: null,
    agrement_collectivites: null,
    compulsory: null,
    brand: null,
    presentation: null,
    pharmaceutical_form: null,
    administration_route: null,
    laboratory: null,
    commercialised: null,
    commercialisation_date: null,
    prescription_conditions: null,
    type: null,
    source: null,
    last_updated: null,
    prerequisites: [],
    prChanged: false,
    updated_by: null,
    updated_at: null,
    newLab: null,
    newAdminRoute: null,
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
    },
    sources_details: [
      { name: "Open Medic", url: "https://www.data.gouv.fr/en/datasets/open-medic-base-complete-sur-les-depenses-de-medicaments-interregimes/", color: "#4b75f8" },
      { name: "BDPM", url: "https://base-donnees-publique.medicaments.gouv.fr/", color: "#d57835" },
    ],
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    },
  },
  mounted: function() {
    this.refreshData();
  },
  methods: {
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: this.label
          }
        })
        .then(response => {
          let node_data = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.name = node_data.name_fr.value;
          this.cip13_code = node_data.cip13_code.value;
          this.cis_code = node_data.cis_code.value;
          this.atc_code = node_data.atc_code.value == null ? '' : node_data.atc_code.value;
          this.price_ht = node_data.price_ht.value === null ? '' : node_data.price_ht.value;
          this.refund = node_data.refund.value === null ? '' : node_data.refund.value;
          this.agrement_collectivites = node_data.agrement_collectivites.value === null ? false : node_data.agrement_collectivites.value;
          this.brand = node_data.brand.value == null ? '' : node_data.brand.value;
          this.presentation = node_data.presentation.value;
          this.pharmaceutical_form = node_data.pharmaceutical_form.value === null ? '' : node_data.pharmaceutical_form.value;
          this.administration_route = node_data.administration_route.value === null ? [] : node_data.administration_route.value;
          this.laboratory = node_data.laboratory.value === null ? [] : node_data.laboratory.value;
          this.commercialised = node_data.commercialised.value === null ? false : node_data.commercialised.value;
          this.commercialisation_date = node_data.commercialisation_date.value === null ? '' : node_data.commercialisation_date.value;
          this.prescription_conditions = node_data.prescription_conditions.value === null ? '' : node_data.prescription_conditions.value;
          this.type = node_data.type.value === null ? '' : node_data.type.value;
          this.sources = node_data.sources.value;
          this.last_updated = moment(node_data.last_updated.value, 'DD-MM-YYYY').locale("fr").format("ll");
          this.updated_by = node_data.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(node_data.prerequisites.value)
          );
          if (this.label == "Vaccine") {
            this.compulsory = node_data.compulsory.value === null ? false : node_data.compulsory.value;
          }
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    },
    addLab() {
      if (this.newLab === "" || this.laboratory.includes(this.newLab)) {
        return;
      }
      this.laboratory.push(this.newLab);
      this.newLab = "";
    },
    removeLab(item) {
      const index = this.laboratory.indexOf(item);
      if (index > -1) {
        this.laboratory.splice(index, 1);
      }
    },
    addAdminRoute() {
      if (this.newAdminRoute === "" || this.administration_route.includes(this.newAdminRoute)) {
        return;
      }
      this.laboratory.push(this.newAdminRoute);
      this.newAdminRoute = "";
    },
    removeAdminRoute(item) {
      const index = this.administration_route.indexOf(item);
      if (index > -1) {
        this.administration_route.splice(index, 1);
      }
    },
  }
};
</script>
