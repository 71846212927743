<template>
  <v-container fluid>
    <div v-if="graphLoading" style="width: 100%; text-align: center; padding-top: 15%;">
      <v-progress-circular
        :size="250"
        :width="20"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-else>
<!--      node deprecation message-->
      <v-col v-if="!active && redirect === null" cols="12">
        <v-alert type="error" text>
          <span class="semibold--text font-size--16">Attention NOEUD INACTIF</span>
        </v-alert>
      </v-col>
      <v-col v-else-if="redirect !== null" cols="12">
        <v-alert type="error" text>
          <span class="semibold--text font-size--16">Attention NOEUD DÉPRÉCIÉ, cliquez <a class="bold--text text-decoration-underline red--text" :href="'/graph/' + redirect">ici</a> pour aller vers le nouveau noeud</span>
        </v-alert>
      </v-col>
<!--      graph display-->
      <v-col cols="12">
        <v-app full-height id="graph" class="ma-auto"></v-app>
      </v-col>
    </v-row>

    <ImpactModal
      @impact_maj="toggle_snack($event.message)"
      :uid="impactUid"
      :dialog.sync="impactDialog"
    />
    <v-dialog v-model="$store.state.nodeDialog" max-width="60%">
      <v-card>
        <v-card-title style="word-break: break-word;">
          <span class="primary--text text-h5 mr-2">{{$store.state.nodeName}}</span>
          <span v-if="$store.state.nodeType" class="mt-3 mb-4">
            <v-chip class="mr-1" v-for="type in $store.state.nodeType" :key="type" :color="node_colors[type]">{{type}}</v-chip>
          </span>
        </v-card-title>
        <v-card-text class="primary--text">
          <v-row>
            <span class="ml-3 mr-1 font-weight-bold">uid:</span> {{ $store.state.nodeUID }}
            <span v-if="$store.state.nodeCIM" class="ml-3 mr-1 font-weight-bold">CIM-10:</span> {{ $store.state.nodeCIM }}
            <span v-if="$store.state.nodeCIP13" class="ml-3 mr-1 font-weight-bold">CIP13:</span> {{ $store.state.nodeCIP13 }}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-end">
            <v-btn text color="blue darken-1" :href="'/details/' + $store.state.nodeUID" @click="details()">
              Details
            </v-btn>
            <v-btn text color="blue darken-1" @click="center()" :href="'/graph/' + this.$store.state.nodeUID"> Centrer </v-btn>
            <v-btn text color="blue darken-1" @click="expand_collapse()">
              Étendre / Contracter
            </v-btn>
            <v-btn
              v-if="hasImpact()"
              text
              color="blue darken-1"
              @click="impact()"
            >
              Impact des réponses
            </v-btn>
            <v-btn
              text
              color="error"
              @click="confirmDeletion('node')"
            >
              Supprimer
            </v-btn>
            <v-btn
              text
              color="blue darken-1"
              @click="$store.state.nodeDialog = false"
              >Fermer</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="$store.state.edgeDialog" width="60%" overlay-opacity="0">
      <v-card>
        <v-card-title
          class="px-8 primary white--text"
          style="white-space: normal; word-break: break-word;"
        >
          Lien {{ $store.state.edgeType }}
        </v-card-title>
        <v-card-text v-if="$store.state.edgeLoading" class="pb-0 pt-3">
          <v-skeleton-loader
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-text v-else class="pb-0 pt-3">
          <v-row>
            <v-col cols="12" fluid>
              <v-card outlined>
                <v-row>
                  <v-col cols="12" class="text-start pl-5">
                    <p style="margin: 0;">
                      <b>uid: </b> {{ $store.state.edgeUID }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="$store.state.edgeTag"
                    class="text-start pl-5"
                  >
                    <p style="margin: 0;">
                      <b>tag: </b> {{ $store.state.edgeTag }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="$store.state.edgeDosage"
                    class="text-start pl-5"
                  >
                    <p style="margin: 0;">
                      <b>dosage: </b> {{ $store.state.edgeDosage }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="$store.state.edgeReference"
                    class="text-start pl-5"
                  >
                    <p style="margin: 0;">
                      <b>reference: </b> {{ $store.state.edgeReference }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6" fluid>
              <v-card outlined class="pa-3" height="100%">
                <v-card-text>
                  <p>
                    <b>label de la source: </b
                    >{{ $store.state.sourceData.node_type }}
                  </p>
                  <p>
                    <b>nom de la source: </b>{{ $store.state.sourceData.name }}
                  </p>
                  <p><b>uid de la source: </b>{{ $store.state.sourceUID }}</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" fluid>
              <v-card outlined class="pa-3" height="100%">
                <v-card-text>
                  <p>
                    <b>label de la cible:</b>
                    {{ $store.state.targetData.node_type }}
                  </p>
                  <p>
                    <b>nom de la cible:</b> {{ $store.state.targetData.name }}
                  </p>
                  <p><b>uid de la cible:</b> {{ $store.state.targetUID }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="!$store.state.edgeLoading" class="py-0">
          <v-col class="text-end">
            <v-btn outlined color="error" @click="confirmDeletion('edge')">
              Supprimer
            </v-btn>
            <v-btn
              elevation="0"
              class="ma-1"
              color="secondary"
              @click="$store.state.edgeDialog = false"
              >Fermer
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="20%">
      <v-card class="error--text text-center pt-3">
        <h3>Supprimer ?</h3>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn @click="confirmDialog = false" outlined color="error">
              Non
            </v-btn>
            <v-btn
              v-if="deleteType === 'node'"
              @click="deleteNode()"
              :loading="removeLoading"
              color="error"
              class="white--text ma-1"
            >
              Oui
            </v-btn>
            <v-btn
              v-if="deleteType === 'edge'"
              @click="deleteEdge()"
              :loading="removeLoading"
              color="error"
              class="white--text ma-1"
            >
              Oui
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar bottom v-model="$store.state.snackbar" timeout="2000">
      {{ $store.state.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import { display_graph } from "@/data/graph.js";
import { expand_collapse_node } from "@/data/graph.js";
import ImpactModal from "@/components/Dialog/ImpactModal";

export default {
  name: "Graph",
  components: {
    ImpactModal
  },
  data: () => ({
    graph: null,
    uid: null,
    graphLoading: true,
    loading: true,
    impactUid: "",
    impactDialog: false,
    confirmDialog: false,
    active: true,
    deleteType: "",
    redirect: null,
    node_colors: {
      MasterQuestion: "#ab2f26",
      Question: "#e39e6e",
      Answer: "#f8bcd0",
      Symptom: "#badf55",
      RiskFactor: "#f44336",
      Group: "#66d7e5",
      Disease: "#d8d8d8",
      ClinicalExam: "#764ebe",
      MedicalExam: "#ff9800",
      MedicalExamResult: "#CC5500",
      Bacteria: "#ffeb3b",
      Virus: "#8c97d3",
      Parasite: "#607d8b",
      Surgery: "#795548",
      Vaccine: "#3f51b5",
      Drug: "#2F71B3",
      DCI: "#E47760",
      Compound: "#809A6F",
      GenericGroup: "#857E7B",
      Atc1AnatomicalGroup: "#f4f1de",
      Atc2TherapeuticSubgroup: "#e07a5f",
      Atc3PharmacologicalSubgroup: "#3d405b",
      Atc4ChemicalSubgroup: "#81b29a",
      Atc5ChemicalSubstance: "#f2cc8f",
      TherapeuticClass: "#8BBF9F",
      TherapeuticArea: "#D6BA73",
    },
  }),
  watch: {
    $route(to, from) {
      this.$router.go();
    }
  },
  mounted: function() {
    let id_redirect_graph;
    let response_graph;
    this.graphLoading = true;
    axios
      .get("/api/mkg/graph/" + this.$route.params.uid, {})
      .then(response => {
        id_redirect_graph = response.data.id_node;
        response_graph = response.data.graph;
        this.graphLoading = false;
        this.active = response.data.is_active;
        this.redirect = response.data.redirect;
      })
      .catch(() => {
        id_redirect_graph = "";
      })
      .finally(() => {
        if (id_redirect_graph !== "") {
          if (response_graph.stairs)
            display_graph(response_graph, response_graph.stairs);
          else display_graph(response_graph, null);
        }
      });
  },
  methods: {
    center() {
      this.$store.state.nodeDialog = false;
    },
    expand_collapse() {
      this.$store.state.nodeDialog = false;
      expand_collapse_node();
    },
    details() {
      this.$store.state.nodeDialog = false;
    },
    hasImpact() {
      return (
        this.$store.state.nodeLabels.includes("Question") ||
        this.$store.state.nodeLabels.includes("Answer")
      );
    },
    impact() {
      if (this.$store.state.nodeLabels.includes("Question")) {
        this.impactUid = this.$store.state.nodeUID;
        this.impactDialog = true;
        this.$store.state.nodeDialog = false;
      } else {
        axios
          .get("/api/answers/" + this.$store.state.nodeUID, {})
          .then(response => {
            let question_uid = response.data.question.uid;
            this.impactDialog = true;
            this.impactUid = question_uid;
          })
          .catch(error => {
            this.$router.push("/details/" + this.$store.state.nodeUID);
          })
          .finally(() => {
            this.$store.state.nodeDialog = false;
          });
      }
    },
    confirmDeletion(type) {
      this.deleteType = type;
      this.confirmDialog = true;
      this.$store.state.nodeDialog = false;
    },
    deleteNode() {
      this.removeLoading = true;
      axios
        .delete("/api/mkg/node/" + this.$store.state.nodeUID, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          location.reload();
          this.$store.state.nodeDialog = false;
          this.$store.state.message = "Le noeud a bien été supprimé";
        })
        .catch(error => {
          this.$store.state.message = error.response.data.detail
        })
        .finally(() => {
          this.removeLoading = false;
          this.confirmDialog = false;
          this.deleteType = "";
          this.$store.state.snackbar = true;
        });
    },
    deleteEdge() {
      this.removeLoading = true;
      axios
        .delete("/api/mkg/edge/" + this.$store.state.edgeUID, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          location.reload();
          this.$store.state.edgeDialog = false;
          this.$store.state.message = "Le lien a bien été supprimé";
        })
        .catch(error => {
          this.$store.state.message = error.response.data.detail;
        })
        .finally(() => {
          this.removeLoading = false;
          this.confirmDialog = false;
          this.deleteType = "";
          this.$store.state.snackbar = true;
        });
    },
    toggle_snack(event) {
      this.$store.state.message = event;
      this.$store.state.snackbar = true;
    }
  }
};
</script>
