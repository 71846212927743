<template>
  <v-container class="pa-1 pa-md-10">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" xl="8" offset-xl="2">
        <v-card flat class="bg_primary">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" v-for="(page, index) in pages" :key="index">
                <v-hover v-slot:default="{ hover }">
                  <v-card elevation="1" height="200" hover class="ma-3" :disabled="page.disabled" @click="redirect_to_page(page.link)">
                    <v-card-title class="justify-center" style="height: 65%;">
                      <img :src="page.img" style="max-width: 100%; max-height: 100%" />
                    </v-card-title>
                    <v-card-actions class="justify-center pb-5">
                      <v-btn small color="secondary" elevation="0" absolute bottom>
                        <span class="font-size--14 px-1 font-weight-medium">
                          {{ page.name }}
                        </span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import src_cases from "@/assets/cases.png";
import src_diagnosis from "@/assets/diagnosis.png";
import src_ml from "@/assets/machine_learning.png";
import src_maintenance from "@/assets/maintenance.png";
import src_questionnaire from "@/assets/questionnaire.png";
import src_atc from "@/assets/atc.png";
import src_changes from "@/assets/recent_changes.png";
import src_translation from "@/assets/translation.png";
import axios from "axios";

export default {
  name: "HomeQuestionnaireView",
  data: () => ({
    overlay: false,
    pages: [
      {name: "Cas Cliniques", img: src_cases, link: "/test_cases", disabled: false},
      {name: "Questionnaires", img: src_questionnaire, link: "/questionnaires", disabled: false},
      {name: "Classification ATC", img: src_atc, link: "/atc_classification", disabled: false},
      {name: "Diagnostic Différentiel", img: src_diagnosis, link: "/differential_diagnosis", disabled: false},
      {name: "Démo Machine Learning", img: src_ml, link: "/machine_learning", disabled: false},
      {name: "Maintenance", img: src_maintenance, link: "/maintenance", disabled: false},
      {name: "MAJ Récentes", img: src_changes, link: "/recent_changes", disabled: false},
      {name: "Traduction", img: src_translation, link: "/translation", disabled: false},
    ]
  }),
  mounted: function() {
    this.call_global_variables();
    this.set_scoring_names();
  },
  methods: {
    redirect_to_page(link){
      this.overlay = true;
      this.$router.push(link);
    },
    call_global_variables() {
      axios
        .get("/api/mkg/call_all_global_variables", {})
        .then(response => {
          for (const el in response.data) {
            localStorage.setItem(el, JSON.stringify(response.data[el]));
          }
        })
        .catch(error => {
          this.manageError(error);
        });
    },
    set_scoring_names() {
      axios
        .get("/api/mkg/manage_scoring", {})
        .then(response => {
          this.$store.commit("setScoringNames", response.data);
        });
    },
    manageError(error) {
      if (error.response) {
        this.$store.dispatch("ManageError", error.response.status);
      } else {
        this.$store.commit("PostError", "Une erreur est survenue");
      }
    },
  },
}
</script>
