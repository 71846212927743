import store from "@/store/index.js";
import router from "@/router/index.js";

import axios from "axios";
import cytoscape from "cytoscape";

window.mode_display = "graph";
window.graph = { nodes: [], edges: [] };

let graph = { nodes: [], edges: [] };
let expanded = [];
let cy = null;
let data_to_display;
let rules = [];

export function expand_collapse_node() {
	axios.get("/api/mkg/node/" + store.state.nodeUID, {
      params: {
        graph: true
      }
    }).then(response => {
      response.data.graph.nodes.forEach(element => {
        element.data.short = truncate(element.data.caption, 50);
        element.data.opacity = element.data.is_active ? 1 : 0.3
      });

      if (store.state.nodeUID !== router.history.current.params.uid && expanded.includes(store.state.nodeUID)) {
        let main_edges = cy.$('#'+router.history.current.params.uid).connectedEdges();
        let expanded_edges = cy.$('#'+store.state.nodeUID).connectedEdges();
        cy.remove(expanded_edges.difference(main_edges))
        expanded = expanded.filter(item => item !== store.state.nodeUID);
      } else {
        for (let el in response.data.graph.nodes) {
          if (!cy.$("#" + response.data.graph.nodes[el].data.id).length) {
            cy.add([{
              group: "nodes",
              data: response.data.graph.nodes[el].data
            }]);
          }
        }
        for (let el in response.data.graph.edges) {
          if (!cy.$("#" + response.data.graph.edges[el].data.id).length) {
            cy.add([{
              group: "edges",
              data: response.data.graph.edges[el].data
            }]);
          }
        }
        expanded.push(store.state.nodeUID);
      }
    }).finally(() => {
      compute_node_position(cy);
    }).catch(error => {
      store.state.message = error;
      store.state.snackbar = true;
      store.state.nodeDialog = false;
    });
}

export function display_graph(response, stairs) {
  expanded.push(router.history.current.params.uid);

  response.graph.nodes.forEach(element => {
    element.data.short = truncate(element.data.caption, 50);
    element.data.opacity = element.data.is_active ? 1 : 0.4
  });

  cy = cytoscape({
    container: document.getElementById("graph"),
    elements: response.graph,
    style: define_graph_style(),
  });

  cy.on("tap", "node", function(evt) {
    store.state.nodeDialog = true;
    store.state.nodeLabels = evt.target.data("type");
    store.state.nodeType = evt.target.data("tag_ind");
    store.state.nodeName = evt.target.data("caption");
    store.state.nodeUID = evt.target.data("id");
    store.state.nodeCIM = evt.target.data("icd10_id");
    store.state.nodeCIP13 = evt.target.data("cip13_code");
    store.state.nodeActive = evt.target.data("is_active");
  });

  cy.on("tap", "edge", function(evt) {
    store.state.edgeUID = evt.target.data("properties").uid;
    store.state.sourceUID = evt.target.data("source");
    store.state.targetUID = evt.target.data("target");
    get_edge_data();
  });

  graph = { nodes: [], edges: [] };
  if (stairs) {
    graph.nodes = response.graph.nodes;
    graph.edges = response.graph.edges;
  }
  get_visualisation(response, stairs);
}

function get_edge_data(){
  store.state.edgeLoading = true;
  store.state.edgeDialog = true;
	axios
    .get("/api/mkg/edge/" + store.state.edgeUID, {
      params: {
        id_source: store.state.sourceUID,
        id_target: store.state.targetUID
      }
    })
    .then(response => {
      store.state.sourceData = response.data.source;
      store.state.targetData = response.data.target;
      store.state.edgeTag = response.data.info_link.tag;
      store.state.edgeDosage = response.data.info_link.dosage;
      store.state.edgeReference = response.data.info_link.reference;
      store.state.edgeType = response.data.info_link.type;
      store.state.edgeLoading = false;
    })
    .catch(error => {
      store.state.message = "Un problème est survenu lors de la récupération des données";
      store.state.snackbar = true;
    });
}

function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

function get_visualisation(data, stairs=false) {
  data_to_display = data.graph;

  window.localStorage.setItem("node_type", data_to_display.nodes[0].data.type);

  if (stairs) {
    // Cosine look
    let offset = 30;
    let w = 500
    let h = 200 - offset;
    let num_data = data_to_display.nodes.length;

    for (let i in data_to_display.nodes) {
      let posX = ((w * i) / num_data + offset) / 2 + w / 4;
      let up_down = i % 2 === 0 ? num_data : 0;
      let posY = ((h * up_down) / num_data + offset) / 2 + h / 5;
      cy.$("#" + data_to_display.nodes[i].data.id).position({
        x: posX,
        y: posY
      });
    }
  }
  if (!stairs) {
    compute_node_position(cy);
  }
  cy.makeLayout({ name: "preset" }).run();
}

function compute_node_position(cy) {
  let parent_node_id = get_parent_nodes(cy);
  let parent_node_list = [];
  for (let j = 0; j < rules.length; j++) {
    rules[j].destroy();
  }
  for (let i = 0; i < parent_node_id; i++) {
    let theta = (360 / parent_node_id) * i * (Math.PI / 180);
    let posX = 5 * cy.nodes().length * Math.cos(theta);
    let posY = 5 * cy.nodes().length * Math.sin(theta);
    cy.$(".parent_node" + i).position({
      x: posX,
      y: posY
    });
    parent_node_list.push({
      id: cy.$(".parent_node" + i)[0]._private.data.id,
      x: posX,
      y: posY
    });

    rules.push(
      cy.automove({
        nodesMatching: cy
          .$(".parent_node" + i)
          .neighborhood()
          .nodes(".child_node"),
        reposition: "drag",
        dragWith: cy.$(".parent_node" + i)
      })
    );
  }
  let index_array = [];
  if (cy.nodes().length > 3) {
    for (let i = 0; i < cy.nodes().length; i++) {
      let selected_node = cy.nodes()[i];
      if (selected_node.hasClass("child_node")) {
        if (selected_node.neighborhood().nodes(".parent_node").length === 1) {
          let my_parent_node_id = selected_node
            .neighborhood()
            .nodes(".parent_node")[0]._private.data.id;
          let my_parent_node = parent_node_list.find(
            x => x.id === my_parent_node_id
          );
          let latest_iteration = 0;
          if (
            index_array.find(
              x =>
                x.id ===
                selected_node.neighborhood().nodes(".parent_node")[0]._private
                  .data.id
            ) === undefined
          ) {
            latest_iteration = 0;
          } else {
            latest_iteration =
              index_array.find(
                x =>
                  x.id ===
                  selected_node.neighborhood().nodes(".parent_node")[0]._private
                    .data.id
              ).latest_iteration + 1;
          }
          index_array.unshift({
            id: selected_node.neighborhood().nodes(".parent_node")[0]._private
              .data.id,
            latest_iteration: latest_iteration
          });
          let theta =
            (360 /
              selected_node
                .neighborhood()
                .nodes(".parent_node")
                .degree()) *
              latest_iteration *
              (Math.PI / 180) + 5;
          let radius =
            5 *
            selected_node
              .neighborhood()
              .nodes(".parent_node")
              .degree();
          if (
            selected_node
              .neighborhood()
              .nodes(".parent_node")
              .degree() < 10
          ) {
            radius = 75;
          }
          selected_node.position({
            x: my_parent_node.x + radius * Math.cos(theta),
            y: my_parent_node.y + radius * Math.sin(theta)
          });
        } else {
          let mean_x = 0;
          let mean_y = 0;
          for (
            let j = 0;
            j < selected_node.neighborhood().nodes(".parent_node").length;
            j++
          ) {
            let my_parent_node_id = selected_node
              .neighborhood()
              .nodes(".parent_node")[j]._private.data.id;
            let my_parent_node = parent_node_list.find(
              x => x.id === my_parent_node_id
            );
            mean_x = mean_x + my_parent_node.x;
            mean_y = mean_y + my_parent_node.y;
          }
          mean_x = mean_x / selected_node.neighborhood().nodes(".parent_node").length;
          mean_y = mean_y / selected_node.neighborhood().nodes(".parent_node").length;
          selected_node.position({
            x: mean_x + 100 * Math.random(),
            y: mean_y + 100 * Math.random()
          });
        }
      }
    }
  } else {
    for (let i = 0; i < cy.nodes().length; i++) {
      let theta = (360 / cy.nodes().length) * i * (Math.PI / 180);
      cy.nodes()[i].position({
        x: 50 * Math.cos(theta),
        y: 50 * Math.sin(theta)
      });
    }
  }
  set_colors_of_nodes(cy);
  cy.fit()
}

function get_parent_nodes(cy) {
  let parent_node_id = 0;
  let dict_parent_node = [];
	let duplicates_list = [];
  for (let i = 0; i < cy.nodes().length; i++) {
    let selected_node = cy.nodes()[i];
    selected_node.classes("");
    let duplicates = false;
    if (duplicates_list.includes(selected_node._private.data.id)) {
      duplicates = true;
		}
    if (duplicates === false) {
      if (expanded.includes(selected_node._private.data.id)) {
        selected_node.addClass("parent_node" + parent_node_id);
        selected_node.addClass("parent_node");
        dict_parent_node.push({ id: selected_node._private.data.id });
        duplicates_list.push(selected_node._private.data.id);
        parent_node_id = parent_node_id + 1;
      } else {
        selected_node.addClass("child_node");
      }
    }
  }
  localStorage.setItem("parent_nodes", JSON.stringify(dict_parent_node));
  return parent_node_id;
}

function set_colors_of_nodes(cy) {
  let node_colors = {
    MasterQuestion: "#ab2f26",
    Question: "#e39e6e",
    Answer: "#f8bcd0",
    Symptom: "#badf55",
    RiskFactor: "#f44336",
    Group: "#66d7e5",
    Disease: "#d8d8d8",
    ClinicalExam: "#764ebe",
    MedicalExam: "#ff9800",
    MedicalExamResult: "#CC5500",
    Bacteria: "#ffeb3b",
    Virus: "#8c97d3",
    Parasite: "#607d8b",
    Surgery: "#795548",
    Vaccine: "#3f51b5",
    Drug: "#2F71B3",
    DCI: "#E47760",
    Compound: "#809A6F",
    GenericGroup: "#857E7B",
    Atc1AnatomicalGroup: "#f4f1de",
    Atc2TherapeuticSubgroup: "#e07a5f",
    Atc3PharmacologicalSubgroup: "#3d405b",
    Atc4ChemicalSubgroup: "#81b29a",
    Atc5ChemicalSubstance: "#f2cc8f",
    TherapeuticClass: "#8BBF9F",
    TherapeuticArea: "#D6BA73",
  };
  cy.nodes().filter(function(ele) {
    let labels = ele.data("type");
    if (labels.length > 1) {
      let dict_pie = {};
      for (let i in labels) {
        let pie_number = parseInt(i) + 1;
        dict_pie["pie-" + pie_number + "-background-color"] =
          node_colors[labels[i]];
        dict_pie["pie-" + pie_number + "-background-size"] =
          (100 / labels.length).toString() + "%";
      }
      ele.css(dict_pie);
    } else {
      ele.css("background-color", node_colors[labels[0]]);
    }
  });
}

function define_graph_style() {
  return [
    {
      selector: "core",
      style: {
        'active-bg-size': 0,
      }
    },
    {
      selector: "node",
      style: {
        "label": "data(short)",
        "font-size": "4px",
        "text-valign": "center",
        "text-wrap": "wrap",
        "text-max-width": 25,
        "overlay-shape": "ellipse",
        "overlay-color": "#B4D5FE",
        "overlay-padding": 1,
        "opacity": "data(opacity)",
      }
    },
    {
      selector: "edge",
      style: {
        "width": "0.5px",
        "label": "data(type)",
        "text-background-color": "white",
        "color": "data(color)",
        "text-background-opacity": "1",
        "font-size": "4px",
        "text-rotation": "autorotate",
        "curve-style": "bezier",
        "target-arrow-shape": "triangle",
        "line-color": "data(color)",
        "target-arrow-color": "data(color)",
        "arrow-scale" : 0.5,
        "overlay-color": "#B4D5FE",
        "overlay-padding": 1,
      }
    },
  ];
}
