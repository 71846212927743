<template>
  <v-row class="mt-10">
    <v-col cols="10" offset="1">
      <v-card flat class="bg rounded-lg" height="100%">
        <v-card-title class="text-h4 primary--text">Ajout d'un questionnaire</v-card-title>
        <v-form v-model="form_valid">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-file-input
                    v-model="questionnaire_file"
                    label="Sélectionnez un fichier d'import"
                    accept="text/csv"
                    filled
                    show-size
                    hide-details
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="username"
                    label="Nom d'utilisateur mkg"
                    filled
                    show-size
                    hide-details
                    prepend-icon=""
                    append-icon="mdi-account"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="meta_questionnaire_name"
                    label="Meta Questionnaire name"
                    filled
                    show-size
                    hide-details
                    prepend-icon=""
                    append-icon="mdi-tag"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="commercial_name"
                    label="Commercial name"
                    filled
                    show-size
                    hide-details
                    prepend-icon=""
                    append-icon="mdi-pencil"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="">
            <v-spacer></v-spacer>
            <v-btn :disabled="!form_valid"
                   elevation="0"
                   color="primary"
                   text
                   class="rounded-2 text-break"
                   :loading="loading"
                   @click="validate_import">
              <span class="font-size--20"><v-icon large dense>mdi-upload</v-icon>Créer le questionnaire</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <v-snackbar v-model="response_questionnaire" width="30%">
      <span>{{ response_content }}</span>
    </v-snackbar>
  </v-row>
</template>

<script>

import rules from "anam-library/src/js/rules";

export default {
  name: 'AddQuestionnaireView',
  data: () => ({
    rules: rules,
    form_valid: false,
    questionnaire_file: null,
    username: null,
    meta_questionnaire_name: null,
    commercial_name: null,
    loading: false,
    response_questionnaire: false,
    response_content: "",
  }),
  methods: {
    validate_import() {
      let formData = new FormData()
      this.questionnaire_file !== null ? formData.append("questionnaire_file", this.questionnaire_file) : null;
      this.username !== null ? formData.append("username", this.username) : null;
      this.meta_questionnaire_name !== null ? formData.append("meta_questionnaire_name", this.meta_questionnaire_name) : null;
      this.commercial_name !== null ? formData.append("commercial_name", this.commercial_name) : null;
      this.loading = true;
      formData.append("medical_classification", "AUTRE");
      this.$http.post('/api/mkg/import_questionnaire', formData).then(response => {
          this.response_questionnaire = true;
          this.response_content = response.data;
        }).finally(() => {
          this.loading = false;
      })
    },
  }
}

</script>

<style scoped>

</style>