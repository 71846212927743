<template>
  <v-col cols="12">
    <v-card elevation="2" v-if="!launched" class="mr-4 mt-4 mb-0">
      <v-skeleton-loader type="article, table-tfoot"></v-skeleton-loader>
    </v-card>
    <v-card elevation="2" class="mr-4 mb-0" v-else>
      <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Synonymes</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="10" fluid>
            <v-text-field
              :counter="100"
              :rules="[synRules]"
              label="Synonyme"
              v-model="newSynonym"
              hint="Ajouter un synonyme au symptôme"
              persistent-hint
              @keydown.enter="addSynonym"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn
              fab
              small
              :disabled="!newSynonym || synonyms.includes(newSynonym) || newSynonym.length > 100"
              color="primary"
              @click="addSynonym"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <p
              class="primary--text"

            >
              <b>Liste des synonymes :</b>
            </p>
            <v-card outlined class="px-2 py-0">
              <v-card-text class="pa-0">
                <p v-if="synonyms.length == 0" label class="my-2">
                  Pas de synonymes pour l'instant
                </p>
                <v-chip-group v-else column active-class="primary--text">
                  <v-chip
                    :key="item"
                    v-for="item in synonyms"
                    close
                    label
                    @click:close="removeSynonym(item)"
                  >
                    {{ item }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "Synonyms",
  props: {
    synonyms: Array,
    loaded: Boolean,
    launched: Boolean,
    update_field: Function
  },
  data: () => ({
    synonyme_route: "/api/manage_synonyms/",
    newSynonym: null,
  }),
  watch: {
    synonyms(value) {
      if (this.loaded) {
        this.update_field(
          "synonyme",
          value,
          this.synonyme_route,
          "des synonymes"
        );
      }
    }
  },
  methods: {
    addSynonym() {
      if (this.newSynonym === "" || this.synonyms.includes(this.newSynonym) || this.newSynonym.length > 100) {
        return;
      }
      this.synonyms.push(this.newSynonym);
      this.newSynonym = null;
    },
    removeSynonym(item) {
      const index = this.synonyms.indexOf(item);
      if (index > -1) {
        this.synonyms.splice(index, 1);
      }
    },
    synRules(value) {
      if (value && this.newSynonym.length > 100) {
        return "Max 100 caractères";
      } else if (value && this.synonyms.includes(this.newSynonym)) {
        return this.newSynonym + " est deja dans la liste";
      } else return true;
    }
  }
};
</script>