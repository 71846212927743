<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_primary" v-if="load" height="100%">
        <v-card-title class="text-h4 primary--text">
          <v-progress-circular
            indeterminate
            color="white"
            class="mr-5"
          ></v-progress-circular>
          Récupération des données
        </v-card-title>
        <v-card-text class="mt-3">
          <v-skeleton-loader type="paragraph@30"></v-skeleton-loader>
        </v-card-text>
      </v-card>
      <v-card flat class="bg_primary">
        <v-card-title class="text-h4 primary--text">
          Noeuds Modifés
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <div class="mb-15">{{ nodeList.length }} nœuds durant les 30 derniers jours</div>
          <v-data-table
            :headers="headers"
            :items="nodeList"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            @click:row="openChangesDetails($event)"
            :footer-props="{
              'items-per-page-options': [300]
            }"
            :items-per-page="300"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="4">
                  <v-text-field class="ma-4" v-model="search" outlined append-icon="mdi-magnify" label="Recherche"></v-text-field>
                </v-col>
              </v-row>
            </template>
<!--            actions header-->
            <template v-slot:item.links="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click.stop="on" :href="'/graph/' + item.uid">
                    <v-icon>mdi-share-variant-outline</v-icon>
                  </v-btn>
                </template>
                <span>graph</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click.stop="on" :href="'/details/' + item.uid">
                    <v-icon>mdi-card-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>details</span>
              </v-tooltip>
            </template>
<!--            name header-->
            <template v-slot:item.name="{ item }">
              <div class="black--text text-sm-body-2" >
                <v-chip :key="label" v-for="label in item.labels.filter(label => nodeTypesDict[label])"
                        small
                        :color="nodeTypesDict[label].color"
                        class="white--text mx-0 pa-1"
                >
                  {{ nodeTypesDict[label].symbol }}
                </v-chip>
                {{ truncate(item.name, 100) }}
              </div>
            </template>
<!--            uid header-->
            <template v-slot:item.uid="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.uid }}</div>
            </template>
<!--            icd10 header-->
            <template v-slot:item.icd10_id="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.icd10_id? item.icd10_id: '-'}}</div>
            </template>
<!--            relations header-->
            <template v-slot:item.relations="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.relations }}</div>
            </template>
<!--            updated at-->
            <template v-slot:item.updated_at="{ item }">
              <div class="black--text text-sm-body-2" >{{ date_format(item.updated_at)}}</div>
            </template>
            <!--            updated by-->
            <template v-slot:item.updated_by="{ item }">
              <div class="black--text text-sm-body-2" >{{ item.updated_by.split('@')[0] }}</div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
<!--    changes dialog popup-->
    <v-dialog v-model="dialog" max-width="80%">
      <v-card v-if="dialog" v-click-outside="close">
        <v-card-title class="primary--text text-h5 font-weight-bold mb-4">
          Changements effectués
        </v-card-title>
        <v-card-text>
<!--          Headers-->
          <v-row>
            <v-col cols="2"><p class="primary--text"><b>Type</b></p></v-col>
            <v-col cols="2"><p class="primary--text"><b>Propriété modifiée</b></p></v-col>
            <v-col cols="8"><p class="primary--text"><b>Modification</b></p></v-col>
          </v-row>
<!--          Changes-->
          <div v-for="diff in differences">
<!--            format specific for change type-->
            <v-row v-if="diff[0] === 'change'">
              <v-col cols="2"><span class="black--text">{{ diff[0] }}</span></v-col>
              <v-col cols="2"><span class="black--text">{{ diff[1] }}</span></v-col>
              <v-col cols="4"><span class="error--text"> avant: "{{ diff[2][0] }}"</span></v-col>
              <v-col cols="4"><span class="success--text"> après: "{{ diff[2][1] }}"</span></v-col>
            </v-row>
<!--            format specific for add type-->
            <div v-if="diff[0] === 'add'">
              <v-row key="aditions[0]" v-for="additions in diff[2]">
                <v-col cols="2"><span class="black--text">{{ diff[0] }}</span></v-col>
                <v-col cols="2"><span class="black--text">{{ additions[0] }}</span></v-col>
                <v-col cols="4"><span class="error--text">avant: ""</span></v-col>
                <v-col cols="4"><span class="success--text">après: "{{ additions[1] }}"</span></v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          <v-spacer></v-spacer>
          <!--            close changes popup without any action-->
          <v-btn color="blue darken-1" text @click="close">
            Annuler
          </v-btn>
          <!--            reset the last modifications from backup-->
          <v-btn color="error" text @click="reset_from_backup()">
            Réinitialiser les modifications
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {getCookie} from "@/data/utils";

export default {
  name: "DisplayNodes",

  data: () => ({
    load: true,
    nodeType: "",
    tab: "0",
    search: "",
    uid: null,
    loading: false,
    dialog: false,
    differences: [],
    nodeList: [],
    headers: [
      { text: 'Liens', value: 'links', class: 'primary--text', sortable: false },
      { text: "Nom du nœud", value: "name", class: 'primary--text', align: 'start'},
      { text: "uid", value: "uid", class: 'primary--text'},
      { text: "CIM-10", value: "icd10_id", class: 'primary--text'},
      { text: "Liens", value: "relations", class: 'primary--text'},
      { text: "MAJ", value: "updated_at", class: 'primary--text'},
      { text: "Auteur", value: "updated_by", class: 'primary--text'}
    ],
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
  }),
  created: function() {
    axios.get(
        "/api/mkg/recent_changes",
    ).then(response => {
      this.nodeList = response.data.item_list;
    }).catch(error => {
      this.$store.dispatch("ManageError", error.response.status)
    }).finally(() => {
      this.load = false;
    });
  },
  methods: {
    truncate(str, n){
      return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    },
    date_format(date) {
      return moment(date, "YYYYMMDDHHmm").format("YYYY-MM-DD h:mma")
    },
    openChangesDetails(event) {
      this.dialog = true;
      this.uid = event.uid;
      axios.get(
          'api/mkg/backup/' + event.uid
      ).then(response => {
        this.differences = response.data;
      }).catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      });
    },
    reset_from_backup() {
      axios.patch(
          'api/mkg/backup/' + this.uid, {}, { headers: { "X-CSRFToken": getCookie("csrftoken") } }
      ).catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      }).finally( () => {
        this.uid = null;
        this.dialog = false;
        this.differences = [];
      });
    },
    close() {
      this.dialog = false;
      this.uid = null;
      this.differences = [];
    },
  }
};
</script>
