<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="12" offset-xl="0">
      <v-card flat class="bg_light" v-if="load" height="100%">
        <v-card-title class="text-h4 primary--text">
          <v-progress-circular
            indeterminate
            color="white"
            class="mr-5"
          ></v-progress-circular>
          Récupération des données
        </v-card-title>
        <v-card-text class="mt-3">
          <v-skeleton-loader type="paragraph@30"></v-skeleton-loader>
        </v-card-text>
      </v-card>
      <v-card flat class="bg_light">
        <v-card-title class="text-h4 primary--text d-flex">
          Liste des Questionnaires
          <v-btn elevation="0" class="text-right text-break" @click="add_questionnaire()">
            <p class="mt-5">Ajouter un questionnaire <v-icon large>mdi-upload</v-icon> </p>
          </v-btn>
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <div class="mb-15">{{ nodeList.length }} questionnaires</div>
          <v-data-table
            :headers="headers"
            :items="nodeList"
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :footer-props="{
              'items-per-page-options': [300]
            }"
            :items-per-page="300"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="search"
                    outlined
                    append-icon="mdi-magnify"
                    label="Recherche"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.entry_point`]="{ item }">
              <v-btn color="blue" link text small :href="'/details/'+item.entry_point">
                {{ item.entry_point }}
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn v-if="item.entry_point" icon small @click="download_questionnaire(item)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn v-else disabled icon small @click="download_questionnaire(item)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar class="pa-0"
      bottom
      v-model="snackData.show"
      :timeout="snackData.timeout"
      :color="snackData.color"
    >
      <v-row>
        <v-col cols="10" class="py-0">
          <p class="pt-1 ma-0">{{ snackData.message }}</p>
        </v-col>
        <v-col cols="2" class="py-0">
          <v-progress-circular
            class="text-end"
            v-if="snackData.loading"
            :size="30"
            :width="3"
            color="white"
            indeterminate
          ></v-progress-circular>
          <v-icon v-else color="white">{{ snackData.icon }}</v-icon>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import generateCsv from "anam-library/src/js/export_csv";
import moment from "moment/moment";


export default {
  name: "QuestionnaireList",
  data: () => ({
    load: true,
    search: "",
    loading: false,
    nodeList: [],
    headers: [
      { text: "Nom", value: "name", class: 'primary--text', align: 'start'},
      { text: "Nom commercial", value: "commercial_name", class: 'primary--text', align: 'start'},
      { text: "Point d'entrée", value: "entry_point", class: 'primary--text', align: 'center'},
      { text: 'Actions', value: 'actions', class: 'primary--text', align: 'center', sortable: false},
    ],
    existingNames: [],
    already_exist: false,
    dialog: false,
    snackData: {
      show: false,
      color: "grey",
      message: "",
      loading: false,
      icon: "",
      timeout: 2500
    }
  }),
  created: function() {
    axios
      .get("/api/mkg/questionnaires", {})
      .then(response => {
        this.nodeList = response.data.item_list;
      })
      .catch(error => {
        this.$store.dispatch("ManageError", error.response.status)
      })
      .finally(() => {
        this.load = false;
        this.nodeList.forEach(obj => this.existingNames.push(obj.name));
      });
  },
  methods: {
    download_questionnaire(item) {
      let pk = item.name
      const filename = moment(new Date())
        .locale("fr")
        .format(`[${pk}]_DD-MM-YYYY`);

      axios.get(`/api/mkg/export_questionnaire/${pk}`).then((response) => {
        generateCsv(filename, response);
      })
      .catch(error => {
        let message = error.response.data.detail;
        this.toggle_snack("error", message, "mdi-close", false, 4000);
      });
    },
    toggle_snack(color, message, icon, loading, timeout) {
      this.snackData.loading = loading;
      this.snackData.icon = icon;
      this.snackData.message = message;
      this.snackData.color = color;
      this.snackData.timeout = timeout;
      this.snackData.show = true;
    },
    add_questionnaire: function() {
      this.$router.push('/questionnaires/add-questionnaire');
    },
  }
};
</script>
