<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="6" offset-xl="0">
      <v-card elevation="3" class="mr-4 ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          Classification ATC
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <v-treeview
              dense
              v-if="loading"
              activatable
              :items="items"
              item-key="uid"
              item-text="name_fr"
              item-children="includes"
              @update:active="getRelevantDrugs($event)"
          >
            <template slot="label" slot-scope="props">
<!--                <router-link :to="'/details/'+props.item.uid" v-if="props.item.route">{{ props.item.name_fr }}</router-link>-->
                <span>{{ props.item.atc_code }} - {{ props.item.name_fr }}</span>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="0" offset-md="0" xl="6" offset-xl="0">
      <v-card elevation="3" class="mr-4 mt-4">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          Médicaments
        </v-card-title>
        <v-card-text class="font-size--16 primary--text">
          <v-data-table
            dense
            :headers="headers"
            :items="drugs"
            item-key="uid"
          >
            <!--            commercialised header-->
            <template v-slot:item.commercialised="{ item }">
              <v-switch v-model="item.commercialised" color="primary" inset readonly dense flat></v-switch>
            </template>
            <!--            actions header-->
            <template v-slot:item.links="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :href="'/graph/' + item.uid">
                    <v-icon>mdi-share-variant-outline</v-icon>
                  </v-btn>
                </template>
                <span>graph</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :href="'/details/' + item.uid">
                    <v-icon>mdi-card-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>details</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "AtcClassification",
  data: () => ({
    loading: false,
    items: null,
    drugs: [],
    headers: [
      { text: 'Raccourcis', value: 'links', class: 'primary--text', sortable: false},
      { text: "Médicament", value: "name", class: 'primary--text', align: 'start'},
      { text: "Commercialisé", value:"commercialised", class: 'primary--text', align: 'start'}
    ]
  }),

  mounted: function () {
    axios.get("/api/drugs/atc_classification")
        .then(response => {
          this.items = response.data;
          this.loading = true;
        });
  },
  methods: {
    getRelevantDrugs(uid) {
      axios.get(`api/drugs/get_drugs_by_atc/${uid[0]}`)
          .then(response => {
            this.drugs = response.data;
          })
    }
  },
}
</script>