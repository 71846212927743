<template>
  <v-app class="bg_primary">
    <v-main>
      <v-container fluid class="pa-3 pa-md-10">
        <v-row class="mx-0" justify="center" align-content="center" style="min-height: 80vh">
          <v-col cols="12" sm="6" md="4" class="pa-sm-6 pr-sm-10 align-self-center">
            <img :src="logo_mkg" width="100%" alt="Logo MKG"/>
            <div style="text-align:center">
              <p class="mt-6 primary--text medium--text font-size--20">
                Connectez-vous pour accéder à votre application
                <span class="font-size--20 bold--text secondary--text">{{ title_app }}</span>
              </p>
            <v-btn color="primary" rounded large class="white--text" @click="initKeycloak()">
              <span class="font-size--16 medium--text">Connexion</span>
            </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import axios from "axios";

export default {
  name: "ConnexionView",
  data: () => ({
    logo_mkg: "https://www.anamnese.care/hubfs/Visuels/Logos%20anamnese/Logo-2022-Anamnese-HD.png",
    title_app: "MKG",
  }),
  methods: {
    initKeycloak() {
      axios.get("/api/check-authentication").then(() => {
        if(this.$store.getters.getUserPermission === 1) this.$router.push({name: "MKG"})
        else this.$keycloak.login()
      }).catch(error => {
        if(error.response && error.response.status === 401) {
          this.$store.commit("setUserPermission", 0);
          this.$keycloak.login()
        }
      })
    },
    connected(data) {
      this.$store.dispatch("connexion", {data: data})
    }
  }
}
</script>
<style scoped>
</style>