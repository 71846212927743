<template>
	<v-row fill-height fluid>
		<v-col cols="12">
				<v-card elevation="0">
						<v-card-title class="pa-0">
								<v-toolbar dark color="primary" flat>
										<v-toolbar-title> {{ title }} </v-toolbar-title>
								</v-toolbar>
						</v-card-title>
						<v-card-text>
								<v-list disabled>
										<v-list-item-group >
												<v-list-item
												:key="item.data"
												v-for="item in items"
												>
														<v-list-item-icon v-if="item.type==='example'">
																<v-icon v-text="'mdi-book-outline'"></v-icon>
														</v-list-item-icon>
														<v-list-item-icon v-else-if="item.type==='warning'">
																<v-icon v-text="'mdi-alert-box'"></v-icon>
														</v-list-item-icon>
														<v-list-item-icon v-else>
																<v-icon v-text="'mdi-border-color'"></v-icon>
														</v-list-item-icon>
														<v-list-item-content>
																<v-list-item-title
																style="white-space: normal;"
																v-text="item.data"></v-list-item-title>
														</v-list-item-content>
												</v-list-item>
										</v-list-item-group>
								</v-list>
						</v-card-text>
				</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "InfoCard",
	props: {
    title: String,
    items: Array,
  },
}
</script>
