<template>
  <v-container style="max-width: 100%;">
    <v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="soft_blue">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="date-picker-days@10"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card v-if="description" color="#BDD5EA" tile class="pa-5">
        <b>{{ description }}</b>
      </v-card>
      <InfoCard v-if="infoList" :title="'Consignes'" :items="infoList" />
      <ChipList
        @show_impact="trigger_impact($event)"
        :type="type"
        :title="title"
        :items="itemList"
      />
      <ImpactModal
        @impact_maj="toggle_snack($event.message)"
        :uid="impactUid"
        :dialog.sync="impactDialog"
      />
      <v-snackbar bottom v-model="snackbar" timeout="2000">
        {{ message }}
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import ChipList from "@/components/Utils/ChipList";
import InfoCard from "@/components/Utils/InfoCard";
import ImpactModal from "@/components/Dialog/ImpactModal";

export default {
  name: "MaintenanceList",
  components: { ChipList, InfoCard, ImpactModal },
  data: () => ({
    loading: true,
    message: "",
    snackbar: false,
    impactDialog: false,
    impactUid: null,
    title: "",
    description: "",
    type: "",
    itemList: [],
    infoList: null
  }),
  created: function() {
    axios
      .get("/api/mkg/" + this.$route.params.name, {})
      .then(response => {
        this.title = response.data.list_title;
        this.type = response.data.type;
        this.description = response.data.description;
        this.itemList = response.data.item_list;
        this.infoList = response.data.info_list;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    toggle_snack(event) {
      this.message = event;
      this.snackbar = true;
    },
    trigger_impact(uid) {
      if (this.type === "Question") {
        this.impactDialog = true;
        this.impactUid = uid;
      } else {
        axios
          .get("/api/answers/" + uid, {})
          .then(response => {
            let question_uid = response.data.question.uid;
            if (!question_uid) this.$router.push("/details/" + uid);
            this.impactDialog = true;
            this.impactUid = question_uid;
          })
          .catch(error => {
            this.$router.push("/details/" + uid);
          });
      }
    }
  }
};
</script>
