import Keycloak from "keycloak-js";
import Vue from 'vue'
import axios from "axios";
import getCookie from "anam-library/src/js/common";
import store from "@/store";

// initialise keycloak
store.commit('setEnvKeycloak');
const _keycloak = new Keycloak({
    url: store.getters.getEnvKeycloak("url"),
    realm: store.getters.getEnvKeycloak("realm"),
    clientId: store.getters.getEnvKeycloak("clientId")
});

const PluginKeycloak = {
    install: Vue => {
        Vue.$keycloak = _keycloak;
    }
}

PluginKeycloak.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            }
        }
    })
}

Vue.use(PluginKeycloak)

// authentication workflow
Vue.$keycloak
    .init({onLoad: "check-sso", checkLoginIframe: false})
    .then(authenticated => {
        if(authenticated && store.getters.getUserPermission === 0) {
            axios.post("/api/connexion_sso/", {access_token: _keycloak.token}, {headers: {"X-CSRFToken": getCookie('csrftoken')}}).then(response => {
                store.dispatch("connexion", {data: response.data})
            }).catch(error => {
                if(error.response && error.response.status === 404) {
                    alert("User not found");
                }
                _keycloak.logout({redirectUri: this.$store.state.keycloak.redirect_logout})
            })
        }
    });

// set callback to refresh token if it's expired
Vue.$keycloak.onTokenExpired = function () {
  _keycloak.updateToken(70);
};

export default PluginKeycloak;