<template>
  <v-app id="app">
    <router-view/>
    <AnSnackbar
      :snackbar_model="$store.state.errors.snack_model"
      :snackbar_info="$store.state.errors.snack_info"
      :icon_class="$store.state.errors.snack_info.icon_class"
      v-on:closed="$store.state.errors.snack_model = false"
    />
  </v-app>
</template>

<script>

import AnSnackbar from "anam-library/src/components/an-ui/snackbar/AnSnackbar.vue";

export default {
  name: 'App',
  components: {
    AnSnackbar,
  },
}
</script>
