<template>
  <v-row>
    <v-col cols="8">
      <v-card elevation="2" class="ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur la master-question
				</v-card-title>
        <v-card-text elevation="2" v-if="!launched">
					<v-skeleton-loader class="mx-10"
						type="article, actions, image, table-tfoot"
					></v-skeleton-loader>
				</v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>UID</b>
              </p>
            </v-col>
            <v-col cols="10" class="text-start">
              <p class="primary--text">
                {{ uid }}
              </p>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Nom</b>
              </p>
            </v-col>
            <v-col cols="7">
              <v-text-field
                dense
                outlined
                v-model="nodeName"
                hide-details
                @change="
                  update_field('name', nodeName, route, fieldsDict['name'])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="2" class=" mt-1">
							<v-btn
								color="primary"
                elevation="0"
								:href="'/translate/' + uid"
							> Traduction </v-btn>
						</v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Spécialité(s)</b>
              </p>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                outlined
                dense
                hide-details
                placeholder="Aucune séléctionné"
                v-model="nodeSpe"
                :items="speciality_list"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                multiple
                @change="
                  update_field(
                    'medical_classification',
                    nodeSpe,
                    route,
                    fieldsDict['medical_classification']
                  )
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Pas de resultat pour cette recherche
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Questionnaire</b>
              </p>
            </v-col>
            <v-col cols="3" class="text-center">
              <v-select
                outlined
                dense
                hide-details
                placeholder="Non défini"
                v-model="questionnaire"
                :items="questionnaireChoices"
                @change="
                  update_field(
                    'questionnaire_name',
                    questionnaire,
                    route,
                    fieldsDict['questionnaire']
                  )
                "
              ></v-select>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Falcultative</b>
              </p>
            </v-col>
            <v-col cols="5">
              <v-switch
                class="ma-0"
                @change="
                  update_field(
                    'is_optional',
                    is_optional,
                    route,
                    fieldsDict['is_optional']
                  )
                "
                v-model="is_optional"
                color="primary"
                inset
              ></v-switch>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Type de question</b>
              </p>
            </v-col>
            <v-col cols="3" class="text-center">
              <v-select
                outlined
                dense
                hide-details
                placeholder="Non défini"
                v-model="ofType"
                :items="questionTypes"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                @change="
                  update_field('of_type', ofType, route, fieldsDict['of_type'])
                "
              ></v-select>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Format de réponse</b>
              </p>
            </v-col>
            <v-col cols="4" class="text-center">
              <v-select
                outlined
                dense
                hide-details
                placeholder="Non défini"
                v-model="ofFormat"
                :items="answerFormat"
                :item-text="item => item[1]"
                :item-value="item => item[0]"
                @change="
                  update_field(
                    'of_format',
                    ofFormat,
                    route,
                    fieldsDict['ofFormat']
                  )
                "
              ></v-select>
            </v-col>
            <v-col cols="2" class="text-end">
              <p class="primary--text">
                <b>Questionnaire</b>
              </p>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                ref="questField"
                dense
                outlined
                clearable
                hide-details
                class="ma-auto"
                :loading="loadingQuest"
                v-model="meta_questionnaire_name"
                :items="questList"
                :item-text="item => item.commercial_name"
                :item-value="item => item.name"
                label="Chercher un questionnaire"
                prepend-inner-icon="mdi-magnify"
                @change="updateQuest()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    Pas de correspondance
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>URL photo/vidéo</b></v-col>
            <v-col cols="10" class="align-self-center">
              <v-text-field dense outlined v-model="media_link" label="URL photo/vidéo" hide-details
                            @change="update_field('media_link', media_link, route, fieldsDict['media_link'])">
              </v-text-field>
            </v-col>
            <v-col cols="2" class="text-end align-self-center"><b>Afficher la description</b></v-col>
            <v-col cols="5" class="text-end">
              <v-switch @change="update_field('description_display', show_description, route, fieldsDict['description_display'])"
                        v-model="show_description" color="primary" inset>
              </v-switch>
            </v-col>
            <v-col cols="12" class="text-start">
              <v-textarea
                outlined
                auto-grow
                clearable
                clear-icon="mdi-close"
                v-model="description"
                label="Description (Optionnel) — Mot clés: TODO, FIXME"
                hide-details
                @change="
                  update_field(
                    'description',
                    description,
                    route,
                    fieldsDict['description']
                  )
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="2" class="mr-4 mt-4 mb-0" v-if="!launched">
						<v-skeleton-loader
							type="article, table-tfoot"
						></v-skeleton-loader>
					</v-card>
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-else>
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
						<v-card-text>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    :disabled="!prChanged"
                    text
                    color="blue darken-1"
                    @click="save_pr(prerequisites, route); prChanged=false"
                  > Enregistrer </v-btn>
                </v-col>
              </v-row>
						</v-card-text>
					</v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- Actions -->
    <v-col cols="12">
      <RelationshipActions
        :uid="uid"
        :node_label="'MasterQuestion'"
        :nodeTypesDict="node_dict"
        :refresh_data="refreshData"
        :rel_data.sync="rel_data"
        :launched.sync="launched"
        :toggle_snack="toggle_snack"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";

export default {
  name: "MasterQuestionDetails",
  components: {
    Prerequisites,
    RelationshipActions
  },
  props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function
  },
  data: () => ({
    route: "/api/master-questions/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeName: "",
    nodeSpe: [],
    description: "",
    media_link: null,
    show_description: false,
    prerequisites: [],
    meta_questionnaire_name: null,
    loadingQuest: false,
    is_deprecated: null,
    questList: [],
    questionnaire: "",
    questionnaireChoices: JSON.parse(
      localStorage.getItem("msq_questionnaire_name_choices")
    ),
    is_optional: false,
    ofType: "",
    questionTypes: JSON.parse(localStorage.getItem("question_choices_2")),
    ofFormat: "",
    answerFormat: JSON.parse(localStorage.getItem("display_answers_2")),
    prChanged: false,
    updated_by: "",
    updated_at: "",
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
      description: "de la description",
      questionnaire: "du nom du questionnaire",
      is_optional: "du status facultatif",
      ofType: "du type de question",
      ofFormat: "du format de réponse"
    },
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    }
  },
  mounted: function() {
    this.getQuestionnaires();
    this.refreshData();
  },
  methods: {
    updateQuest() {
      if (!this.meta_questionnaire_name) {
        this.update_field(
          "meta_questionnaire_name",
          "",
          this.route,
          "du questionnaire"
        );
      } else {
        this.update_field(
          "meta_questionnaire_name",
          this.meta_questionnaire_name,
          this.route,
          "du questionnaire"
        );
      }
      this.$refs.questField.blur();
    },
    getQuestionnaires() {
      if (!this.questList.length) {
        this.loadingQuest = true;
        axios
          .get("/api/mkg/questionnaires", {
            params: {
              list: true
            }
          })
          .then(response => {
            this.questList = response.data.questionnaire_list;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loadingQuest = false;
          });
      }
    },
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "MasterQuestion"
          }
        })
        .then(response => {
          let nodeData = response.data.data[0].node_data;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeName = nodeData.name_fr.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.description = nodeData.description.value;
          this.is_deprecated = nodeData.is_deprecated.value;
          this.show_description = nodeData.description_display.value;
          this.questionnaire = nodeData.questionnaire_name.value;
          this.is_optional = nodeData.is_optional.value;
          this.media_link = nodeData.media_link.value;
          this.ofType = nodeData.of_type.value;
          this.ofFormat = nodeData.format.value;
          this.meta_questionnaire_name = nodeData.meta_questionnaire_name.value;
          if (this.meta_questionnaire_name === "")
            this.meta_questionnaire_name = null;
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch(error => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.loaded = true;
          this.launched = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    }
  }
};
</script>
