<template>
	<v-container style="max-width: 100%;">
		<v-card v-if="description" color="#BDD5EA" tile class="pa-5">
			<b>{{ description }}</b>
		</v-card>
		<v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="paragraph@30"></v-skeleton-loader>
      </v-card-text>
    </v-card>
		<v-row v-else fill-height fluid>
			<v-col cols="12">
				<v-card elevation="0">
					<v-card-title class="pa-0">
						<v-toolbar flat dark color="primary">
							<v-toolbar-title style="white-space: normal;"> {{ title }} </v-toolbar-title>
							<v-spacer></v-spacer>
							<span> {{ itemList.length }} propriétés dupliqués </span>
						</v-toolbar>
					</v-card-title>
					<v-card-text class="mt-3">
						<template>
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left"> Propriété dupliquée</th>
											<th class="text-left"> Noeuds concernés </th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(item,index) in itemList">
                      <td>
												<span> {{ item.property }} </span>
											</td>
                      <td>
                        <v-list dense>
                          <v-list-item-group>
                            <v-list-item
                              v-for="node in item.nodes"
                              :key="node.uid"
                              :href="'/details/' + node.uid"
                            >
                              <v-list-item-title>{{ node.uid }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</template>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios"

export default {
	name: "Duplicates",
  data: () => ({
		loading: true,
		title: "",
		description: "",
		itemList: [],
	}),
	created: function() {
    axios
      .get("/api/mkg/" + this.$route.params.name, {})
      .then(response => {
        this.title = response.data.list_title;
        this.description = response.data.description;
        this.itemList = response.data.item_list;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>
<style lang="scss">
  tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>
