import axios from "axios";
import store from "@/store/index.js";
import getCookie from "anam-library/src/js/common";
import cytoscape from "cytoscape";
import cxtmenu from "cytoscape-cxtmenu";
import automove from "cytoscape-automove";

cytoscape.use(cxtmenu);
cytoscape.use(automove);

let is_question_or_master_question =
  '[type="Question"], [type="MasterQuestion"]';
let menu_is_open = false;
let level = 1;
let dict_next_questions = { "1": { ids: [], number: 0 } };
let rules = [];
var cy = null;

export function initiate_graph(entry_point) {
  cy = cytoscape({
    container: document.getElementById("questionnaire_graph"),
    elements: {},
    style: get_style()
	});

  cy.on("dbltap", "edge", function(evt) {
    if (menu_is_open) {
      return false;
    }
    delete_edge(evt.target.data("source"), evt.target.data("target"));
  });

  cy.on("cxttap", "node", function() {
    menu_is_open = true;
  });

  cy.on("tap", "node", function(evt) {
    if (menu_is_open) {
      return false;
    }
    add_link(
      evt.target.data("id"),
      localStorage.getItem("selected_type_of_link")
    );
  });

  cy.cxtmenu(get_menu_questions());
  cy.cxtmenu(get_menu_master_questions());
  cy.cxtmenu(get_menu_answers());

  cy.viewport({ zoom: 3, pan: { x: 0, y: 0 } });
  localStorage.setItem("entry_point", entry_point);
  if (entry_point !== "") {
    add_node(entry_point);
  }
}

export function add_node(node_id, meta_questionnaire_name = null) {
  let data_to_send = { node_id: node_id, level: level };
  if (localStorage.getItem("entry_point") === "") {
    data_to_send["entry_point"] = node_id;
    data_to_send["meta_questionnaire_name"] = meta_questionnaire_name;
  }
  store.state.loading_graph = true;
  axios.get("/api/get_node_info", {
    params: {
      node_id : data_to_send.node_id,
      level : data_to_send.level,
      entry_point : data_to_send.entry_point,
      meta_questionnaire_name : data_to_send.meta_questionnaire_name,
    }
  })
  .then(response => {
    for (let el in response.data.res.nodes) {
      let uid = response.data.res.nodes[el].data.id;
      let index = store.state.question_list.findIndex(
        element => element.uid === uid
      );
      if (index >= 0) store.state.question_list.splice(index, 1);
      if (
        response.data.res.nodes[el].data.type === "Question" ||
        response.data.res.nodes[el].data.type === "MasterQuestion"
      ) {
        store.state.entryPointList.push({
          name: response.data.res.nodes[el].data.full_caption
            ? response.data.res.nodes[el].data.full_caption
            : response.data.res.nodes[el].data.caption,
          uid: response.data.res.nodes[el].data.id,
          type: response.data.res.nodes[el].data.type
        });
      }
      cy.add([
        {
          group: "nodes",
          data: response.data.res.nodes[el].data
        }
      ]);
    }
    for (let el in response.data.res.edges) {
      cy.add([
        {
          group: "edges",
          data: response.data.res.edges[el]
        }
      ]);
    }
    for (let el in response.data.res.nodes) {
      set_node_position(
        response.data.res.nodes[el].data.id,
        response.data.res.nodes[el].data.type
      );
    }
    manage_automove();
    store.state.loading_graph = false;
    manage_dict_next_questions(response.data.next_questions_list);
  })
  .catch(() => {
    store.state.loading_graph = false;
  });
}

function initiate_add_link(evt_target_id, type_of_link) {
  cy.$("#" + evt_target_id).addClass("selected_for_link");
  localStorage.setItem("selected_type_of_link", type_of_link);
}

function add_link(evt_target_id, type_of_link) {
  if (cy.$("#" + evt_target_id).hasClass("selected_for_link")) {
    cy.$("#" + evt_target_id).removeClass("selected_for_link");
  } else {
    let valid = true;
    if (!cy.$(".selected_for_link").length) {
      valid = false;
    }
    if (
      cy.$(".selected_for_link").edgesWith(cy.$("#" + evt_target_id)).length
    ) {
      cy.$(".selected_for_link").removeClass("selected_for_link");
      alert("Error: this link cannot be created. Reason: already linked");
      valid = false;
    }
    if (cy.$("#" + evt_target_id).data("type") === "Answer") {
      cy.$(".selected_for_link").removeClass("selected_for_link");
      alert(
        "Error: this link cannot be created. Reason: an answer cannot be the target of a 'chained' or 'subquestion' type relationship"
      );
      valid = false;
    }
    if (
      cy.$("#" + evt_target_id).data("type") === "MasterQuestion" &&
      type_of_link === "sub_question"
    ) {
      cy.$(".selected_for_link").removeClass("selected_for_link");
      alert(
        "Error: this link cannot be created. Reason: a MasterQuestion cannot be the target of a 'subquestion' type relationship"
      );
      valid = false;
    }
    if (valid) {
      axios.post(
          "/api/create_or_delete_link",
          {
            source_id: cy.$(".selected_for_link").data("id"),
            target_id: evt_target_id,
            type_of_link: type_of_link,
            action: "create"
          },
          {headers: {"X-CSRFToken": getCookie('csrftoken')}})
        .then(response => {
          let edge_data = {
            target: evt_target_id,
            source: cy.$(".selected_for_link").data("id"),
            id: response.data,
            type: type_of_link
          };
          cy.add([
            {
              group: "edges",
              data: edge_data
            }
          ]);
          let origin_node = cy.$("#" + cy.$(".selected_for_link").data("id"));
          cy.$("#" + evt_target_id).position(
            "y",
            origin_node.position("y") + 60
          );
          cy.$(".selected_for_link").removeClass("selected_for_link");
          adjust_positions_after_placement();
        })
        .catch(() => {});
    }
  }
}

function adjust_positions_after_placement() {
  let level_count = 1;
  cy.$(".answer_is_placed").removeClass("answer_is_placed");
  cy.$(".question_is_placed").removeClass("question_is_placed");
  cy.$(".subquestion_is_placed").removeClass("subquestion_is_placed");
  cy.$(".is_sub_question").removeClass("is_sub_question");
  cy.$(".not_subquestion").removeClass("not_subquestion");
  while (level_count < level) {
    for (let i = 0; i < cy.nodes('[level="' + level_count + '"]').length; i++) {
      let selected_node = cy.nodes('[level="' + level_count + '"]')[i];
      if (
        selected_node.connectedEdges('[type="subquestion"]').length > 0 &&
        selected_node._private.data.type === "Question"
      ) {
        selected_node.addClass("is_sub_question");
      } else {
        selected_node.addClass("not_subquestion");
      }
      if (!selected_node.hasClass("is_sub_question")) {
        let parent = selected_node.incomers().nodes();
        if (parent.length > 0) {
          let number_of_siblings = parent
            .outgoers()
            .nodes(
              '[type="Question"].not_subquestion, [type="MasterQuestion"].not_subquestion'
            ).length;
          let number_on_next_level =
            cy.nodes('[level="' + (level_count + 1) + '"]').length + 1;
          let number_of_children = selected_node
            .outgoers()
            .nodes(is_question_or_master_question).length;
          let initialX =
            parent.position("x") -
            25 * number_on_next_level * (number_of_siblings - 1);
          let offsetX =
            50 *
            (number_of_children + 2) *
            parent.outgoers().nodes(".question_is_placed").length;
          selected_node.addClass("question_is_placed");
          selected_node.position({
            x: initialX + offsetX,
            y:
              parent[0]._private.data.type === "MasterQuestion"
                ? parent.position("y") + 90
                : parent.position("y") + 60
          });
        }
      }
    }
    level_count++;
  }

  for (let j = 0; j < cy.nodes(".is_sub_question").length; j++) {
    let selected_subquestion = cy.nodes(".is_sub_question")[j];
    let parent_master_question = selected_subquestion.incomers().nodes();
    let number_of_subquestions = parent_master_question.connectedEdges(
      '[type="subquestion"]'
    ).length;
    let initialX =
      parent_master_question.position("x") - 50 * (number_of_subquestions - 1);
    let offsetX =
      100 *
      parent_master_question
        .outgoers()
        .nodes('[type="Question"].subquestion_is_placed').length;
    selected_subquestion.addClass("subquestion_is_placed");
    selected_subquestion.position({
      x: initialX + offsetX,
      y: parent_master_question.position("y") + 30
    });
  }

  for (let k = 0; k < cy.nodes('[type="Answer"]').length; k++) {
    let selected_answer = cy.nodes('[type="Answer"]')[k];
    let parent_question = selected_answer.incomers().nodes();
    let number_of_answers = parent_question.outgoers().nodes('[type="Answer"]')
      .length;
    let initialX = parent_question.position("x") - 25 * (number_of_answers - 1);
    let offsetX =
      50 *
      parent_question.outgoers().nodes('[type="Answer"].answer_is_placed')
        .length;
    selected_answer.addClass("answer_is_placed");
    selected_answer.position({
      x: initialX + offsetX,
      y: parent_question.position("y") + 30
    });
  }
  manage_automove();
}

function manage_dict_next_questions(next_questions_list) {
  if (!(level + 1 in dict_next_questions)) {
    dict_next_questions[level + 1] = { ids: [], number: 0 };
  }
  for (let el in next_questions_list) {
    dict_next_questions[level + 1]["ids"].push(next_questions_list[el]);
    dict_next_questions[level + 1]["number"]++;
  }
  if (dict_next_questions[level]["ids"].length === 0) {
    level++;
  }
  if (dict_next_questions[level]["ids"].length > 0) {
    let question_id = dict_next_questions[level]["ids"].shift();
    add_node(question_id);
  } else {
    adjust_positions_after_placement();
  }
}

function manage_automove() {
  for (let j = 0; j < rules.length; j++) {
    rules[j].destroy();
  }
  for (let i = 0; i < cy.nodes().length; i++) {
    if (
      cy.nodes()[i]._private.data.type === "Question" ||
      cy.nodes()[i]._private.data.type === "MasterQuestion"
    ) {
      let id_of_node = cy.nodes()[i]._private.data.id;
      rules.push(
        cy.automove({
          nodesMatching: cy.$("#" + id_of_node).successors("node"),
          reposition: "drag",
          dragWith: cy.$("#" + id_of_node)
        })
      );
    }
  }
}

function delete_edge(source_id, target_id) {
  if (cy.$("#" + target_id).data("type") !== "Answer") {
    axios.post("/api/create_or_delete_link",
        {
          source_id: source_id,
          target_id: target_id,
          action: "delete"
        },
        {headers: {"X-CSRFToken": getCookie('csrftoken')}})
      .then(() => {
        cy.remove(
          cy.edges('[source="' + source_id + '"][target="' + target_id + '"]')
        );
        manage_automove();
      })
      .catch(() => {});
  }
}

function set_node_position(node_id, node_type) {
  let node_position;
  if (node_type === "Question" || node_type === "MasterQuestion") {
    if (level === 1) {
      node_position = {
        x: cy.width() / 2,
        y: 60
      };
    } else {
      let parent = cy
        .$("#" + node_id)
        .incomers()
        .nodes(is_question_or_master_question);
      if (parent.length === 0) {
        node_position = {
          x:
            cy.nodes('[level="1"]').length > 0
              ? cy.nodes('[level="1"]').position("x")
              : cy.width() / 2,
          y: 60 * level
        };
      } else {
        let initialX =
          parent.position("x") -
          50 * dict_next_questions[level]["number"] +
          ((25 * (dict_next_questions[level]["number"] + 1)) % 2);
        let offsetX =
          50 *
          (parent.outgoers().nodes(is_question_or_master_question).length + 2);
        node_position = {
          x: initialX + offsetX,
          y: 60 * level
        };
      }
    }
  } else {
    let parent = cy
      .$("#" + node_id)
      .incomers()
      .nodes('[type="Question"]');
    node_position = {
      x: parent.position("x"),
      y: parent.position("y") + 30
    };
  }
  cy.$("#" + node_id).position(node_position);
  cy.fit();
}

function get_default_menu_options() {
  return {
    menuRadius: 60,
    fillColor: "rgba(107, 168, 170, 0.90)",
    activeFillColor: "rgba(27, 42, 73, 0.75)",
    activePadding: 0,
    indicatorSize: 15,
    separatorWidth: 3,
    spotlightPadding: 4,
    minSpotlightRadius: 20,
    maxSpotlightRadius: 50,
    openMenuEvents: "cxttap",
    itemColor: "black",
    itemTextShadowColor: "transparent",
    zIndex: 9999,
    atMouse: false
  };
}

function close_menu_after_delay() {
  setTimeout(function() {
    menu_is_open = false;
  }, 100);
}

function trigger_impact(node) {
  let uid = node.id;
  let type = node.type;

  if (type === "Question") {
    store.state.impactUid = uid;
    store.state.impactDialog = true;
  } else {
    axios
      .get("/api/answers/" + uid, {})
      .then(response => {
        let question_uid = response.data.question.uid;
        if (!question_uid) {
          window.open("/details/" + uid, "_blank");
        }
        store.state.impactUid = question_uid;
        store.state.impactDialog = true;
      })
      .catch(() => {
        window.open("/details/" + uid, "_blank");
      });
  }
}

function get_menu_questions() {
  let options = get_default_menu_options();
  options["selector"] = 'node[type="Question"]';
  options["commands"] = [
    {
      content: "Edit",
      select: function(el) {
        close_menu_after_delay();
        setTimeout(() => {
          trigger_impact(el._private.data);
        });
      }
    },
    {
      content: "Details",
      select: function(el) {
        close_menu_after_delay();
        window.open(
          "/details/" + el._private.data.id,
          "_blank"
        );
      }
    },
    {
      content: "Supprimer",
      select: function(el) {
        close_menu_after_delay();
        remove_node_from_graph(
          el._private.data.id,
          el._private.data.caption,
          el._private.data.type
        );
      }
    },
    {
      content: "",
      select: function(el) {
        close_menu_after_delay();
      }
    },
    {
      content: "Chain",
      select: function(el) {
        close_menu_after_delay();
        initiate_add_link(el._private.data.id, "chained");
      }
    }
  ];
  return options;
}

function get_menu_master_questions() {
  let options = get_default_menu_options();
  options["selector"] = 'node[type="MasterQuestion"]';
  options["commands"] = [
    {
      content: "Chain",
      select: function(el) {
        close_menu_after_delay();
        initiate_add_link(el._private.data.id, "chained");
      }
    },
    {
      content: "Details",
      select: function(el) {
        close_menu_after_delay();
        window.open(
          "/details/" + el._private.data.id,
          "_blank"
        );
      }
    },
    {
      content: "Supprimer",
      select: function(el) {
        close_menu_after_delay();
        remove_node_from_graph(
          el._private.data.id,
          el._private.data.caption,
          el._private.data.type
        );
      }
    },
    {
      content: "",
      select: function(el) {
        close_menu_after_delay();
      }
    },
    {
      content: "Sous-question",
      select: function(el) {
        close_menu_after_delay();
        initiate_add_link(el._private.data.id, "sub_question");
      }
    }
  ];
  return options;
}

function get_menu_answers() {
  let options = get_default_menu_options();
  options["selector"] = 'node[type="Answer"]';
  options["commands"] = [
    {
      content: "Edit",
      select: function(el) {
        close_menu_after_delay();
        setTimeout(() => {
          trigger_impact(el._private.data);
        });
      }
    },
    {
      content: "Chain",
      select: function(el) {
        close_menu_after_delay();
        initiate_add_link(el._private.data.id, "chained");
      }
    },
    {
      content: "Details",
      select: function(el) {
        close_menu_after_delay();
        window.open(
          "/details/" + el._private.data.id,
          "_blank"
        );
      }
    },
    {
      content: "",
      select: function(el) {
        close_menu_after_delay();
      }
    }
  ];
  return options;
}

function remove_node_from_graph(target_id, caption, type) {
  let incomers = cy
    .$("#" + target_id)
    .incomers()
    .nodes('[type="Question"]');
  for (let i = 0; i < incomers.length; i++) {
    let id_question_linked = incomers[i]._private.data.id;
    delete_edge(id_question_linked, target_id);
  }
  let outgoers = cy
    .$("#" + target_id)
    .outgoers()
    .nodes('[type="Question"]');
  for (let i = 0; i < outgoers.length; i++) {
    let id_question_linked = outgoers[i]._private.data.id;
    delete_edge(target_id, id_question_linked);
  }
  store.state.question_list.push({ name: caption, uid: target_id, type: type });
  if (type === "Question" || type === "MasterQuestion") {
    let index = store.state.entryPointList.findIndex(
      element => element.uid === target_id
    );
    if (index >= 0) store.state.entryPointList.splice(index, 1);
  }
  cy.remove(cy.$("#" + target_id).outgoers('node[type="Answer"]'));
  cy.remove(cy.$("#" + target_id));
}

function get_style() {
  return [
    {
      selector: "node",
      style: {
        label: "data(caption)",
        "font-size": "6px",
        "text-valign": "center",
        "border-width": 0.5,
        "border-color": "black",
        shape: "rectangle",
        width: "label",
        height: "label",
        padding: "10px"
      }
    },
    {
      selector: "edge",
      style: {
        width: "1px",
        label: "data(type)",
        "font-size": "6px",
        "text-rotation": "autorotate",
        "curve-style": "haystack",
        "mid-target-arrow-shape": "vee"
      }
    },
    {
      selector: 'node[type="Question"]',
      style: {
        "background-color": "grey"
      }
    },
    {
      selector: 'node[type="Answer"]',
      style: {
        "background-color": "pink"
      }
    },
    {
      selector: 'node[type="MasterQuestion"]',
      style: {
        "background-color": "brown"
      }
    },
    {
      selector: 'node[type="Symptom"]',
      style: {
        "background-color": "#0ca5b4",
        shape: "ellipse"
      }
    },
    {
      selector: 'node[type="RiskFactor"]',
      style: {
        "background-color": "#EB5F59",
        shape: "ellipse"
      }
    },
    {
      selector: 'node[can_expand="no"]',
      style: {
        "border-style": "dashed"
      }
    },
    {
      selector: 'node[of_type="scoring"]',
      style: {
        "border-style": "dashed"
      }
    },
    {
      selector: 'node[caption=""]',
      style: {
        label: "UNDEFINED"
      }
    },
    {
      selector: ".selected_for_link",
      style: {
        "background-color": "green"
      }
    }
  ];
}
