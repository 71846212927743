<template>
  <v-row fill-height fluid>
    <v-col cols="12">
      <v-card elevation="0">
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary" flat>
            <v-toolbar-title
              style="white-space: normal; word-break: break-word;"
            >
              {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <span style="white-space: nosecondaryrmal; word-break: break-word;">
              {{ items.length }} noeuds
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="mt-3">
          <div style="text-align: center" v-if="items.length === 0">
            <b>Cette liste est vide</b>
          </div>
          <v-chip-group v-else column active-class="primary--text">
            <v-chip
              :key="item.uid"
              v-for="item in items"
              style="text-decoration: none;"
              :href="'/details/' + item.uid"
            >
              <span v-if="!item.name && !item.relations"> {{ item.uid }} </span>
              <span v-else-if="!item.name && item.relations"> {{ item.uid }} — {{ item.relations}} lien(s) </span>
              <span v-else-if="item.name && item.relations"> {{ item.name }} — {{ item.relations}} lien(s) </span>
              <span v-else> {{ item.name }} </span>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    title: String,
    type: String,
    items: Array
  }
};
</script>
