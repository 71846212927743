import ErrorPagesFr from 'anam-library/src/i18n/error-pages/fr.json'
import AuthFr from 'anam-library/src/i18n/auth/fr.json'
import RulesFr from 'anam-library/src/i18n/rules/fr.json'
import CommonFr	from 'anam-library/src/i18n/common/fr.json'

export const defaultLocale = 'fr'

export const languages = {
	fr: {
		auth: AuthFr,
		common: CommonFr,
		error_pages: ErrorPagesFr,
		rules: RulesFr
	}
}
