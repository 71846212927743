<template>
  <v-dialog persistent v-model="dialog" width="700">
    <v-card>
      <v-card-title
        class="px-8 primary white--text"
        style="white-space: normal; word-break: break-word;"
      >
        Créer un facteur de risque
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" v-model="validForm">
          <v-row>
            <CreateModalCore
              :validForm.sync="validForm"
              :prerequisites.sync="prerequisites"
              :linkedNodes.sync="linkedNodes"
              :nodeTypesDict="nodeTypesDict"
              :nodeClass="'RiskFactor'"
              :linkNode="true"
            />
            <v-col cols="8">
              <v-autocomplete
                v-model="rfType"
                :items="typeList"
                item-text="name"
                item-value="value"
                :rules="[type_rule]"
                chips
                label="Type de facteur de risque *"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="is_absence_of"
                label="Absence de ..."
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col offset="7">
          <v-btn
            style="padding: 0; text-align: center; width: 6em;"
            outlined
            color="primary"
            @click="close()"
          >
            Fermer
          </v-btn>
          <v-btn
            class="ml-2"
            style="padding: 0; text-align: center; width: 10em;"
            :disabled="!validForm"
            :loading="validateLoading"
            @click="validate()"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import CreateModalCore from "@/components/Dialog/CreateModalCore";

export default {
  name: "CreateRiskFactorModal",
  components: {
    CreateModalCore
  },
  data: () => ({
    rfType: [],
    is_absence_of: false,
    type_rule: v =>
      (v && v.length > 0) ||
      "Sélectionnez au moins un type de facteur de risque",
    typeList: [
      { value: "age", name: "Age (ex: Age:[25,50])" },
      {
        value: "harmful_substance",
        name: "Substance dangereuse (ex : Cannabis, Ectasy)"
      },
      {
        value: "origin",
        name: "Origine (ex : Origine asiatique, Origine caucasienne)"
      },
      {
        value: "lifestyle",
        name: "Habitudes de vie (ex : Sédentarité, Hygiène des mains)"
      },
      {
        value: "side_effects",
        name: "Effets secondaires (ex : Chimiothérapie)"
      },
      {
        value: "sexual_intercourse",
        name: "Rapports sexuels (ex : Partenaires multiples)"
      },
      { value: "trip", name: "Voyage (ex : Voyages longs)" },
      { value: "job", name: "Travail (ex : Travail dans la métallurgie)" },
      {
        value: "allergy",
        name: "Allergies (ex : Allergie au pollen, Allergie au gluten)"
      },
      {
        value: "personal_medical_history",
        name:
          "Antécédents médicaux personnels réglés sans récidive (ex : Antécédent de chirurgie orthopédique)"
      },
      {
        value: "familial_medical_history",
        name: "Antécédents médicaux familiaux (ex : Antécédent familial d'AVC)"
      },
      {
        value: "current_medical_situation",
        name: "Comorbidité active (ex : Diabète)"
      },
      {
        value: "recent_affections",
        name: "Affection Récente (ex : Griffure de Chat) "
      },
      {
        value: "medical_element",
        name:
          "Élément d'interrogatoire d'ordre médical (ex : Hospitalisation récente) "
      },
      {
        value: "medical_process",
        name: "Procédure médicale (ex : Chirurgie thoracique, Cataracte) "
      }
    ],
    //Base props
    validForm: false,
    validateLoading: false,
    linkedNodes: [],
    prerequisites: [],
    required_rule: v => !!v || "Ce champ est requis",
    len_rule: v => (v && v.length <= 100) || "Max 100 caractères"
  }),
  props: {
    snackText: String,
    snackbar: Boolean,
    dialog: Boolean,
    nodeTypesDict: Object
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$store.state.description = "";
      this.$store.state.specialities = [];
      this.$store.state.newName = null;
      this.linkedNodes = [];
      this.is_absence_of = false;
      this.$emit("update:dialog", false);
    },
    validate() {
      this.validateLoading = true;
      let data = {
        tags: this.rfType,
        is_absence: this.is_absence_of,
        name: this.$store.state.newName,
        description: this.$store.state.description,
        medical_classification: this.$store.state.specialities,
        prerequisites: JSON.stringify(this.prerequisites),
        node_link: this.linkedNodes
      };
      axios
        .post("/api/risk-factors", data, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .then(response => {
          this.$emit("update:snackText", response.data.detail);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
          this.snackText = "Erreur lors de la création du noeud";
          this.$emit("update:snackText", "Erreur lors de la création du noeud");
        })
        .finally(() => {
          this.$emit("update:snackbar", true);
          this.validateLoading = false;
        });
    }
  }
};
</script>
