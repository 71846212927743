<template>
	<v-row>
    <v-col cols="8">
			<v-card elevation="2" class="ml-4 mt-4 mb-0">
				<v-card-title class="text-h5 primary--text mb-4 pa-2">
          <v-btn icon @click="refreshData()"><v-icon color="primary">mdi-refresh</v-icon></v-btn>
          Informations sur la maladie
				</v-card-title>
				<v-card-text elevation="2" v-if="!launched">
					<v-skeleton-loader class="mx-10"
						type="article, actions, image, table-tfoot"
					></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-row>
            <v-col cols="12"><span class="primary--text font-size--13">Dernière modification le {{ updated_at }} par {{ updated_by }}</span></v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>UID</b></p>
						</v-col>
						<v-col cols="5" class="text-start">
							<p class="primary--text">{{uid}}</p>
						</v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>CIM-10</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="nodeCIM"
								hide-details
								@change="update_field('icd10_id', nodeCIM, route, fieldsDict['icd10_id'])"
							></v-text-field>
						</v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>Nom</b></p>
						</v-col>
						<v-col cols="7" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="nodeName"
								hide-details
								@change="update_field('name', nodeName, route, fieldsDict['name'])"
							></v-text-field>
						</v-col>
						<v-col cols="2" class=" mt-1">
							<v-btn
								color="primary"
                elevation="0"
								:href="'/translate/' + uid"
							> Traduction </v-btn>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>URL arbre de décision</b></p>
						</v-col>
            <v-col cols="9" class="text-start">
							<v-text-field
								dense
								outlined
								v-model="nodeDecisionTreeUrl"
								hide-details
								@change="update_field('decision_tree_url', nodeDecisionTreeUrl, route, fieldsDict['decision_tree_url'])"
							></v-text-field>
						</v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>Spécialité(s)</b></p>
						</v-col>
						<v-col cols="5" class="text-start">
							<v-autocomplete
								outlined
								dense
								hide-details
								placeholder="Aucune séléctionné"
								v-model="nodeSpe"
								:items="speciality_list"
								:item-text="(item) => item[1]"
								:item-value="(item) => item[0]"
								multiple
								@change="update_field('medical_classification', nodeSpe, route, fieldsDict['medical_classification'])"
							>
								<template v-slot:no-data>
									<v-list-item>
										<v-list-item-title>
											Pas de resultat pour cette recherche
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>Fréquence</b></p>
						</v-col>
						<v-col cols="3" class="text-start">
							<v-select
								outlined
								dense
								hide-details
								v-model="frequency"
								:items="frequencyChoices"
								@change="update_field('frequency', frequencyChoices.indexOf(frequency) + 1, route, fieldsDict['frequency'])"
							></v-select>
						</v-col>
						<v-col cols="2" class="text-end">
							<p class="primary--text"><b>Dangerosité</b></p>
						</v-col>
						<v-col cols="5" class="text-start">
							<v-text-field
								v-model="dangerosity"
								hide-details
								dense
								outlined
								type="number"
								min="0"
								max="100"
								:rules="[dangerosity_rule]"
								@change="update_field('dangerosity', dangerosity, route, fieldsDict['dangerosity'])"
							></v-text-field>
						</v-col>
            <v-col cols="2" class="text-end">
							<p class="primary--text"><b>Urgence</b></p>
						</v-col>
						<v-col cols="3">
							<v-switch class="ma-0" @change="update_field('emergency', emergency, route, fieldsDict['emergency'])" v-model="emergency" inset color="primary"></v-switch>
						</v-col>
            <v-col cols="12">
							<v-textarea
								outlined
								auto-grow
								clearable
								clear-icon="mdi-close"
								v-model="description"
								label="Description (Optionnel) — Mot clés: TODO, FIXME"
								hide-details
								@change="update_field('description', description, route, fieldsDict['description'])"
							></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="4">
			<v-row>
				<v-col cols="12">
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-if="!launched">
						<v-skeleton-loader
							type="article, table-tfoot"
						></v-skeleton-loader>
					</v-card>
					<v-card elevation="2" class="mr-4 mt-4 mb-0" v-else>
            <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">Prérequis</v-card-title>
						<v-card-text>
              <v-row>
                <v-col cols="12">
                  <Prerequisites :prerequisites.sync="prerequisites"/>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    :disabled="!prChanged"
                    text
                    color="blue darken-1"
                    @click="save_pr(prerequisites, route); prChanged=false"
                  > Enregistrer </v-btn>
                </v-col>
              </v-row>
						</v-card-text>
					</v-card>
				</v-col>
        <Synonyms
          :synonyms.sync="synonyms"
          :loaded="loaded"
          :launched="launched"
          :update_field="update_field"
        />
			</v-row>
		</v-col>
		<!-- Disease Expression -->
		<v-col cols="12">
			<v-card elevation="2" class="ml-4 mr-4 mt-4 mb-0" v-if="!launched">
				<v-skeleton-loader
					type="article, table-tfoot"
				></v-skeleton-loader>
			</v-card>
			<DiseaseExpression v-else
				:uid="uid"
				:route="route"
				:dexp_data.sync="dexp_data"
				:nodeTypesDict="node_dict"
				:refresh_data="refreshData"
				:update_field="update_field"
				:toggle_snack="toggle_snack"
			/>
		</v-col>
		<!-- Actions -->
		<v-col cols="12">
			<RelationshipActions
				:uid="uid"
				:node_label="'Disease'"
				:nodeTypesDict="node_dict"
				:refresh_data="refreshData"
				:rel_data.sync="rel_data"
				:launched.sync="launched"
				:toggle_snack="toggle_snack"
			/>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { getCookie } from "@/data/utils.js";
import Synonyms from "@/components/Utils/Synonyms";
import Prerequisites from "@/components/Utils/Prerequisites";
import RelationshipActions from "@/components/Details/RelationshipActions";
import DiseaseExpression from "@/components/Details/DiseaseExpression";

export default {
	name:"DiseaseDetails",
	components: {
		Prerequisites,
		RelationshipActions,
		DiseaseExpression,
    Synonyms
	},
	props: {
    uid: String,
    speciality_list: Array,
    node_dict: Object,
    snack_data: Object,
    update_field: Function,
    unpack_pr: Function,
    save_pr: Function,
    toggle_snack: Function,
  },
  data: () => ({
    is_deprecated: null,
    dexp_data: null,
    route: "/api/disease/",
    launched: false,
    loaded: false,
    rel_data: null,
    nodeCIM: "",
    nodeName: "",
    nodeDecisionTreeUrl: "",
    nodeSpe: [],
    synonyms: [],
    description: "",
    dangerosity: 0,
    emergency: false,
    frequency: "",
    frequencyChoices: [
      "Très rare",
      "Rare",
      "Modéré",
      "Fréquent",
      "Très fréquent",
    ],
    prerequisites: [],
    prChanged: false,
    updated_by: "",
    updated_at: "",
    fieldsDict: {
      name: "du nom du noeud",
      medical_classification: "des spécialités",
      frequency: "de la fréquence",
      dangerosity: "de la dangerosité",
      description: "de la description",
      emergency: "de l'urgence",
    },
    dangerosity_rule: (v) =>
      /^([0-9]|[1-9][0-9]|100)$/.test(v) ||
      "Dangerosité comprise etre 0 et 100",
  }),
  watch: {
    prerequisites() {
      if (this.loaded) {
        this.prChanged = true;
      }
    },
  },
  mounted: function () {
    this.refreshData();
  },
  methods: {
    getData() {
      axios
        .get("/api/mkg/node/" + this.uid, {
          params: {
            label: "Disease",
          },
        })
        .then((response) => {
          let nodeData = response.data.data[0].node_data;
          this.dexp_data = nodeData.disease_expression.value;
          this.rel_data = response.data.data[0].node_relationships;
          this.nodeCIM = nodeData.icd10_id.value;
          this.nodeName = nodeData.name_fr.value;
          this.nodeDecisionTreeUrl = nodeData.decision_tree_url.value;
          this.nodeSpe = nodeData.medical_classification.value;
          this.is_deprecated = nodeData.is_deprecated.value;
          this.description = nodeData.description.value;
          this.synonyms = nodeData.synonyme.value;
          this.dangerosity = nodeData.dangerosity.value;
          this.emergency = nodeData.emergency.value;
          this.frequency = this.frequencyChoices[nodeData.frequency.value - 1];
          this.updated_by = nodeData.updated_by.value;
          this.updated_at = moment(response.data.time_str, "YYYYMMDDHHmm")
            .add(2, "h")
            .locale("fr")
            .format("lll");
          this.prerequisites = this.unpack_pr(
            JSON.parse(nodeData.prerequisites.value)
          );
        })
        .catch((error) => {
          this.toggle_snack(
            "error",
            "Un problème est survenu lors de la récupération des données",
            "mdi-close",
            false,
            10000
          );
        })
        .finally(() => {
          this.launched = true;
          this.loaded = true;
          this.snack_data.show = false;
          this.$emit("update:snack_data", this.snack_data);
        });
    },
    refreshData() {
      this.loaded = false;
      this.toggle_snack(
        "primary",
        "Rafraîchissement des données",
        "",
        true,
        0
      );
      this.getData();
    },
  },
}
</script>
