<template>
  <v-row>
    <v-col cols="12" md="0" offset-md="0" xl="6" offset-xl="0">
      <v-card elevation="3" class="mr-4 ml-4 mt-4 mb-0">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          <v-row class="pr-2 pl-2 pb-4">
            <v-col>Information du patient</v-col>
            <v-col class="text-end">
              <v-btn
                elevation="0"
                color="secondary"
                @click="open_testcase_import"
              >
                Importer un cas clinique
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-select
                hide-details
                name="sexe"
                v-model="sexe"
                :items="genderPick"
                item-text="text"
                item-value="value"
                label="Sexe"
                :append-icon="getGenderIcon()"
                outlined
                dense
                reverse
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                name="age"
                v-model="age"
                type="number"
                label="Age"
                append-icon="mdi-calendar-account"
                :prefix="age ? 'ans' : ''"
                outlined
                reverse
                dense
                min="0"
                max="150"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                v-model="height"
                type="number"
                label="Taille (cm)"
                append-icon="mdi-human-male-height"
                :prefix="height ? 'cm' : ''"
                outlined
                reverse
                dense
                min="0"
                max="250"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                hide-details
                name="weight"
                v-model="weight"
                type="number"
                label="Poids (kg)"
                step="0.1"
                append-icon="mdi-weight"
                :prefix="weight ? 'kg' : ''"
                outlined
                reverse
                dense
                min="0"
                max="250"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <SubLink
                :label="'Ajouter des indications validées'"
                :color="'primary'"
                :chosenList.sync="validated_indications"
                :exceptionList="invalidated_indications.concat(unknown_indications)"
                :current_csq="[]"
                :nodeTypesDict="nodeTypesDict"
                :linkLabels="['Symptom', 'RiskFactor']"
              />
            </v-col>
            <v-col cols="6">
              <SubLink
                :label="'Ajouter des indications invalidées'"
                :color="'primary'"
                :chosenList.sync="invalidated_indications"
                :exceptionList="validated_indications.concat(unknown_indications)"
                :current_csq="[]"
                :nodeTypesDict="nodeTypesDict"
                :linkLabels="['Symptom', 'RiskFactor']"
              />
            </v-col>
            <v-col cols="12">
              <SubLink
                :label="'Ajouter des indications inconnues'"
                :color="'primary'"
                :chosenList.sync="unknown_indications"
                :exceptionList="validated_indications.concat(invalidated_indications)"
                :current_csq="[]"
                :nodeTypesDict="nodeTypesDict"
                :linkLabels="['Symptom', 'RiskFactor']"
              />
            </v-col>
            <v-col cols="4" class="mt-0">
              <v-select
                v-model="select"
                :items="specialityList"
                :item-text="(item) => item[1]"
							  :item-value="(item) => item[0]"
                label="Spécialité médicale"
                outlined
                dense
                return-object
                clearable
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="eta"
                type="number"
                label="Seuil minimal"
                outlined
                dense
                min="0"
                max="100"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                v-model="precise_diagnosis"
                label="diagnostic précis"
              ></v-checkbox>
            </v-col>
            <v-col class="text-end">
              <v-btn
                elevation="0"
                color="secondary"
                :loading="loading"
                @click="launchDiagnosis()"
              >
                Lancer
              </v-btn>
            </v-col>
            <v-card-text>
              <div v-if="!first"></div>
              <div
                class="text-center"
                v-else-if="!results.length && !loading"
              >
                Aucun résultat pour ces indications
              </div>
              <div v-else-if="loading"></div>
              <v-list v-else>
                <v-list-item-group v-model="selected" color="secondary">
                  <v-list-item
                    :key="item.uid"
                    v-for="item in results"
                    @click.prevent="selectDisease(item)"
                    :href="'/details/' + item.uid"
                  >
                    <v-list-item-icon v-if="item.emergency" class="my-auto mr-4">
                      <v-icon color="error">mdi-alert</v-icon>
                    </v-list-item-icon>
                    <v-list-item-avatar
                      v-else
                      size="26"
                      color="#8ee5ee"
                      class="white--text"
                    >
                      D
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="6" class="ma-auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title
                              v-text="item.name"
                              v-bind="attrs"
                              v-on="on"
                            ></v-list-item-title>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-col>
                        <v-col cols="6">
                          <span> {{ Math.ceil(item.proximity * 100) }}% </span>
                          <v-progress-linear
                            color="primary"
                            height="10"
                            :value="item.proximity * 100"
                          >
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="0" offset-md="0" xl="6" offset-xl="0">
      <v-card elevation="3" class="mt-4 mr-4">
        <v-card-title class="text-h5 primary--text mb-4 pa-2 soft_grey">
          Diagnostic différentiel
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="diagLoading"
            :size="30"
            :width="3"
            color="secondary"
            indeterminate
            class="mr-5"
          ></v-progress-circular>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-expansion-panels elevation="3" focusable v-model="panel" multiple>
            <v-expansion-panel :key="i" v-for="(item, i) in panelsName">
              <v-expansion-panel-header class="font-weight-bold primary--text" dense>
                {{ item }}
              </v-expansion-panel-header>
              <v-divider></v-divider>
              <v-expansion-panel-content>
                <v-list v-if="getList(item).length > 0">
                  <v-list-item :key="el.uid" v-for="el in getList(item)">
                    <v-list-item-avatar
                      size="26"
                      :color="
                        nodeTypesDict[el.label]
                          ? nodeTypesDict[el.label].color
                          : 'black'
                      "
                    >
                      {{
                        nodeTypesDict[el.label]
                          ? nodeTypesDict[el.label].symbol
                          : ""
                      }}
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-row>
                        <v-col cols="10" class="ma-auto">
                          <v-list-item-title v-if="el.origin === 'original'">
                            <b>{{ el.name }}</b>
                          </v-list-item-title>
                          <v-list-item-title v-else>
                            {{ el.name }}
                          </v-list-item-title>
                          <v-spacer></v-spacer>
                        </v-col>
                        <v-col cols="2" class="text--end">
                          <v-btn text color="secondary" class="my-0" :href="'/details/' + el.uid">
                            Détails
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div v-else class="mt-3">Aucun éléments</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogImportTestCase" max-width="620px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Importer un cas clinique</v-card-title>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="chosen_test_case"
                :items="test_cases"
                :item-text="item => item.case_name + ' ('+ item.expected_disease.name +')'"
                outlined
                dense
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-btn color="error" text @click="close">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="import_from_testcase">OK</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import SubLink from "@/components/Utils/SubLink";
import getCookie from "anam-library/src/js/common";

export default {
  name: "DifferentialDiagnosis",
  components: { SubLink },
  data: () => ({
    sexe: 1,
    age: 30,
    height: 180,
    weight: 65,
    combNbr: 1,
    first: false,
    loading: false,
    precise_diagnosis: true,
    eta: 5,
    test_cases: [],
    chosen_test_case: null,
    dialogImportTestCase: false,
    validated_indications: [],
    invalidated_indications: [],
    unknown_indications: [],
    results: [],
    genderPick: [
      { text: "Homme", value: 1 },
      { text: "Femme", value: 0 }
    ],
    selected: null,
    diagLoading: false,
    diagSymptom: [],
    diagRF: [],
    diagPrerequisites: [],
    diagExams: [],
    panelsName: ["Symptômes", "Facteurs de risque", "Prérequis", "Examens"],
    panel: [],
    specialityList: JSON.parse(localStorage.getItem("medical_classification")),
    select: null,
    // Global
    nodeTypesDict: {
      MasterQuestion: { name: "Master Question", symbol: "MQ", color: "#ab2f26" },
      Question: { name: "Question", symbol: "Q", color: "#e39e6e" },
      Answer: { name: "Réponse", symbol: "A", color: "#f8bcd0" },
      Symptom: { name: "Symptome", symbol: "S", color: "#badf55" },
      RiskFactor: { name: "Facteur de risque", symbol: "RF", color: "#f44336" },
      Group: { name: "Groupe", symbol: "G", color: "#66d7e5" },
      Disease: { name: "Maladie", symbol: "D", color: "#d8d8d8" },
      ClinicalExam: { name: "Examen clinique", symbol: "CE", color: "#764ebe" },
      MedicalExam: {name: "Examen complémentaire", symbol: "ME", color: "#ff9800"},
      MedicalExamResult: {name: "Resultat d'examen", symbol: "MER", color: "#CC5500"},
      Bacteria: { name: "Bacteria", symbol: "B", color: "#ffeb3b" },
      Virus: { name: "Virus", symbol: "Vi", color: "#8c97d3" },
      Parasite: { name: "Parasite", symbol: "P", color: "#607d8b" },
      Surgery: { name: "Surgery", symbol: "Su", color: "#795548" },
      Vaccine: { name: "Vaccine", symbol: "Va", color: "#3f51b5" },
      Drug: { name: "Drug", symbol: "Dr", color: "#2F71B3"},
      DCI: { name: "DCI", symbol: "Dc", color: "#E47760"},
      Compound: { name: "Compound", symbol: "C", color: "#809A6F"},
      Atc1AnatomicalGroup: {name: "Atc1AnatomicalGroup", symbol: "ATC1", color: "#f4f1de"},
      Atc2TherapeuticSubgroup: {name: "Atc2TherapeuticSubgroup", symbol: "ATC2", color: "#e07a5f"},
      Atc3PharmacologicalSubgroup: {name: "Atc3PharmacologicalSubgroup", symbol: "ATC3", color: "#3d405b"},
      Atc4ChemicalSubgroup: {name: "Atc4ChemicalSubgroup", symbol: "ATC4", color: "#81b29a"},
      Atc5ChemicalSubstance: {name: "Atc5ChemicalSubstance", symbol: "ATC5", color: "#f2cc8f"},
    },
  }),
  created: function() {
    axios.get(
        "/api/mkg/test_cases",
        {}
    ).then(response => {
      this.test_cases = response.data;
    }).catch(error => {
      this.manageError(error);
    });
  },
  methods: {
    launchDiagnosis() {
      if (!this.first) this.first = true;
      this.loading = true;
      axios
        .post(
          "/api/diag_diff/relevant_clusters_from_indications",
          {
            validated_indications: this.validated_indications.map(i => i.uid),
            invalidated_indications: this.invalidated_indications.map(i => i.uid),
            unknown_indications: this.unknown_indications.map(i => i.uid),
            sex: this.sexe,
            age: this.age,
            height: this.height,
            weight: this.weight,
            medical_classification: this.select === null ? null : this.select[0],
            precise_diagnosis: this.precise_diagnosis,
            eta: this.eta / 100,
          },
          {headers: { "X-CSRFToken": getCookie("csrftoken") }}
        )
        .then(response => {
          this.results = response.data;
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectDisease(item) {
      this.panel = [];
      this.diagLoading = true;
      this.selected = item;
      axios
        .get("/api/diag_diff/get_other_indications", {
          params: {
            validated_indications: this.validated_indications.map(i => i.uid),
            id_cluster: item.uid
          }
        })
        .then(response => {
          this.diagSymptom = response.data.all_indications.filter(
            el => el.label === "Symptom"
          );
          this.diagRF = response.data.all_indications.filter(
            el => el.label === "RiskFactor"
          );
          this.diagPrerequisites = response.data.prerequired_indications;
          this.diagExams = response.data.linked_exams;
        })
        .catch(error => {
          this.$store.dispatch("ManageError", error.response.status)
        })
        .finally(() => {
          this.diagLoading = false;
          this.panel = [0, 1, 2, 3];
        });
    },
    getList(label) {
      let lists = [
        this.diagSymptom,
        this.diagRF,
        this.diagPrerequisites,
        this.diagExams
      ];
      return lists[this.panelsName.indexOf(label)];
    },
    getGenderIcon() {
      if (this.sexe === 1) return "mdi-gender-male";
      else if (this.sexe === 0) return "mdi-gender-female";
      else return "mdi-gender-male-female";
    },
    close() {
      this.dialogImportTestCase = false;
      this.chosen_test_case = null;
    },
    open_testcase_import() {
      this.dialogImportTestCase = true;
    },
    import_from_testcase() {
      this.dialogImportTestCase = false;
      if (this.chosen_test_case !== null) {
        this.age = this.chosen_test_case.age;
        this.sexe = this.chosen_test_case.sex == "M" ? 1 : 0;
        this.validated_indications = this.chosen_test_case.validated_indications;
        this.unvalidated_indications = this.chosen_test_case.unvalidated_indications;
        this.unknown_indications = this.chosen_test_case.unknown_indications;
      }
    }
  }
};
</script>
