<template>
  <v-container style="max-width: 100%;">
    <v-card v-if="loading" elevation="0" height="100%">
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>
            <v-progress-circular
              indeterminate
              color="white"
              class="mr-5"
            ></v-progress-circular>
            Récupération des données
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-skeleton-loader type="date-picker-days@10"></v-skeleton-loader>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card v-if="description" color="#BDD5EA" tile="true" class="pa-5">
        <b>{{ description }}</b>
      </v-card>
      <InfoCard v-if="infoList" :title="'Consignes'" :items="infoList" />
      <v-row fill-height fluid>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-title class="pa-0">
              <v-toolbar flat dark color="primary">
                <v-toolbar-title
                  style="white-space: normal; word-break: break-word;"
                >
                  {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span style="white-space: normal; word-break: break-word;">
                  {{ itemList.length }} noeuds
                </span>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="mt-3">
              <div style="text-align: center;" v-if="itemList.length == 0">
                <b>Cette liste est vide</b>
              </div>
              <div class="text-center">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    :key="item.uid"
                    v-for="item in itemList"
                    @click="select(item)"
                  >
                    <span v-if="!item.name"> uid : {{ item.uid }} </span>
                    <span> {{ item.name }} </span>
                  </v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row fill-height fluid>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-title class="pa-0">
              <v-toolbar flat dark color="primary">
                <v-toolbar-title
                  style="white-space: normal; word-break: break-word;"
                >
                  Symptomes visible sans nom patient
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span style="white-space: normal; word-break: break-word;">
                  {{ noNameList.length }} noeuds
                </span>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="mt-3">
              <div style="text-align: center;" v-if="noNameList.length == 0">
                <b>Cette liste est vide</b>
              </div>
              <div class="text-center">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    :key="item.uid"
                    v-for="item in noNameList"
                    @click="select(item)"
                  >
                    <span v-if="!item.name"> uid : {{ item.uid }} </span>
                    <span> {{ item.name }} </span>
                  </v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row fill-height fluid>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-title class="pa-0">
              <v-toolbar flat dark color="primary">
                <v-toolbar-title
                  style="white-space: normal; word-break: break-word;"
                >
                  Symptomes visible sans synonymes
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span style="white-space: normal; word-break: break-word;">
                  {{ noSynonymsList.length }} noeuds
                </span>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="mt-3">
              <div
                style="text-align: center;"
                v-if="noSynonymsList.length == 0"
              >
                <b>Cette liste est vide</b>
              </div>
              <div class="text-center">
                <v-chip-group column active-class="primary--text">
                  <v-chip
                    :key="item.uid"
                    v-for="item in noSynonymsList"
                    @click="select(item)"
                  >
                    <span v-if="!item.name"> uid : {{ item.uid }} </span>
                    <span> {{ item.name }} </span>
                  </v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" width="700">
        <v-card>
          <v-card-title
            class="px-8 secondary white--text"
            style="white-space: normal; word-break: break-word;"
          >
            Symptôme : {{ selectedName }}
          </v-card-title>
          <v-card-text class="py-5">
            <v-form ref="form" v-model="validForm" autocomplete="off">
              <v-row>
                <span class="primary--text" style="margin: 0.5em;"
                  ><b>Nom patient du symptôme</b></span
                >
                <v-card class="my-2" style="width: -webkit-fill-available;">
                  <v-col cols="12" fluid>
                    <v-text-field
                      :counter="60"
                      :rules="nameRules"
                      required
                      style="margin-top: 0; padding-top: 0;"
                      label="Nom patient"
                      v-model="p_name_fr"
                      hint="Nom patient du symptôme (Parfois similaire au langage médecin)"
                      prepend-icon="mdi-pencil"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-card>
                <span class="primary--text" style="margin: 0.5em;"
                  ><b>Synonymes du symptôme</b></span
                >
                <v-card class="my-2" style="width: -webkit-fill-available;">
                  <v-row class="mx-2">
                    <v-col cols="8" fluid>
                      <v-text-field
                        :counter="60"
                        :rules="[synRules]"
                        label="Synonyme"
                        v-model="newSynonym"
                        hint="Ajouter un synonyme au symptôme"
                        persistent-hint
                        @keydown.enter="addSynonym"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col offset="1" cols="3" style="align-self: center">
                      <v-btn
                        :disabled="
                          !newSynonym ||
                            synonyms.includes(newSynonym) ||
                            newSynonym.length > 60
                        "
                        color="secondary"
                        @click="addSynonym"
                      >
                        Ajouter
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <span><b>Liste actuelle des synonymes : </b></span>
                      <p v-if="synonyms.length == 0" label class="my-2">
                        Pas de synonymes pour l'instant
                      </p>
                      <v-chip-group v-else column active-class="primary--text">
                        <v-chip
                          :key="item"
                          v-for="item in synonyms"
                          close
                          label
                          @click:close="removeSynonym(item)"
                        >
                          {{ item }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-card>
                <span class="primary--text" style="margin: 0.5em;"
                  ><b>Visibilité</b></span
                >
                <v-card class="my-2 px-3">
                  <v-col cols="12" fluid>
                    <v-checkbox
                      v-model="is_visible"
                      label="symptôme visible"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" class="py-0" fluid>
                    <p>
                      <b>ATTENTION:</b> Si vous décochez "Symptôme visible", il
                      faudra réactualiser la page pour qu'il disparaisse de la
                      liste. Par défaut, cette case est toujours cochée.
                      <br />
                      Vous pourrez toujours revenir sur un symptôme non visible
                      depuis sa page de détails.
                    </p>
                  </v-col>
                </v-card>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col offset="7">
              <v-btn outlined color="secondary" @click="close">
                Fermer
              </v-btn>
              <v-btn
                class="ml-3"
                :disabled="!validForm"
                @click="validate"
                color="secondary"
              >
                Enregistrer
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getCookie } from "@/data/utils.js";
import InfoCard from "@/components/Utils/InfoCard";

export default {
  name: "SymptomList",
  components: { InfoCard },
  data: () => ({
    loading: true,
    title: "",
    description: "",
    itemList: [],
    noNameList: [],
    noSynonymsList: [],
    infoList: [],
    dialog: false,
    selectedUid: "",
    selectedName: "",
    p_name_fr: "",
    is_visible: false,
    newSynonym: "",
    synonyms: [],
    validForm: true,
    nameRules: [v => v.length <= 60 || "Max 60 caractères"]
  }),
  created: function() {
    axios
      .get("/api/mkg/" + this.$route.params.name, {})
      .then(response => {
        this.title = response.data.list_title;
        this.description = response.data.description;
        this.noNameList = response.data.no_name_list;
        this.noSynonymsList = response.data.no_synonyms_list;
        this.itemList = response.data.item_list;
        this.infoList = response.data.info_list;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    synRules() {
      if (this.newSynonym.length > 60) {
        return () => this.newSynonym.length <= 60 || "Max 60 caractères";
      }
      return () =>
        !this.synonyms.includes(this.newSynonym) ||
        this.newSynonym + " est deja dans la liste";
    }
  },
  methods: {
    addSynonym() {
      if (
        this.newSynonym === "" ||
        this.synonyms.includes(this.newSynonym) ||
        this.newSynonym.length > 60
      ) {
        return;
      }
      this.synonyms.push(this.newSynonym);
      this.newSynonym = "";
    },
    removeSynonym(item) {
      const index = this.synonyms.indexOf(item);
      if (index > -1) {
        this.synonyms.splice(index, 1);
      }
    },
    select(item) {
      this.selectedName = item.name;
      this.selectedUid = item.uid;
      axios
        .get("/api/symptoms/" + item.uid, {})
        .then(response => {
          this.synonyms = response.data["synonyme"];
          this.p_name_fr = response.data["patient_name_fr"];
          this.is_visible = response.data["visible_by_patient"];
          this.dialog = true;
        })
        .catch(error => {
          console.log(error);
        });
    },
    reset() {
      this.p_name_fr = "";
      this.selectedUid = "";
      this.is_visible = false;
      this.synonyms = [];
      this.p_name_fr = "";
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    validate() {
      this.$refs.form.validate();
      let patchData = {};
      patchData["patient_name_fr"] = this.p_name_fr;
      patchData["visible_by_patient"] = this.is_visible;
      patchData["synonyme"] = this.synonyms;
      axios
        .patch("/api/symptoms/" + this.selectedUid, patchData, {
          headers: { "X-CSRFToken": getCookie("csrftoken") }
        })
        .catch(error => {
          console.log(error);
          this.reset();
          this.dialog = false;
        })
        .finally(() => {
          this.reset();
          this.dialog = false;
        });
    }
  }
};
</script>
